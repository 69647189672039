import React, { useEffect, useState, useCallback } from "react";
import { CssBaseline, Link } from "@material-ui/core";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ThemeProvider } from "@welldigital/components";
import { AuthenticationProvider } from "@welldigital/ui-common/Authentication/components/AuthenticationProvider";
import { useServiceStore } from "./stores/service/service.store";
import { InStoreRoutes } from "./pages/routes";
import { CookiesProvider } from "react-cookie";
import {
  CookieManagerModal,
  Panel,
} from "./components/CookieManagerModal/index";
import { cookie } from "@welldigital/ui-common/Components/CookiesManager/helpers";
import { CookiesPreferenceNames } from "@welldigital/ui-common/Components/CookiesManager/constants";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import RightArrow from "components/CookieManagerModal/rightArrow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cookiesPreferencesLink: {
      position: "absolute",
      bottom: 0,
      left: 0,
      padding: 16,
      display: "block",
      zIndex: 100,
      textDecoration: "none",
      fontSize: "16px",
      lineHeight: "25.6px",
      fontWeight: 500,
      color: "#fff",
      backgroundColor: "#083149",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);

const App = () => {
  const classes = useStyles();

  const fetchServices = useServiceStore((state) => state.fetchServices);
  const hasServices = useServiceStore((state) => !!state.services);

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const [currentCookieBannerPanel, setCurrentCookieBannerPanel] = useState(
    cookie.get(CookiesPreferenceNames.cookiePreferencesSet) !== "true"
      ? Panel.home
      : null
  );

  const closeCookiesBanner = useCallback(() => {
    setCurrentCookieBannerPanel(null);
  }, []);

  const launchCookiesBanner = useCallback((evt) => {
    evt.preventDefault();
    setCurrentCookieBannerPanel(Panel.preferences);
  }, []);

  if (!hasServices) return null;
  return (
    <AuthenticationProvider>
      <ThemeProvider themeName={"inStore"}>
        <CookiesProvider>
          <CssBaseline />
          <Router>
            <CookieManagerModal
              onDone={closeCookiesBanner}
              onPanelChangeIntent={setCurrentCookieBannerPanel}
              panel={currentCookieBannerPanel}
            />
            <Link
              onClick={launchCookiesBanner}
              className={classes.cookiesPreferencesLink}
              href={"#"}
            >
              <span
                style={{
                  transform: "rotate(-90deg)",
                  display: "inline-block",
                  marginRight: 10,
                  textSizeAdjust: "100%",
                }}
              >
                <RightArrow />
              </span>
              Cookie preferences
            </Link>
            <Switch>
              <InStoreRoutes />
            </Switch>
          </Router>
        </CookiesProvider>
      </ThemeProvider>
    </AuthenticationProvider>
  );
};

export default App;
