import { useParams } from "react-router-dom";
import {
  AccountStoreState,
  useAccountStore,
} from "../../../stores/account/account.store";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { CancelData } from "../../../stores/account/account.types";

type Params = {
  token: string;
};

export type CancelConfirmationState = {
  error?: ErrorAlertProps;
  cancelData?: CancelData;
  clearCancel: AccountStoreState["clearCancel"];
};

export default function useCancelConfirmation(): CancelConfirmationState {
  const { token } = useParams<Params>();
  const [error, cancelData, clearCancel] = useAccountStore((state) => [
    state.error,
    state.cancelMap[token],
    state.clearCancel,
  ]);

  return {
    error,
    clearCancel,
    cancelData,
  };
}
