import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { analytics } from "@welldigital/ui-common";
import events from "../../events";
import MainLayout from "../../components/MainLayout/MainLayout";
import { PaymentStatus, usePayment } from "./hooks/usePayment";
import { Paths } from "../paths";
import { Appointment } from "../../stores/appointment/appointment.types";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import { Typography } from "@welldigital/components";
import { Box } from "@material-ui/core";
import PaymentLoader from "./components/PaymentLoader/PaymentLoader";
import PaymentPanel from "./components/Panel/Panel";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { useCartStore } from "../../stores/cart/cart.store";
import { useBooking } from "./hooks/useBooking";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import ErrorAlert, {
  ErrorAlertProps,
} from "../../components/ErrorAlert/ErrorAlert";
import {traversalSavetAPI} from "./api.fetchers";
import { deleteFromLocalStorage, getFromLocalStorage } from "pages/LocationsPage/hooks/utils";
import { API_INIT_KEY, BookAppointmentCompleteResponse } from "api/api.types";

enum PaymentPageRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
  bookingDetailsFinished = "bookingDetailsFinished",
  bookedSlot = "bookedSlot",
}

type PaymentPageProps = Pick<
  Appointment,
  keyof typeof PaymentPageRequiredProps
>;

const PaymentPage: React.FC<PaymentPageProps> = ({
  service: actualService,
  pharmacy,
  owner,
  bookedSlot,
}) => {
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const appointmentComplete = useAppointmentStore(
    (state) => state.appointment!.appointmentComplete
  );
  const traversalId = useAppointmentStore(
    (state) => state.appointment!.traversalId
  );
  const [totalFee] = useCartStore((state) => [state.totalFee]);
  const {
    error: paymentError,
    paymentStatus,
    makePayment,
  } = usePayment({
    skip: appointmentComplete,
    fee: totalFee,
    customerDetails: owner.details,
  });
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { bookAllCustomers, booking, error: bookingError } = useBooking();
  const service = owner.serviceOverride || actualService;

  const trackPaymentComplete = useCallback(() => {
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.payment.complete,
      metadata: {
        location: pharmacy.name,
      },
    });
    analytics.trackRevenue(service.id, totalFee);
  }, [pharmacy.name, totalFee, service.analyticsName, service.id]);

  const trackBookingComplete = useCallback(() => {
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.confirmation.bookingComplete,
    });
  }, [service.analyticsName]);

  const trackPaymentFailed = useCallback(() => {
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.payment.failed,
    });
  }, [service]);

  const goNext = useCallback(
    (action: "push" | "replace") => {
      if (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) {
        const bookAppointmentComplete: any = getFromLocalStorage<BookAppointmentCompleteResponse>(API_INIT_KEY);
        let traversalSavePayload ={
          traversal_id: traversalId ? traversalId : '',
          customer_id: bookAppointmentComplete?.[0]?.userId ? bookAppointmentComplete?.[0]?.userId : '',
          appointment_id: bookAppointmentComplete?.[0].appointmentId ? bookAppointmentComplete?.[0]?.appointmentId : ''
        };
        traversalSavetAPI(traversalSavePayload).then(() => {
          history[action](Paths.Confirmation);
        });
        deleteFromLocalStorage(API_INIT_KEY);
      } else {
      history[action](Paths.Confirmation);
      }
    },
    [history, service.id, traversalId]
  );

  useEffect(() => {
    if (totalFee === 0) {
      bookAllCustomers(bookedSlot).then(() => {
        trackPaymentComplete();
        goNext("replace");
        trackBookingComplete();
      });
    }
  }, [
    totalFee,
    appointmentComplete,
    goNext,
    bookAllCustomers,
    bookedSlot,
    trackPaymentComplete,
    trackBookingComplete,
  ]);

  const onPay = useCallback(async () => {
    try {
      const payload = await makePayment();
      await bookAllCustomers(bookedSlot, payload.nonce);
      trackPaymentComplete();
      goNext("push");
      trackBookingComplete();
    } catch (e) {
      trackPaymentFailed();
    }
  }, [
    bookAllCustomers,
    bookedSlot,
    goNext,
    makePayment,
    trackPaymentComplete,
    trackPaymentFailed,
    trackBookingComplete,
  ]);

  const error = useMemo(() => {
    if (!paymentError && !bookingError) return;
    const _error = (paymentError || bookingError) as ErrorAlertProps;
    if (_error.message.includes("Amount must be greater than zero")) {
      _error.message =
        "We're sorry, there was a problem processing your booking. Please try again or use the contact details at the bottom of the page to get in touch with our support team.";
    }
    return _error;
  }, [paymentError, bookingError]);

  return (
    <MainLayout>
      <Typography variant={"h4"}>Payment</Typography>
      {paymentStatus === PaymentStatus.gettingToken && <PaymentLoader />}
      <PaymentPanel />
      {error && (
        <Box mt={2}>
          <ErrorAlert {...error} />
        </Box>
      )}
      {paymentStatus !== PaymentStatus.gettingToken && (
        <LayoutNavigation
          nextButton={{
            children: "Pay",
            onClick: onPay,
            disabled: paymentStatus === PaymentStatus.disabled,
            loading: paymentStatus === PaymentStatus.paying || booking,
          }}
        />
      )}
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<PaymentPageProps>
    validatedProps={
      Object.keys(PaymentPageRequiredProps) as (keyof Appointment)[]
    }
    page={PaymentPage}
  />
);
