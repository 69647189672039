import { CovidAnswer } from "../hooks.types";

export const covidQuestions: CovidAnswer[] = [
  {
    number: 1,
    question: "In the last 14 days, have you had any symptoms of COVID-19?",
    correctAnswer: "N",
  },
  {
    number: 2,
    question:
      "In the last 14 days, have any members of your household or support bubble had any symptoms of COVID-19?",
    correctAnswer: "N",
  },
  {
    number: 4,
    question: "Are you showing any symptoms of the virus now",
    correctAnswer: "N",
  },
  {
    number: 8,
    question:
      "Have you, or any member of your household received a positive test result for COVID-19 since the beginning of the pandemic?",
    followUpQuestion:
      "If yes, please tell us the approximate date of the test(s)",
  },
];
