import React, { useCallback, useMemo } from "react";
import Layout from "../../components/Layout/Layout";
import { Typography } from "@welldigital/components";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { Box, Divider } from "@material-ui/core";
import { generatePath, useHistory } from "react-router-dom";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import { format } from "date-fns";
import { Paths } from "../paths";
import useRescheduleConfirm from "./hooks/useRescheduleConfirm";

const AccountAppointmentRescheduleConfirmPage: React.FC = () => {
  const history = useHistory();
  const {
    appointmentId,
    rescheduleData,
    appointment,
    reschedule,
    isRescheduling,
    error,
  } = useRescheduleConfirm();

  const appointmentDate: string = useMemo(() => {
    if (!rescheduleData) return "";
    return format(
      new Date(rescheduleData.bookedSlot.startTime),
      "EEEE, d MMMM y, h:mm aa"
    );
  }, [rescheduleData]);

  const pharmacyName: string = useMemo(() => {
    if (!appointment) return "";
    return appointment.pharmacy.name;
  }, [appointment]);

  const onSubmit = useCallback(async () => {
    await reschedule(appointmentId);
    history.push(
      generatePath(Paths.AccountAppointmentRescheduleConfirmation, {
        appointmentId,
      })
    );
  }, [reschedule, history, appointmentId]);

  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Confirm your appointment
      </Typography>

      {rescheduleData && (
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={0}>
          We’ve changed your appointment to {appointmentDate} at {pharmacyName},
          to complete your booking, choose confirm. If you have booked for a
          group, everyone in your booking needs to arrive at the same time.
        </Typography>
      )}

      <LayoutNavigation
        nextButton={{
          onClick: onSubmit,
          children: "Confirm",
          endIcon: undefined,
          disabled: !rescheduleData,
          loading: isRescheduling,
        }}
      />

      <Box my={4}>
        <Divider />
      </Box>

      <AccountInfoMessage pharmacy={appointment?.pharmacy} />
    </Layout>
  );
};

export default AccountAppointmentRescheduleConfirmPage;
