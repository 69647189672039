import React, { useCallback } from "react";
import {
  DateSelectField,
  Spacing,
  TimeAvailabilityField,
  Typography,
  WatchFields,
} from "@welldigital/components";
import { Collapse } from "@material-ui/core";
import AvailabilityLoader from "../../BookAppointmentPage/components/AvailabilityLoader/AvailabilityLoader";
import { FindAvailabilityState } from "../../BookAppointmentPage/hooks/useFindAvailability";
import useFormContext from "../../../utils/useFormContext";

export type AccountAppointmentRescheduleDateFieldsProps = {
  fetchMonthCalendarData: FindAvailabilityState["fetchMonthCalendarData"];
  calendarData: FindAvailabilityState["calendarData"];
  minDate: Date;
  maxDate?: Date;
  slots: FindAvailabilityState["slots"];
  fetchSlots: FindAvailabilityState["fetchSlots"];
};

const AccountAppointmentRescheduleDateFields: React.FC<AccountAppointmentRescheduleDateFieldsProps> = ({
  fetchMonthCalendarData,
  calendarData,
  minDate,
  maxDate,
  slots,
  fetchSlots,
}) => {
  const {
    form: { setValue },
  } = useFormContext();

  const dateOnChange = useCallback(
    async (date: Date | null) => {
      setValue("selectedSlot", null);
      if (date) {
        await fetchSlots(date);
      }
    },
    [setValue, fetchSlots]
  );

  return (
    <>
      <Typography variant={"h6"}>Select an appointment</Typography>
      <DateSelectField
        name={"dateSelected"}
        fetchMonthData={fetchMonthCalendarData}
        data={calendarData}
        disablePast
        minDate={minDate}
        maxDate={maxDate}
        required
        onChange={dateOnChange}
        spacingAfter={0}
        validationMessages={{ required: "Select an appointment" }}
      />

      <WatchFields fieldNames={["dateSelected"]}>
        {({ dateSelected }: any) => (
          <Collapse in={!!dateSelected}>
            <Spacing spacing={3} />
            <Typography variant={"h6"}>Available appointments</Typography>
            {!slots?.length ? (
              <AvailabilityLoader />
            ) : (
              <TimeAvailabilityField
                name={"selectedSlot"}
                slots={slots}
                required
                spacingAfter={0}
              />
            )}
          </Collapse>
        )}
      </WatchFields>
    </>
  );
};

export default AccountAppointmentRescheduleDateFields;
