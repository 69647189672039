import React from "react";
import { Paths } from "./paths";
import LocationsPage from "./LocationsPage/LocationsPage";
import CustomerDetailsPage from "./CustomerDetailsPage/CustomerDetailsPage";
import ScreeningQuestionsPage from "./ScreeningQuestionsPage/ScreeningQuestionsPage";
import CovidQuestionsPage from "./CovidQuestionsPage/CovidQuestionsPage";
import CheckoutOptionsPage from "./CheckoutOptionsPage/CheckoutOptionsPage";
import BookAppointmentPage from "./BookAppointmentPage/BookAppointmentPage";
import PaymentPage from "./PaymentPage/PaymentPage";
import ConfirmationPage from "./ConfirmationPage/ConfirmationPage";
import BookingDetailsPage from "./BookingDetailsPage/BookingDetailsPage";
import RouteRenderer, { RouteProps } from "../components/RouteRenderer/RouteRenderer";
import BookingForPage from "./BookingForPage/BookingForPage";
import PageNotFound from "./PageNotFound/PageNotFound";
import AccountDashboardPage from "./AccountDashboardPage/AccountDashboardPage";
import AccountAppointmentManagePage from "./AccountAppointmentManagePage/AccountAppointmentManagePage";
import AccountAppointmentReschedulePage from "./AccountAppointmentReschedulePage/AccountAppointmentReschedulePage";
import AccountAppointmentRescheduleConfirmPage from "./AccountAppointmentRescheduleConfirmPage/AccountAppointmentRescheduleConfirmPage";
import AccountAppointmentRescheduleNotAllowedPage from "./AccountAppointmentRescheduleNotAllowedPage/AccountAppointmentRescheduleNotAllowedPage";
import AccountAppointmentRescheduleConfirmationPage from "./AccountAppointmentRescheduleConfirmationPage/AccountAppointmentRescheduleConfirmationPage";
import AppointmentCancelPage from "./AppointmentCancelPage/AppointmentCancelPage";
import AppointmentCancelConfirmPage from "./AppointmentCancelConfirmPage/AppointmentCancelConfirmPage";
import AppointmentCancelConfirmationPage from "./AppointmentCancelConfirmationPage/AppointmentCancelConfirmationPage";
import InEligiblePage from "./InEligibilePage/InEligibile";

export const inStoreRoutes: RouteProps<Paths>[] = [
  { path: Paths.Locations, component: LocationsPage },
  { path: Paths.BookingFor, component: BookingForPage },
  { path: Paths.BookingDetails, component: BookingDetailsPage },
  { path: Paths.CustomerDetails, component: CustomerDetailsPage },
  { path: Paths.ScreeningQuestions, component: ScreeningQuestionsPage },
  { path: Paths.CovidQuestions, component: CovidQuestionsPage },
  { path: Paths.CheckoutOptions, component: CheckoutOptionsPage },
  { path: Paths.BookAppointment, component: BookAppointmentPage },
  { path: Paths.Payment, component: PaymentPage },
  { path: Paths.Confirmation, component: ConfirmationPage },

  { path: Paths.AccountDashboard, component: AccountDashboardPage },
  {
    path: Paths.AccountAppointmentManage,
    component: AccountAppointmentManagePage,
  },
  {
    path: Paths.AccountAppointmentReschedule,
    component: AccountAppointmentReschedulePage,
  },
  {
    path: Paths.AccountAppointmentRescheduleNotAllowed,
    component: AccountAppointmentRescheduleNotAllowedPage,
  },
  {
    path: Paths.AccountAppointmentRescheduleConfirm,
    component: AccountAppointmentRescheduleConfirmPage,
  },
  {
    path: Paths.AccountAppointmentRescheduleConfirmation,
    component: AccountAppointmentRescheduleConfirmationPage,
  },
  {
    path: Paths.AppointmentTokenCancel,
    component: AppointmentCancelPage,
  },
  {
    path: Paths.AppointmentTokenCancelConfirmation,
    component: AppointmentCancelConfirmationPage,
  },
  {
    path: Paths.AppointmentTokenCancelConfirm,
    component: AppointmentCancelConfirmPage,
  },
  {
    path: Paths.IneligiblePage,
    component: InEligiblePage,
  },
];

export const InStoreRoutes: React.FC = () => (
  <RouteRenderer
    routes={[...inStoreRoutes, { path: "*", component: PageNotFound }]}
  />
);
