import React from "react";
import {
  ItemManagerItem,
  Button,
  Typography,
  InputField,
} from "@welldigital/components";
import { Box, makeStyles, Theme } from "@material-ui/core";
import {
  CustomerAppointmentDetails,

} from "../../../../stores/appointment/appointment.types";
import { generatePath, useHistory } from "react-router-dom";
import { Paths } from "../../../paths";
import {
  bookingDetailsOtherValidationMessages,
  bookingDetailsOwnerValidationMessages,
} from "../../../../components/validationMessages";
import CustomerAppointmentItem from "../../../../components/CustomerAppointmentItem/CustomerAppointmentItem";
import { Service } from "../../../../stores/service/service.types";

export type BookingDetailsItemProps = Partial<ItemManagerItem> & {
  headerName: string;
  noDetailsMessage?: string;
  customer: CustomerAppointmentDetails;
  customerId: "owner" | string;
  service?: Service;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(4),
    },
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
  },
  headerContent: {
    flex: 1,
  },
  headerName: {
    lineHeight: "32px",
  },
  headerButton: {
    flex: "0 auto",
  },
  service: {
    marginBottom: theme.spacing(2),
  },
}));

const BookingDetailsItem: React.FC<BookingDetailsItemProps> = ({
  headerName,
  noDetailsMessage,
  onDelete,
  customer,
  customerId,
  service,
}) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <Typography
            variant={"h6"}
            className={classes.headerName}
            spacingAfter={!customer.details && noDetailsMessage ? 1 : 0}
          >
            {headerName}
          </Typography>
          {!customer.details && noDetailsMessage && (
            <Typography variant={"body1"} color={"textSecondary"}>
              {noDetailsMessage}
            </Typography>
          )}
        </div>
        {typeof onDelete === "function" && (
          <Button
            onClick={onDelete}
            className={classes.headerButton}
            size={"small"}
            variant={"text"}
          >
            Remove person
          </Button>
        )}
      </div>

      {customer.details && (
        <Box mb={2}>
          <CustomerAppointmentItem {...customer} service={service} />
        </Box>
      )}

      <Button
        color={"default"}
        children={customer.details ? "Edit details" : "Add details"}
        fullWidth
        onClick={() =>
          history.push(generatePath(Paths.CustomerDetails, { customerId }))
        }
      />
      <InputField
        type="hidden"
        name={`hasDetails-${customer.id}`}
        required={!customer.details}
        spacingAfter={0}
        validationMessages={
          customerId === "owner"
            ? bookingDetailsOwnerValidationMessages
            : bookingDetailsOtherValidationMessages
        }
      />
    </div>
  );
};
export default BookingDetailsItem;
