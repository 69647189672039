import React from "react";
import {Box, Grid} from "@material-ui/core";
import { Spacing, SpacingProps, Typography } from "@welldigital/components";
import { SvgIconComponent } from "@material-ui/icons";

export type AccountDashboardDetailsProp = {
  icon: SvgIconComponent;
  label: string;
  items: AccountDashboardDetail[];
  spacingAfter?: SpacingProps["spacing"];
};

export type AccountDashboardDetail = {
  label: string;
  body: string;
};

const AccountDashboardDetails: React.FC<AccountDashboardDetailsProp> = ({
  icon: Icon,
  label,
  items,
  spacingAfter = 2,
}) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item>
          <Icon />
        </Grid>
        <Grid item xs>
          <Typography variant={"h6"} spacingAfter={1}>
            {label}
          </Typography>
          {items.map(({ label, body }, k) => (
            <Box key={k} mb={k === items.length - 1 ? 0 : 1}>
              <Typography variant={"subtitle1"} spacingAfter={0}>
                {label}
              </Typography>
              <Typography
                variant={"body1"}
                color={"textSecondary"}
                spacingAfter={0}
              >
                {body}
              </Typography>
            </Box>
          ))}
        </Grid>
      </Grid>
      {!!spacingAfter && <Spacing spacing={spacingAfter} />}
    </>
  );
};

export default AccountDashboardDetails;
