import React, { useCallback } from "react";
import {
  checkIfUnder18,isAbove74,
  checkIfDateIsValid,
} from "../../CustomerDetailsPage.utils";
import { WatchFields } from "@welldigital/components";
import { GuardianTypes } from "../../../../stores/appointment/appointment.types";
import { Service } from "../../../../stores/service/service.types";

export type DisplayDetailsProps = {
  isOwner: boolean;
  service:Service;
};

export type CustomDetailsWatchFieldsParams = {
  guardianType: GuardianTypes;
  "details.birthDate": Date;
  consent: boolean;
};

export type CanDisplayDetailsProps = {
  guardianType: GuardianTypes;
  birthDate: Date;
  consent: boolean;
};

const DisplayDetails: React.FC<DisplayDetailsProps> = ({
  children,
  isOwner,
  service
}) => {
  const checkIfCanDisplayDetails = useCallback(
    ({ guardianType, birthDate, consent }: CanDisplayDetailsProps): boolean => {
      if (!checkIfDateIsValid(birthDate)) return false;
      if (!birthDate) return false;
      let isUnder18;
      if(service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES ) {
        isUnder18 = checkIfUnder18(birthDate) || isAbove74(birthDate);
      } else {
        isUnder18 = checkIfUnder18(birthDate);
      }
      
      if (isOwner) {
        return !isUnder18;
      }
      if (!isUnder18) {
        return !!consent;
      }
      return !!guardianType;
    },
    [isOwner, service.id]
  );

  return (
    <WatchFields fieldNames={["guardianType", "details.birthDate", "consent"]}>
      {({
        guardianType,
        "details.birthDate": birthDate,
        consent,
      }: CustomDetailsWatchFieldsParams) =>
        checkIfCanDisplayDetails({ guardianType, birthDate, consent }) &&
        children
      }
    </WatchFields>
  );
};

export default DisplayDetails;
