import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    "& .braintree-placeholder": {
      display: "none",
    },
    "& .braintree-upper-container:before": {
      background: "none",
    },
  },
}));

const braintreeContainerId = "braintree_container";
export const BRAINTREE_CONTAINER_ID = `#${braintreeContainerId}`;

const PaymentPanel: React.FC = () => {
  const classes = useStyles();
  return <div className={classes.container} id={braintreeContainerId} />;
};

export default PaymentPanel;
