import React from "react";
import { Typography } from "@welldigital/components";
import LayoutNavigation from "../../../../components/LayoutNavigation/LayoutNavigation";
import { Service } from "../../../../stores/service/service.types";
import { WatchFields } from "@welldigital/components";
import { checkIfUnder18 } from "../../CustomerDetailsPage.utils";

export type CustomDetailsWatchFieldsParams = {
  "details.birthDate": Date;
};
export type IneligibleProps = {
  service: Service;
};

const Ineligible: React.FC<IneligibleProps> = ({service}) => {
  return (
    <>
     {
          service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES? 
          <WatchFields fieldNames={["details.birthDate"]}>
          {({ "details.birthDate": birthDate }: CustomDetailsWatchFieldsParams) =>
            checkIfUnder18(birthDate)?   <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
            Because you are under 18, you are unable to book an appointment for
            this service.
          </Typography> : <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
            Because you are above 74, you are unable to book an appointment for
            this service.
          </Typography>
          }
        </WatchFields>:
  
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        Because you are under 18, you are unable to book an appointment for
        yourself. Your guardian needs to book the appointment for you and attend
        with you on the day.
      </Typography>
        }
      <LayoutNavigation
        nextButton={{
          href: "https://www.well.co.uk/vaccinations",
          children: "Return to booking home",
        }}
      />
    </>
  );
};
export default Ineligible;
