import React, { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { Typography } from "@welldigital/components";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { generatePath, useHistory } from "react-router-dom";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import { Paths } from "../paths";
import useRescheduleNotAllowed from "./hooks/useRescheduleNotAllowed";

const AccountAppointmentRescheduleNotAllowedPage: React.FC = () => {
  const history = useHistory();
  const { appointmentId, appointment, error } = useRescheduleNotAllowed();

  const onSubmit = useCallback(() => {
    history.push(
      generatePath(Paths.AccountAppointmentCancel, {
        appointmentId,
      })
    );
  }, [history, appointmentId]);

  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Manage your appointment
      </Typography>
      <Typography variant={"h6"}>
        We require over 24 hours’ notice to reschedule your booking.
      </Typography>
      <Typography variant={"body2"} color={"textSecondary"} spacingAfter={2}>
        If you can no longer attend your appointment, please cancel using the
        link below.
      </Typography>
      <Typography variant={"body2"} color={"textSecondary"} spacingAfter={4}>
        Please note, if you cancel within 48 hours of your appointment time, you
        will not be eligible for a refund.
      </Typography>
      <AccountInfoMessage pharmacy={appointment?.pharmacy} />
      <LayoutNavigation
        nextButton={{
          onClick: onSubmit,
          children: "Cancel appointment",
        }}
      />
    </Layout>
  );
};

export default AccountAppointmentRescheduleNotAllowedPage;
