import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Collapse } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { analytics } from "@welldigital/ui-common";
import { format } from "date-fns";
import events from "../../events";
import {
  Alert,
  DateSelect,
  Radio,
  Spacing,
  TimeAvailability,
  Typography,
} from "@welldigital/components";
import NextAvailableSlotLoader from "./components/NextAvailableSlotLoader/NextAvailableSlotLoader";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import AvailabilityLoader from "./components/AvailabilityLoader/AvailabilityLoader";
import { useFindAvailability } from "./hooks/useFindAvailability";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import { Paths } from "../paths";
import {
  getSlotMaximumDate,
  getSlotMinimumDate,
} from "./BookAppointmentPage.utils";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Appointment, PaymentTypes } from "../../stores/appointment/appointment.types";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import { useBooking } from "../PaymentPage/hooks/useBooking";
import { useCartStore } from "../../stores/cart/cart.store";
import { Slot } from "./BookAppointmentPage.types";
import { ServiceIds } from "../../stores/service/service.constants";

enum BookAppointmentRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
  bookingDetailsFinished = "bookingDetailsFinished",
}

type BookAppointmentPageProps = Pick<
  Appointment,
  keyof typeof BookAppointmentRequiredProps
>;

const BookAppointmentPage: React.FC<BookAppointmentPageProps> = ({
  service: actualService,
  pharmacy,
  owner,
}) => {
  const history = useHistory();
  const [appointment, setBookedSlot] = useAppointmentStore((state) => [
    state.appointment,
    state.setBookedSlot, 
  ]);
  const [isNextChecked, setNextChecked] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<Slot | null>(null);
  const [dateSelected, setDateSelected] = useState<Date | null>(null);
  const [customersBooked, totalFee] = useCartStore((state) => [
    state.customersBooked,
    state.totalFee,
  ]);
  const numberOfBookings = customersBooked.length;
  const isFlu = useMemo(
    () => actualService?.id === ServiceIds.Flu,
    [actualService]
  );
  const service = owner.serviceOverride || actualService;
  const locationId = pharmacy.id;

  let otherExemptArray = appointment?.other?.filter((item=> item.paymentType === PaymentTypes.exempt));
  let NHSSelected = false;
  if(appointment?.owner?.paymentType === PaymentTypes.exempt || appointment?.owner?.paymentType === PaymentTypes.exemptPayment  || (otherExemptArray && otherExemptArray?.length> 0)){
    NHSSelected = true;
  }
  const minDate = useMemo(
    () => getSlotMinimumDate({ isFlu, locationId, NHSSelected}),
    [isFlu, locationId, NHSSelected ]
  );
  const maxDate = useMemo(
    () => getSlotMaximumDate({ isFlu, locationId }),
    [isFlu, locationId]
  );

  const {
    calendarData,
    slots,
    fetchSlots,
    error: availabilityError,
    nextAvailableSlot,
    fetchMonthCalendarData,
  } = useFindAvailability({
    minDate,
    maxDate,
    appointment,
    customersBooked,
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { bookAllCustomers, bookAllCustomerInitialAppt, booking, error: bookingError, initError: initBookingError } = useBooking();

  useEffect(() => {
    if (initBookingError) {
      setNextChecked(false);
      setSelectedSlot(null);
    }
    if (!(availabilityError && !nextAvailableSlot)) return;
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.appointment.noneAvailable,
      metadata: {
        location: pharmacy.name,
      },
    });
  }, [
    pharmacy.name,
    service,
    availabilityError,
    nextAvailableSlot,
    initBookingError
  ]);

  const continueFlow = useCallback(async (): Promise<void> => {
    if (!service || !selectedSlot) return;

    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.appointment.selected,
    });

    setBookedSlot(selectedSlot);
    await bookAllCustomerInitialAppt(selectedSlot, locationId, service.id);

    if (totalFee === 0) {
      await bookAllCustomers(selectedSlot);
    }
    history.push(Paths.Payment);
  }, [
    service,
    selectedSlot,
    setBookedSlot,
    totalFee,
    history,
    locationId,
    bookAllCustomers,
    bookAllCustomerInitialAppt
  ]);

  const radioOnChange = useCallback(() => {
    setNextChecked(true);
    setDateSelected(null);
    setSelectedSlot(nextAvailableSlot);
  }, [nextAvailableSlot]);

  const dateOnChange = useCallback(
    async (date: Date | null) => {
      setNextChecked(false);
      setSelectedSlot(null);
      setDateSelected(date);
      if (date) {
        await fetchSlots(date);
      }
    },
    [setDateSelected, fetchSlots, setSelectedSlot]
  );

  return (
    <MainLayout error={availabilityError || bookingError}>
      <Typography variant={"h4"} spacingAfter={2}>
        {dateSelected ? "Select an appointment" : "Next available appointment"}
      </Typography>
      <Collapse in={!dateSelected}>
        <Typography
          variant={"body1"}
          spacingAfter={3}
          spacingResponsive
          color={"textSecondary"}
        >
          You can choose the next available appointment or search for a specific
          date and time.
          {numberOfBookings > 1 && (
            <strong>
              {" "}
              Everyone in your booking needs to arrive at the same time.
            </strong>
          )}
        </Typography>
        {!nextAvailableSlot ? (
          <NextAvailableSlotLoader />
        ) : (
          <Radio
            value={isNextChecked}
            onChange={radioOnChange}
            variant={"contained"}
            disabled={!nextAvailableSlot}
            label={
              nextAvailableSlot
                ? format(new Date(nextAvailableSlot.startTime), "dd MMMM yyyy")
                : undefined
            }
            description={
              nextAvailableSlot
                ? format(new Date(nextAvailableSlot.startTime), "p")
                : undefined
            }
            fullWidth
          />
        )}
        <Spacing spacing={3} />
        <Typography variant={"h6"}>Select a date</Typography>
      </Collapse>

      <DateSelect
        value={dateSelected}
        onChange={dateOnChange}
        label={!dateSelected ? "Select a date" : undefined}
        fetchMonthData={fetchMonthCalendarData}
        data={calendarData}
        disablePast
        minDate={minDate}
        maxDate={maxDate}
      // shouldDisableDate={(maxDate: any) => maxDate.getTime() === new Date('2022-09-07T00:00').getTime() || maxDate.getTime() === new Date('2022-09-08T00:00').getTime() || maxDate.getTime() === new Date('2022-09-09T00:00').getTime() || maxDate.getTime() === new Date('2022-09-10T00:00').getTime()}
      />

      {initBookingError && (
        <Alert type={"error"} message={initBookingError.message} />
      )}

      <Collapse in={!!dateSelected}>
        <Spacing spacing={3} responsive />
        <Typography variant={"h6"}>Available appointments</Typography>
        {!slots?.length ? (
          <AvailabilityLoader />
        ) : (
          <TimeAvailability
            value={selectedSlot || undefined}
            onChange={setSelectedSlot}
            slots={slots}
          />
        )}
      </Collapse>

      <LayoutNavigation
        nextButton={{
          onClick: continueFlow,
          disabled: !selectedSlot,
          children: "Next",
          loading: booking,
        }}
      />
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<BookAppointmentPageProps>
    validatedProps={
      Object.keys(BookAppointmentRequiredProps) as (keyof Appointment)[]
    }
    page={BookAppointmentPage}
  />
);