import React from "react";
import { Alert, AlertProps, ButtonProps } from "@welldigital/components";
import { ChevronLeft } from "@material-ui/icons";
import { Link, LinkProps } from "react-router-dom";

export type ErrorAlertProps = {
  message: string;
  action?: ButtonProps & Partial<LinkProps>;
  spacingAfter?: AlertProps["spacingAfter"];
  isBlocking?: boolean;
};

const ErrorAlert: React.FC<ErrorAlertProps> = ({
  message,
  action,
  spacingAfter = 2,
}) => {
  return (
    <Alert
      type={"error"}
      message={message}
      action={
        action
          ? {
              // TODO: should integrate this into the button component
              component: action.to ? Link : undefined,
              startIcon: <ChevronLeft />,
              ...action,
            }
          : undefined
      }
      spacingAfter={spacingAfter}
    />
  );
};

export default ErrorAlert;
