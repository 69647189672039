import { useCallback, useState } from "react";
import { v4 as generateUuid } from "uuid";
import { addMinutes, differenceInMinutes } from "date-fns";
import { bookAppointmentsAPI,bookInitialAppointmentsAPI } from "../../../api";
import { useCartStore } from "../../../stores/cart/cart.store";
import { useAppointmentStore } from "../../../stores/appointment/appointment.store";
import { Slot } from "../../BookAppointmentPage/BookAppointmentPage.types";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { PaymentTypes } from "../../../stores/appointment/appointment.types";
import { initErrorMessage } from "utils/utils";
import { API_INIT_KEY, BookAppointmentCompleteResponse, InitialAppointment } from "api/api.types";
import { getFromLocalStorage, setToLocalStorage } from "pages/LocationsPage/hooks/utils";

export type BookingState = {
  bookAllCustomers: (bookedSlot: Slot, paymentNonce?: string) => Promise<void>;
  booking: boolean;
  bookAllCustomerInitialAppt: (
    bookedSlot: Slot,
    locationId: string,
    serviceId: number
  ) => Promise<void>;
  initError?: ErrorAlertProps;
  error?: ErrorAlertProps;
};

export function useBooking(): BookingState {
  const customersBooked = useCartStore((state) => state.customersBooked);
  const setAppointmentComplete = useAppointmentStore(
    (state) => state.setAppointmentComplete
  );
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [booking, setBooking] = useState(false);
  const [error, setError] = useState<ErrorAlertProps>();
  const [initError, setInitError] = useState<ErrorAlertProps>();
  
  const generateBookedSlots = useCallback(
    (bookedSlot: Slot): Slot[] => {
      const { startTime, endTime, resourceId } = bookedSlot;
      const slotSize = differenceInMinutes(
        new Date(endTime),
        new Date(startTime)
      );
      let lastTime = new Date(startTime);
      return customersBooked.map(() => {
        const slot = {
          startTime: lastTime,
          endTime: addMinutes(lastTime, slotSize),
          resourceId,
        };
        lastTime = slot.endTime;
        return slot;
      });
    },
    [customersBooked]
  );

 const bookAllCustomerInitialAppt: BookingState["bookAllCustomerInitialAppt"] =
  useCallback(
    async (bookedSlot, locationId, serviceId) => {
      try {
        const bookedSlots = generateBookedSlots(bookedSlot);
        const initialAppointment = await bookInitialAppointmentsAPI(
          bookedSlots.map((slot: Slot) => {
            return {
              locationId,
              serviceId,
              resourceId: parseInt(slot.resourceId),
              startDateTime: slot.startTime,
              endDateTime: slot.endTime,
            };
          })
        );

        setToLocalStorage<InitialAppointment>(
          API_INIT_KEY,
          initialAppointment
        );
      } catch (e) {

        setInitError({ message: initErrorMessage});


        throw e;
      } finally {
      }
    },
    [generateBookedSlots]
  );

  const bookAllCustomers: BookingState["bookAllCustomers"] = useCallback(
    async (bookedSlot, paymentNonce) => {
      setBooking(true);
      try {
        let groupId: string;
        if (customersBooked.length > 1) {
          groupId = generateUuid();
        }
        const bookedSlots = generateBookedSlots(bookedSlot);
        const initialAppointment =
        getFromLocalStorage<InitialAppointment>(API_INIT_KEY) || undefined;
        let response : any = await bookAppointmentsAPI(customersBooked.map(
          ({ screeningId, paymentType }, k) => {
            const slot = bookedSlots[k] as Slot;
            return {
              screeningId,
              paymentNonce,
              resourceId: parseInt(slot.resourceId),
              startDate: slot.startTime,
              endDate: slot.endTime,
              initiator: "customer",
              paymentType:
                paymentType ||
                (paymentNonce ? PaymentTypes.payment : PaymentTypes.exempt),
                groupId,
                initialAppointment
            };
          }
        ));
        setToLocalStorage<BookAppointmentCompleteResponse>(
          API_INIT_KEY,
          response
        );
        setAppointmentComplete();
      } catch (e) {
        setError({ message: e.response?.data.message || e.message });
        throw e;
      } finally {
        setBooking(false);
      }
    },
    [generateBookedSlots, customersBooked, setAppointmentComplete]
  );

  return {
    bookAllCustomers,
    bookAllCustomerInitialAppt,
    booking,
    initError,
    error,
  };
}