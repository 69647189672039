import { Paths } from "../../pages/paths";
import { Appointment } from "../../stores/appointment/appointment.types";
import { ErrorAlertProps } from "../ErrorAlert/ErrorAlert";

export const RequiredAppointmentPropsErrorMap: {
  [key in keyof Partial<Appointment>]: ErrorAlertProps;
} = {
  // You are at least on the locations page, no service is set
  service: {
    message:
      "You need to choose a booking type first, for example a vaccination",
    action: {
      children: "Go back to well.co.uk",
      href: "https://well.co.uk",
    },
  },
  // You are at least on Booking For page, no pharmacy selected
  pharmacy: {
    message: "You need to choose a pharmacy",
    action: {
      children: "Select a pharmacy",
      to: Paths.Locations,
    },
  },
  // You are at least on Booking Details page, booking type selected
  bookingType: {
    message: "We need to know who the booking is for",
    action: {
      children: "Enter booking details",
      to: Paths.BookingFor,
    },
  },
  // You are at least on Select an Appointment screen, the booking details are not set
  bookingDetailsFinished: {
    message: "We need more information",
    action: {
      children: "Enter booking details",
      to: Paths.BookingDetails,
    },
  },
  // You are at least on the payment page, no slot is selected
  bookedSlot: {
    message: "You need to choose an appointment time",
    action: {
      children: "Select an appointment",
      to: Paths.BookAppointment,
    },
  },
  // You are on The confirmation  page, the payment is not made
  appointmentComplete: {
    message: "You need to complete checkout",
    action: {
      children: "Go to checkout",
      to: Paths.Payment,
    },
  },
};
