import React, { useMemo } from "react";
import { Container, Grid, makeStyles, Theme } from "@material-ui/core";
import Logo from "../../../../Logo/Logo";
import { Typography, wellColors } from "@welldigital/components";
import FooterLink from "../FooterLink/FooterLink";
import { useAppointmentStore } from "../../../../../stores/appointment/appointment.store";
import getContactEmailByService from "../../../../../utils/getContactEmailByService.util";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${wellColors.greyscale[300]}`,
  },
  container: {
    paddingTop: 100,
    paddingBottom: 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
      paddingBottom: 30,
    },
  },
  logo: {
    display: "block",
    width: 110,
    height: "auto",
    marginBottom: theme.spacing(6),
    "& path": {
      fill: wellColors.elixir[900],
    },
  },
}));

const FooterFirst: React.FC = () => {
  const classes = useStyles();
  const service = useAppointmentStore((state) => state.appointment?.service);

  const contactEmail = useMemo(
    () => getContactEmailByService(service),
    [service]
  );

  return (
    <div className={classes.root}>
      <Container maxWidth={"lg"} className={classes.container}>
        <Grid container>
          <Grid item sm={12} md={5} lg={4}>
            <Logo className={classes.logo} />
            <Typography
              variant={"caption"}
              color={"textSecondary"}
              spacingAfter={2}
            >
              This content was written and edited by Well. Our health
              information is evidence based, up-to-date and reviewed by health
              professionals. It isn’t intended to replace the medical
              information given to you by your doctor.
            </Typography>
            <Typography
              variant={"caption"}
              color={"textSecondary"}
              spacingAfter={4}
            >
              We aim to provide you with the knowledge and support you need to
              make confident decisions about your health and wellbeing.
            </Typography>
          </Grid>
          <Grid item sm={"auto"} md={1} lg={2} />
          <Grid item sm={3} md={3} lg={2}>
            <FooterLink
              data-testid={"footer/link-to-t-and-c"}
              href={
                "https://www.well.co.uk/about-us/policies/terms-and-conditions-vaccination-services"
              }
              target={"_blank"}
            >
              Terms and conditions
            </FooterLink>
            <FooterLink
              data-testid={"footer/link-to-privacy-policy"}
              href={"https://www.well.co.uk/about-us/policies/privacy"}
              target={"_blank"}
            >
              Privacy policy
            </FooterLink>
            <FooterLink
              data-testid={"footer/link-to-cookies"}
              href={"https://www.well.co.uk/about-us/policies/cookies"}
              target={"_blank"}
            >
              Cookies
            </FooterLink>
          </Grid>
          <Grid item xs={2} md={"auto"} lg={1} />
          <Grid item sm={5} md={2} lg={2}>
            <Typography variant={"body1"} spacingAfter={0}>
              For support email
            </Typography>
            <FooterLink
              data-testid={"footer/link-to-help"}
              href={`mailto:${contactEmail}`}
              target={"_blank"}
            >
              {contactEmail}
            </FooterLink>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterFirst;
