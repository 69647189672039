import React from "react";
import CovidIneligibleOwner from "./CovidIneligibleOwner/CovidIneligibleOwner";
import CovidIneligibleOther from "./CovidIneligibleOther/CovidIneligibleOther";
import { Pharmacy } from "../../../../stores/appointment/appointment.types";

export type CovidIneligibleProps = {
  isOwner: boolean;
  pharmacy: Pharmacy;
  onOwnerContinue: () => void;
  onOtherContinue: () => void;
};

const CovidIneligible: React.FC<CovidIneligibleProps> = ({
  isOwner,
  pharmacy,
  onOwnerContinue,
  onOtherContinue,
}) => {
  if (isOwner) {
    return (
      <CovidIneligibleOwner pharmacy={pharmacy} onContinue={onOwnerContinue} />
    );
  }
  return (
    <CovidIneligibleOther pharmacy={pharmacy} onContinue={onOtherContinue} />
  );
};

export default CovidIneligible;
