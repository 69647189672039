import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Form, CheckboxField } from "@welldigital/components";
import CovidQuestion from "./components/CovidQuestion/CovidQuestion";
import { analytics } from "@welldigital/ui-common";
import events from "../../events";
import useCovidQuestions from "./hooks/useCovidQuestions";
import CovidInfo from "./components/CovidInfo/CovidInfo";
import {
  BookingTypes,
  Appointment,
} from "../../stores/appointment/appointment.types";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Box, Grid, Link } from "@material-ui/core";
import { covidConsentValidationMessages } from "../../components/validationMessages";
import CovidLoader from "./components/CovidLoading/CovidLoading";
import CovidIneligible from "./components/CovidIneligible/CovidIneligible";
import { Paths } from "../paths";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import { CovidFormAnswer } from "./hooks/hooks.types";

enum CovidQuestionsRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
}

type CovidQuestionsPageProps = Pick<
  Appointment,
  keyof typeof CovidQuestionsRequiredProps
>;

export type CovidQuestionsPageParams = {
  customerId: "owner" | string;
};

const CovidQuestionsPage: React.FC<CovidQuestionsPageProps> = ({
  bookingType,
  service,
  pharmacy,
  owner,
  other,
}) => {
  const [isIneligible, setIneligible] = useState<boolean>(false);
  const history = useHistory();
  const { customerId } = useParams<CovidQuestionsPageParams>();
  const isOwner = customerId === "owner";
  const customer = useMemo(
    () => (isOwner ? owner : other.find(({ id }) => customerId === id)),
    [customerId, other, owner, isOwner]
  );
  const { questions, answers, submitAnswers, error } = useCovidQuestions(
    customer
  );
  const {
    deleteOtherCustomer,
    setBookingDetailsFinished,
    setCovidQuestionsFinished,
  } = useAppointmentStore();

  useEffect(() => {
    if (!customer?.screeningId) return;
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.screening.started,
    });
  }, [customer, service]);

  const onNext = useCallback(
    async ({ answers }: { answers: CovidFormAnswer[] }) => {
      if (!customer) return;
      try {
        await submitAnswers(customer.screeningId, answers);
        analytics.trackEvent({
          flow: service.analyticsName,
          event: events.screening.complete,
        });
        if (bookingType === BookingTypes.owner) {
          setCovidQuestionsFinished(customerId);
          setBookingDetailsFinished();
          history.push(Paths.BookAppointment);
        } else {
          history.push(Paths.BookingDetails);
        }
      } catch (e) {
        if (e.message === "Ineligible") {
          setIneligible(true);
          analytics.trackEvent({
            flow: service.analyticsName,
            event: events.screening.ineligible,
            metadata: {
              reason: "answered yes",
            },
          });
        }
      }
    },
    [
      customer,
      submitAnswers,
      service.analyticsName,
      bookingType,
      setCovidQuestionsFinished,
      customerId,
      setBookingDetailsFinished,
      history,
      setIneligible,
    ]
  );

  const onIneligibleOwner = useCallback(() => {
    window.location.href = "https://www.well.co.uk/vaccinations";
  }, []);

  const onIneligibleOther = useCallback(() => {
    if (!customer) return;
    deleteOtherCustomer(other.indexOf(customer));
    history.push(Paths.BookingDetails);
  }, [customer, deleteOtherCustomer, history, other]);

  return (
    <MainLayout error={error}>
      <Form
        onSubmit={onNext}
        initialValues={answers ? { answers, understandTTR: true } : undefined}
      >
        {typeof answers === "undefined" ? (
          <CovidLoader />
        ) : isIneligible ? (
          <CovidIneligible
            isOwner={isOwner}
            pharmacy={pharmacy}
            onOwnerContinue={onIneligibleOwner}
            onOtherContinue={onIneligibleOther}
          />
        ) : (
          <>
            <Typography variant={"h4"}>Covid-19 screening</Typography>
            <CovidInfo isOwner={isOwner} />

            <Box mt={4}>
              <Grid container spacing={2}>
                {questions.map((question, index) => (
                  <Grid item key={question.number} xs={12}>
                    <CovidQuestion
                      baseName={`answers[${index}]`}
                      {...question}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <CheckboxField
              name={"understandTTR"}
              label={
                <>
                  I understand that this service is not suitable for Test to
                  Release.
                  <br />
                  <Link
                    href="https://www.well.co.uk/coronavirus-testing"
                    target="_blank"
                  >
                    Find out more on our website
                  </Link>
                </>
              }
              required
              validationMessages={covidConsentValidationMessages}
            />

            <LayoutNavigation
              nextButton={{
                type: "submit",
                children: "Next",
              }}
            />
          </>
        )}
      </Form>
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<CovidQuestionsPageProps>
    validatedProps={
      Object.keys(CovidQuestionsRequiredProps) as (keyof Appointment)[]
    }
    page={CovidQuestionsPage}
  />
);
