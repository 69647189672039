import { Appointment, BookingTypes } from "../appointment.types";
import { mockBookingSlot, mockPharmacy, mockService } from "../__mocks__";
import {
  mockCustomerDetails,
  mockSurgeryDetails,
} from "../../account/mockData/account.mockData";

export const mockAppointment: Appointment = {
  id: "123",
  service: mockService,
  pharmacy: mockPharmacy,
  bookingType: BookingTypes.owner,
  owner: {
    id: "123",
    details: mockCustomerDetails,
    surgeryDetails: mockSurgeryDetails,
    screeningId: "123",
  },
  other: [],
  bookedSlot: mockBookingSlot,
  appointmentComplete: true,
  bookingDetailsFinished: true,
  traversalId: '123'
};
