import React, { useEffect, useState } from "react";
import { Typography } from "@welldigital/components";
import { Box, makeStyles, Grid, TextField, } from "@material-ui/core";




const useStyles = makeStyles(() => ({

  container: {
    border: "1px solid #ECECEC",
    borderRadius: "4px",
    backgroundColor: "#ffffff",
    "&:not(:last-child)": {
      marginBottom: "30px",
    },
  },
  headerText: {
    fontSize: "22px",
    fontWeight: 500,
    color: "#0C161F",
  }, headerTextMedium: {
    fontSize: "18px",
    color: "#445461",
    fontWeight: 500,
  }, headerTextSmall: {
    fontSize: "16px",
    fontWeight: 500,
    color: "#0C161F",
  },
  yourText: {
    fontSize: "18px",
    color: "#0C161F",
  },
  yourTextSmall: {
    fontSize: "14px",
    color: "#0C161F",
  },
  centimeterText: {
    fontSize: "16px",
    width: "130px",
    color: "#747474",
    height: 38, '& input + fieldset': {
      borderColor: '#747474',
    },
  },
  textFeild: {
    fontSize: "16px",
    width: "100px",
    color: "#747474",
    height: 38, '& input + fieldset': {
      borderColor: '#747474',
    },
  }, root: {
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  }, btnContainer: {
    paddingTop: "30px"
  },
  headerBox: {
    paddingTop: 20,
  }, headerBox2: {
    paddingTop: 40,
  }, questionText: {
    fontSize: "22px",
    fontWeight: 500,
    color: "#0C161F",
  }, cardView: {
    maxWidth: "700px",
  }, smallText: {
    paddingTop: 10,
    paddingLeft: 5,
    fontSize: "16px",
    fontWeight: 500,
    color: "#445461",
    display: "inline-block",
  }, metricText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0061F2",
  }, topheaderView: {
    height: 80,
    bagroundColor: "#fff"
  },
  continueButton: {
    backgroundColor: "#0033E8",
    fontSize: "14px",
    color: "#fff",
    minWidth: "4px",
    minHeight: "64px",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#0033E8",
    },
  },
  footer: {
    overflowY: "hidden",
  },
  logo: {
    height: "55px",
    width: "165px",
    marginLeft: "121px",
    marginTop: "10px"
  }

}));
interface bMIValue {
  getBMIValue: (BmiMetricResults: number) => void
 
}

//type bMIValue = (BmiMetricResults: string) => void;
const BmiContainer = ({ getBMIValue, }: bMIValue) => {


  const classes = useStyles();
  const [bmiValue, setBmiValue] = useState(0);
  const [feet, setFeet] = useState("");
  const [inches, setInch] = useState("");
  const [stone, setStone] = useState("");
  const [ponds, setPonds] = useState("");
  const [meter, setMeter] = useState("");
  const [centimenter, setCentimenter] = useState("");
  const [kilograms, setKilograms] = useState("");
  const [isBmi, setBmi] = useState(false);
  const [imperialtoggle, setImperialToggle] = useState(false);
  const [metrictoggle, setMetricToggle] = useState(true);
  const [imperialWeighttoggle, setImperialWeightToggle] = useState(false);
  const [metricWeighttoggle, setMetricWeightToggle] = useState(true);
  const [errorFeet, setErrorFeet] = useState(false);
  const [errorFeetHelper, setErrorFeetHelper] = useState(false);
  const [errorInch, setErrorInch] = useState(false);
  const [errorInchHelper, setErrorInchHelper] = useState(false);
  const [errorStone, setErrorStone] = useState(false);
  const [errorStoneHelper, setErrorStoneHelper] = useState(false);
  const [errorPonds, setErrorPonds] = useState(false);
  const [errorPondsHelper, setErrorPondsHelper] = useState(false);
  const [errorMeter, setErrorMeter] = useState(false);
  const [errorMeterHelper, setErrorMeterHelper] = useState(false);
  const [errorCentimeter, setErrorCentimeter] = useState(false);
  const [errorCentimeterHelper, setErrorCentimeterHelper] = useState(false);
  const [errorKilogram, setErrorKilogram] = useState(false);
  const [errorKilogramHelper, setErrorKilogramHelper] = useState(false);


  const onFeetCahange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const feetVal = ((event.target as HTMLInputElement).value)
    setFeet(feetVal)
    if (feetVal.length === 0 || feetVal === "0") {
      setErrorFeet(true)
      setErrorFeetHelper(true)
    } else {
      setErrorFeet(false)
      setErrorFeetHelper(false)
    }
  };


  const onInchCahange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const inchVal = ((event.target as HTMLInputElement).value)
    setInch(inchVal)
    if (inchVal.length === 0 || inchVal=== "0") {
      setErrorInch(true)
      setErrorInchHelper(true)
    } else {
      setErrorInch(false)
      setErrorInchHelper(false)
    }
  };
  const onStoneCahange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const stoneVal = ((event.target as HTMLInputElement).value)
    setStone(stoneVal)
    if (stoneVal.length === 0 || stoneVal=== "0") {
      setErrorStone(true)
      setErrorStoneHelper(true)
    } else {
      setErrorStone(false)
      setErrorStoneHelper(false)
    }
  };
  const onPondCahange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const pondVal = ((event.target as HTMLInputElement).value)
    setPonds(pondVal)
    if (pondVal.length === 0 || pondVal=== "0") {
      setErrorPonds(true)
      setErrorPondsHelper(true)
    } else {
      setErrorPonds(false)
      setErrorPondsHelper(false)
    }
  };

  const onMeterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const meterVal = ((event.target as HTMLInputElement).value)
    setMeter(meterVal)
    if (meterVal.length === 0 || meterVal==="0") {
      setErrorMeter(true)
      setErrorMeterHelper(true)
    } else {
      setErrorMeter(false)
      setErrorMeterHelper(false)
    }
  };
  const onKilogramChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const kilogramVal = ((event.target as HTMLInputElement).value)
    setKilograms(kilogramVal)
    if (kilogramVal.length === 0) {
      setErrorKilogram(true)
      setErrorKilogramHelper(true)
    } else {
      setErrorKilogram(false)
      setErrorKilogramHelper(false)
    }
  };

  const onCentimeterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const kilogramVal = ((event.target as HTMLInputElement).value)
    setCentimenter(kilogramVal)
    if (kilogramVal.length === 0 || kilogramVal === "0") {
      setErrorCentimeter(true)
      setErrorCentimeterHelper(true)
    } else {
      setErrorCentimeter(false)
      setErrorCentimeterHelper(false)
    }
  };

  const metricOnClick = () => {
    setImperialToggle(true);
    setMetricToggle(false);
  };

  const imperialOnClick = () => {
    setImperialToggle(false);
    setMetricToggle(true);
  };
  const metricWeightOnClick = () => {
    setImperialWeightToggle(true);
    setMetricWeightToggle(false);
  };

  const imperialWeightOnClick = () => {
    setImperialWeightToggle(false);
    setMetricWeightToggle(true);
  };
useEffect(()=> {
  const timeout = setTimeout(() => {
  let BmiResults;
  if(metrictoggle && metricWeighttoggle) {
    if(meter && centimenter && kilograms) {
    const metricHeight = (((Number(meter) *100) + (Number(centimenter)))/100);
    const metricHeightSquare = (Number(metricHeight) * Number(metricHeight));
    BmiResults = (Number(kilograms) / Number(metricHeightSquare));
    if(BmiResults > 0)
    setBmiValue(BmiResults);
    setBmi(true);
    }else {
      setBmiValue(0);
      setBmi(true);
    }
  }else if(imperialtoggle && imperialWeighttoggle){
    if(ponds && feet && inches && stone) {
    const imperialHeight = (Number(feet)*12)+ Number(inches);
    const imperialWeight = (Number(stone)*14)+ Number(ponds);
    const imperialHeightSquare = Number(imperialHeight)* Number(imperialHeight);
    BmiResults = ((Number(imperialWeight) / Number(imperialHeightSquare)) * 703);
    if(BmiResults > 0)
    setBmiValue(BmiResults);
    setBmi(true);
    }else {
      setBmiValue(0);
      setBmi(true);
    }
  }
  else if(metrictoggle && imperialWeighttoggle) {
    if(meter && centimenter && ponds && stone) {
    const metricHeight = (((Number(meter) *100) + (Number(centimenter)))/100);
    const metricHeightSquare = (Number(metricHeight) * Number(metricHeight));
    const weight = ((Number(stone)*14)+ Number(ponds)) *  0.45359237;
    BmiResults = (Number(weight) / Number(metricHeightSquare));
    if(BmiResults > 0)
    setBmiValue(BmiResults);
    setBmi(true);
    }else {
      setBmiValue(0);
      setBmi(true);
    }
  }else if(imperialtoggle && metricWeighttoggle) {
    if(feet && inches && kilograms) {
      const imperialHeight = (Number(feet)*12)+ Number(inches);
      const imperialWeight = (Number(kilograms) * 2.20462);
      const imperialHeightSquare = Number(imperialHeight)* Number(imperialHeight);
      BmiResults = ((Number(imperialWeight) / Number(imperialHeightSquare)) * 703);
      if(BmiResults > 0)
      setBmiValue(BmiResults);
      setBmi(true);
      }else {
        setBmiValue(0);
        setBmi(true);
      }
  }
},600);
return () => {
  clearTimeout(timeout);
};
}, [ponds,stone,feet,inches,meter,centimenter,kilograms,imperialtoggle, metrictoggle, metricWeighttoggle, imperialWeighttoggle]);

useEffect(()=> {
 if(bmiValue >= 0 && isBmi) {
  getBMIValue(bmiValue);
  setBmi(false);
 }

}, [bmiValue, isBmi, getBMIValue]);
  return (
    <Box className={classes.container}>

    {/* <Box >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box> <Typography className={classes.questionText}>Question [number]</Typography></Box>
        </Grid>
      </Grid>
    </Box> */}

    {/* Imperial  starting*/}
      
      {imperialtoggle &&
         <>
        <Box className={classes.btnContainer}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box> <Typography className={classes.headerTextSmall}>Height</Typography></Box>
            </Grid>
            <Grid item xs={3}>
              <Box> <Typography onClick={imperialOnClick} className={classes.metricText}>Metric</Typography></Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.cardView}>
                <Box >
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box
                       >
                        <TextField
                          size="small"
                          label="Feet*"
                          defaultValue={feet}
                          onChange={onFeetCahange}
                          InputProps={{ className: classes.textFeild, }}
                          helperText={errorFeetHelper ? "Enter valid feet" : ""}
                          error={errorFeet}
                        />
                        <Typography className={classes.smallText}>ft</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box
                        >
                        <TextField
                          label="Inches*"
                          size="small"
                          defaultValue={inches}
                          onChange={onInchCahange}
                          InputProps={{ className: classes.textFeild, }}
                          helperText={errorInch ? "Enter valid inch" : ""}
                          error={errorInchHelper}
                        />
                        <Typography className={classes.smallText}>in</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box></>
}

{metrictoggle &&
      <>
        <Box className={classes.btnContainer}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box> <Typography className={classes.headerTextSmall}>Height</Typography></Box>
            </Grid>
            <Grid item xs={4}>
              <Box> <Typography onClick={metricOnClick} className={classes.metricText}>Imperial</Typography></Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container spacing={0}>

            <Grid item xs={12}>
              <Box className={classes.cardView}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box
                       >
                        <TextField
                          size="small"
                          label="Meters*"
                          defaultValue={meter}
                          onChange={onMeterChange}
                          InputProps={{ className: classes.textFeild, }}
                          helperText={errorMeter ? "Enter valid meter" : ""}
                          error={errorMeterHelper}
                        />
                        <Typography className={classes.smallText}>m</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box>
                        <TextField
                          size="small"
                          label="Centimeters*"
                          defaultValue={centimenter}
                          onChange={onCentimeterChange}
                          InputProps={{ className: classes.centimeterText }}
                          helperText={errorCentimeter ? "Enter valid centimeter" : ""}
                          error={errorCentimeterHelper}
                        />
                        <Typography className={classes.smallText}>cm</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box></>}
        { imperialWeighttoggle && 

        <>
        <Box className={classes.btnContainer}>
          <Grid container spacing={2}>

            <Grid item xs={8}>
              <Box> <Typography className={classes.headerTextSmall}>Weight</Typography></Box>
            </Grid>
            <Grid item xs={3}>
              <Box> <Typography onClick={imperialWeightOnClick} className={classes.metricText}>Metric</Typography></Box>
            </Grid>
          </Grid>
        </Box>

        <Box  >
          <Grid container spacing={2}>
            <Grid item xs={1}>
            </Grid>
            <Grid item xs={12}>
              <Box >
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box
                        >
                        <TextField
                          label="Stone*"
                          defaultValue={stone}
                          size="small"
                          onChange={onStoneCahange}
                          InputProps={{ className: classes.textFeild, }}
                          helperText={errorStone ? "Enter valid stone" : ""}
                          error={errorStoneHelper} />
                        <Typography className={classes.smallText}>st</Typography>
                      </Box>
                    </Grid>

                    <Grid item xs={4}>
                      <Box>
                        <TextField
                          label="Pounds*"
                          size="small"
                          defaultValue={ponds}
                          onChange={onPondCahange}
                          InputProps={{ className: classes.textFeild, }}
                          helperText={errorPonds ? "Enter valid pounds" : ""}
                          error={errorPondsHelper} />
                        <Typography className={classes.smallText}>lb</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        </>
}
      
    {/* Imperial ending */}


    {/* Metric  starting*/}
       {metricWeighttoggle && 
       <>
        <Box className={classes.btnContainer}>
          <Grid container spacing={2}>

            <Grid item xs={8}>
              <Box> <Typography className={classes.headerTextSmall}>Weight</Typography></Box>
            </Grid>
            <Grid item xs={3}>
              <Box> <Typography onClick={metricWeightOnClick} className={classes.metricText}>Imperial</Typography></Box>
            </Grid>
          </Grid>
        </Box>

        <Box  >
          <Grid container spacing={0}>

            <Grid item xs={12}>
              <Box className={classes.cardView}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Box
                        >
                        <TextField
                          label="Kilogram*"
                          defaultValue={kilograms}
                          onChange={onKilogramChange}
                          InputProps={{ className: classes.textFeild, }} 
                          helperText={errorKilogram ? "Enter valid kilogram" : ""}
                          error={errorKilogramHelper}
                          />
                        <Typography className={classes.smallText}>kg</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        </>
       }
      
    {/* Metric ending */}



  </Box>




  );
};

export default BmiContainer;
