import React, { FC } from "react";
import FooterFirst from "./components/First/FooterFirst";
import FooterSecond from "./components/Second/FooterSecond";

const Footer: FC = () => {
  return (
    <footer>
      <FooterFirst />
      <FooterSecond />
    </footer>
  );
};

export default Footer;
