import React, { useMemo } from "react";
import {
  EmailSuggestionField,
  InputField,
  IRadioOption,
  RadioSelectField,
  Typography,
  WatchFields,
} from "@welldigital/components";
import AddressFields from "../AddressFields/AddressFields";
import { Genders } from "../../../../api/api.types";
import { Box } from "@material-ui/core";
import { checkIfUnder18 } from "../../CustomerDetailsPage.utils";
import {
  emailValidationMessages,
  firstNameTheirValidationMessages,
  firstNameValidationMessages,
  genderValidationMessages,
  lastNameTheirValidationMessages,
  lastNameValidationMessages,
  phoneValidationMessages,
} from "../../../../components/validationMessages";
import { Service } from "../../../../stores/service/service.types";

export type CustomerDetailsProps = {
  isOwner: boolean;
  service: Service;
};

const CustomerDetailsFields: React.FC<CustomerDetailsProps> = ({ isOwner, service }) => {
  const genderOptions: IRadioOption[] = useMemo(
    () => [
      { label: "Male", id: Genders.male },
      { label: "Female", id: Genders.female },
    ],
    []
  );

  return (
    <Box mt={1}>
      <Typography variant={"h6"}>
        {isOwner ? "Enter your name" : "Enter their name"}
      </Typography>
      <InputField
        name={"details.firstName"}
        label={"First name"}
        required
        validationMessages={
          isOwner
            ? firstNameValidationMessages
            : firstNameTheirValidationMessages
        }
      />
      <InputField
        name={"details.lastName"}
        label={"Last name"}
        required
        validationMessages={
          isOwner ? lastNameValidationMessages : lastNameTheirValidationMessages
        }
      />

     {service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES ? null :
     <Box mt={2}>
     <Typography variant={"h6"} spacingAfter={1}>
       {isOwner ? "Are you male or female?" : "Are they male or female?"}*
     </Typography>
     <RadioSelectField
       name={"details.gender"}
       options={genderOptions}
       required
       spacingAfter={1}
       validationMessages={genderValidationMessages}
     />
   </Box>
     }

      <AddressFields isOwner={isOwner} />

      <WatchFields fieldNames={["details.birthDate"]}>
        {({ "details.birthDate": birthDate }: any) =>
          !checkIfUnder18(birthDate) && (
            <Box mt={2}>
              <Typography variant={"h6"}>
                {isOwner
                  ? "Your contact information"
                  : "Their contact information"}
              </Typography>
              <Typography
                variant={"body1"}
                color={"textSecondary"}
                spacingAfter={2}
              >
                {isOwner
                  ? "We’ll email your booking confirmation to the email address you provide. We need your phone number to contact you if anything changes with your appointment."
                  : "Please provide their contact details in case we need to contact them about the appointment."}
              </Typography>
              <InputField
                name={"details.email"}
                label={"Email address"}
                required
                email
                validationMessages={emailValidationMessages}
                afterComponent={
                  <EmailSuggestionField fieldName={"details.email"} />
                }
              />
              <InputField
                name={"details.phone"}
                label={"Phone number"}
                required
                validationMessages={phoneValidationMessages}
              />
            </Box>
          )
        }
      </WatchFields>
    </Box>
  );
};

export default CustomerDetailsFields;
