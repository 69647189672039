import React from "react";
import Layout from "../../components/Layout/Layout";
import { Tabs, ITab } from "@welldigital/components";
import { useAccountDashboard } from "./hooks/useAccountDashboard";
import { Grid } from "@material-ui/core";
import AccountDashboardSidebar from "./components/Sidebar/AccountDashboardSidebar";
import Appointment from "./components/Appointment/AccountDashboardAppointment";
import AppointmentLoader from "./components/AppointmentLoader/AccountDashboardAppointmentLoader";
import SidebarLoader from "./components/SidebarLoader/AccountDashboardSidebarLoader";

const AccountDashboardPage: React.FC = () => {
  const {
    tabIndex,
    setTabIndex,
    appointments,
    details,
    surgeryDetails,
  } = useAccountDashboard();

  const tabs: ITab[] = [
    { label: "Next appointments" },
    { label: "Previous appointments" },
  ];

  return (
    <Layout>
      <Tabs items={tabs} tabIndex={tabIndex} setTabIndex={setTabIndex} />
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <Grid container spacing={4}>
            {!appointments
              ? new Array(2).fill("").map((i, k) => (
                  <Grid item key={k} xs={4}>
                    <AppointmentLoader />
                  </Grid>
                ))
              : appointments.map((appointment, k) => (
                  <Grid item key={k} xs={4}>
                    <Appointment {...appointment} />
                  </Grid>
                ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          {!(details && surgeryDetails) ? (
            <SidebarLoader />
          ) : (
            <AccountDashboardSidebar
              details={details}
              surgeryDetails={surgeryDetails}
            />
          )}
        </Grid>
      </Grid>
    </Layout>
  );
};

export default AccountDashboardPage;
