import React, { useMemo } from "react";
import { Box, Link } from "@material-ui/core";
import { Button, Typography } from "@welldigital/components";
import MainLayout from "../../components/MainLayout/MainLayout";
import {
  BookingTypes,
  Appointment,
} from "../../stores/appointment/appointment.types";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import { format } from "date-fns";
import { Paths } from "../paths";
import { useHistory } from "react-router-dom";
import CustomerAppointmentItem from "../../components/CustomerAppointmentItem/CustomerAppointmentItem";
import { useCartStore } from "../../stores/cart/cart.store";
import { formatCustomerNumber } from "../CustomerDetailsPage/CustomerDetailsPage.utils";
import ConfirmationNextInfo from "./components/NextInfo/NextInfo";

enum ConfirmationPageRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
  bookingDetailsFinished = "bookingDetailsFinished",
  bookedSlot = "bookedSlot",
  appointmentComplete = "appointmentComplete",
}

type ConfirmationPageProps = Pick<
  Appointment,
  keyof typeof ConfirmationPageRequiredProps
>;

export const ConfirmationPage: React.FC<ConfirmationPageProps> = ({
  bookingType,
  pharmacy,
  service: actualService,
  owner,
  bookedSlot,
}) => {
  const history = useHistory();
  const customersBooked = useCartStore((state) => state.customersBooked);
  const confirmationCustomers = useMemo(() => {
    const customers = [...customersBooked];
    if (bookingType === BookingTypes.otherExcludingOwner) {
      // If the booking type excludes owner from the booking customer,
      // we must reintroduce it here to be shown on the confirmation screen
      customers.unshift(owner);
    }
    return customers;
  }, [bookingType, customersBooked, owner]);
  const service = owner?.serviceOverride || actualService;

  const formattedAddress: string = useMemo(() => {
    if (!pharmacy.address) return "";
    const { addressLine1, addressLine2, city, postalCode } = pharmacy.address;
    let address = addressLine1;
    if (addressLine2) {
      address += ` ${addressLine2}`;
    }
    address += `, ${city}`;
    address += `, ${postalCode}`;
    return address;
  }, [pharmacy.address]);

  return (
    <MainLayout hideCart>
      <Typography variant={"h4"}>
        Thank you, your appointment is booked
      </Typography>
      <Typography variant={"h5"} spacingAfter={0}>
        Appointment details
      </Typography>

      <Box mt={4}>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={1}>
          Location
        </Typography>
        <Typography variant={"h6"} spacingAfter={1}>
          {pharmacy.name}
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"}>
          {formattedAddress}
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={1}>
          Date and time
        </Typography>
        <Typography variant={"h6"} spacingAfter={1}>
          {format(new Date(bookedSlot.startTime), "EEEE, d MMMM y, h:mmaaaa")}
        </Typography>
        {!!pharmacy.phone && (
          <Typography variant={"body1"} color={"textSecondary"}>
            If you need to change this for any reason you can call the pharmacy
            on <Link href={`tel:${pharmacy.phone}`}>{pharmacy.phone}</Link>
          </Typography>
        )}
      </Box>

      <Box mt={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            history.push(`${Paths.Locations}?serviceId=${actualService.id}`)
          }
          size={"large"}
        >
          Book another appointment
        </Button>
      </Box>

      <Box mt={6}>
        {confirmationCustomers.map((customer, k) => (
          <Box mb={4} key={k}>
            <CustomerAppointmentItem
              showAddress={k === 0}
              title={
                k === 0
                  ? "Your details"
                  : `Customer ${formatCustomerNumber(
                      bookingType === BookingTypes.otherExcludingOwner
                        ? k
                        : k + 1
                    )}`
              }
              {...customer}
              service={
                k === 0 && bookingType === BookingTypes.otherExcludingOwner
                  ? undefined
                  : service
              }
            />
          </Box>
        ))}
      </Box>

      <ConfirmationNextInfo />
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<ConfirmationPageProps>
    validatedProps={
      Object.keys(ConfirmationPageRequiredProps) as (keyof Appointment)[]
    }
    page={ConfirmationPage}
  />
);
