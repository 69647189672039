import React, { useEffect, useState } from "react";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import {
  Pharmacy,
  Appointment,
} from "../../stores/appointment/appointment.types";
import { Paths } from "../../pages/paths";
import { useLocationQuery } from "../../utils/utils";
import { getPharmacyById } from "./RouteValidator.utils";
import { useHistory } from "react-router-dom";
import ErrorPageLayout from "./components/ErrorPageLayout/ErrorPageLayout";
import { RequiredAppointmentPropsErrorMap } from "./RouteValidator.types";
import { useCartStore } from "../../stores/cart/cart.store";
import { ErrorAlertProps } from "../ErrorAlert/ErrorAlert";
import { Service } from "../../stores/service/service.types";
import { useServiceStore } from "../../stores/service/service.store";

type RouteValidatorProps<T> = {
  page: React.FC<T>;
  validatedProps: (keyof Appointment)[];
};

type RouteValidatorQuery = {
  serviceId: string;
  pharmacyId: string;
};

export default function RouteValidator<T extends Partial<Appointment>>({
  page: Page,
  validatedProps,
}: React.PropsWithoutRef<RouteValidatorProps<T>>) {
  const serviceMap = useServiceStore((state) => state.serviceMap);
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [ready, setReady] = useState(false);
  const [error, setError] = useState<ErrorAlertProps>();
  const { appointment, initAppointment } = useAppointmentStore();
  const [calculateCart, totalFee] = useCartStore((state) => [
    state.calculateCart,
    state.totalFee,
  ]);
  const { serviceId, pharmacyId } = useLocationQuery<RouteValidatorQuery>();
  const shouldRedirectToConfirmation =
    !serviceId &&
    appointment?.appointmentComplete &&
    window.location.pathname !== Paths.Confirmation;

  useEffect(() => {
    if (!appointment) return;
    const { bookingType, owner, other } = appointment;
    const service = owner?.serviceOverride || appointment?.service;
    if (service) {
      calculateCart({ service, bookingType: bookingType, owner, other: other });
    }
  }, [appointment, calculateCart]);

  useEffect(() => {
    if (!appointment) return;
    if (shouldRedirectToConfirmation) {
      history.push(Paths.Confirmation);
    }
  });

  useEffect(() => {
    if ((!appointment && !serviceId) || !serviceId) {
      // Handles validation
      for (const prop of validatedProps) {
        if (!(appointment || {})[prop]) {
          const error = RequiredAppointmentPropsErrorMap[prop];
          if (error) {
            setError(error);
          }
          return;
        }
      }
      setReady(true);
    } else {
      // Handles appointment init
      (async () => {
        let service: Service | undefined;
        let pharmacy: Pharmacy | undefined;
        if (serviceId) {
          try {
            service = serviceMap[parseInt(serviceId)];
            if (!service) {
              throw Error("No service");
            }
          } catch (e) {
            setError({
              message: `No vaccination service found`,
              action: {
                children: "Return to Well website",
                href: "https://www.well.co.uk/vaccinations",
              },
            });
            return;
          }
        }
        if (pharmacyId) {
          try {
            pharmacy = await getPharmacyById(pharmacyId);
          } catch (e) {
            setError({
              message: `The location with id ${pharmacyId} does not exist`,
              action: {
                children: "Return to Well website",
                href: "https://well.co.uk",
              },
            });
            return;
          }
        }
        initAppointment(service as Service, pharmacy);
        history.replace(pharmacy ? Paths.BookingFor : Paths.Locations);
        setError(undefined);
      })();
    }
  }, [
    history,
    initAppointment,
    pharmacyId,
    appointment,
    serviceId,
    validatedProps,
    serviceMap,
  ]);

  if (
    shouldRedirectToConfirmation ||
    (appointment && totalFee === -1) ||
    (!ready && !error) ||
    (!appointment && !error)
  )
    return null;
  if (error) {
    return <ErrorPageLayout {...error} />;
  }
  return <Page {...(appointment as T)} />;
}
