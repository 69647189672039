import { useEffect, useMemo, useState } from "react";
import {
  Appointment,
  CustomerDetails,
  SurgeryDetails,
} from "../../../stores/appointment/appointment.types";
import { useAccountStore } from "../../../stores/account/account.store";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";

export type AccountDashboardState = {
  tabIndex: number;
  setTabIndex: (tabIndex: number) => void;
  appointments?: Appointment[];
  details?: CustomerDetails;
  surgeryDetails?: SurgeryDetails;
  error?: ErrorAlertProps;
};

export function useAccountDashboard(): AccountDashboardState {
  const [tabIndex, setTabIndex] = useState(0);
  const [
    details,
    surgeryDetails,
    rawAppointments,
    fetchAccountDetails,
    fetchAppointments,
    error,
  ] = useAccountStore((state) => [
    state.details,
    state.surgeryDetails,
    state.appointments,
    state.fetchAccountDetails,
    state.fetchAppointments,
    state.error,
  ]);

  useEffect(() => {
    fetchAccountDetails();
    fetchAppointments();
  }, [fetchAccountDetails, fetchAppointments]);

  const filteredAppointments: Appointment[] | undefined = useMemo(() => {
    if (!rawAppointments) return;
    if (tabIndex === 0) {
      return rawAppointments.filter(() => true); // TODO: implement
    } else {
      return rawAppointments.filter(() => true); // TODO: implement
    }
  }, [rawAppointments, tabIndex]);

  return {
    tabIndex,
    setTabIndex,
    appointments: filteredAppointments,
    details,
    surgeryDetails,
    error,
  };
}
