import React from "react";
import { Container, makeStyles, Theme } from "@material-ui/core";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ErrorAlert, { ErrorAlertProps } from "../ErrorAlert/ErrorAlert";

export type MainLayoutProps = {
  error?: ErrorAlertProps;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
};

const useStyles = makeStyles((theme: Theme) => ({
  "@global": {
    body: {
      backgroundColor: "#fff !important",
      position: "relative",
    },
  },
  container: {
    minHeight: 500,
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const Layout: React.FC<MainLayoutProps> = ({ children, error, maxWidth }) => {
  const classes = useStyles();

  return (
    <>
      <Header />
      <Container className={classes.container} maxWidth={maxWidth}>
        {error && <ErrorAlert {...error} spacingAfter={4} />}
        {!(error && error.isBlocking) && children}
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
