import React, { useCallback, useEffect } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { analytics } from "@welldigital/ui-common";
import { Typography } from "@welldigital/components";
import { NoLocationsMessage } from "./components/NoLocationsMessage/NoLocationsMessage";
import { LocationItem } from "./components/LocationItem/LocationItem";
import { SearchControls } from "./components/SearchControls/SearchControls";
import events from "../../events";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useLocationSearch } from "./hooks/useLocationSearch";
import { Box, Grid } from "@material-ui/core";
import { Paths } from "../paths";
import {
  Pharmacy,
  Appointment,
  BookingTypes
} from "../../stores/appointment/appointment.types";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";

enum LocationSelectorRequiredProps {
  service = "service",
}

type LocationsPageProps = Pick<
  Appointment,
  keyof typeof LocationSelectorRequiredProps
>;

export const LocationsPage: React.FC<LocationsPageProps> = ({ service }) => {
  const history = useHistory();
  const { initAppointment, setBookingType } = useAppointmentStore();
  const {
    searchTerm,
    currentLocation,
    onSearch,
    onSearchByCurrentLocation,
    isSearching,
    isLocationSearching,
    results,
    error,
  } = useLocationSearch({ service });

  const onPharmacySelect = useCallback(
    (selectedPharmacy: Pharmacy) => {
      initAppointment(service, selectedPharmacy);

      analytics.trackEvent({
        flow: service.analyticsName,
        event: events.location.selected,
        metadata: {
          pharmacyId: selectedPharmacy.id,
        },
      });
      if(service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES){
        setBookingType(BookingTypes.owner);
        //console.log('hello path is', generatePath(Paths.CustomerDetails, { customerId: "owner" }));
        history.push(
          generatePath(Paths.CustomerDetails, { customerId: "owner" })
        );
      } else {
      history.push(Paths.BookingFor);
      }
    },
    [initAppointment, service, history, setBookingType]
  );

  useEffect(() => {
    if (!results || results.length) return;

    const coordinates = currentLocation
      ? `${currentLocation.coords.latitude}, ${currentLocation.coords.longitude}`
      : undefined;

    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.location.noneAvailable,
      metadata: {
        searchTerm,
        coordinates,
      },
    });
  }, [currentLocation, results, searchTerm, service.analyticsName]);

  return (
    <MainLayout>
      <Typography variant={"h4"} data-testid="LocationsPage/title">
        Find your local Well pharmacy
      </Typography>

      <SearchControls
        searchTerm={searchTerm}
        currentLocation={currentLocation}
        onSearch={onSearch}
        onSearchByCurrentLocation={onSearchByCurrentLocation}
        isSearching={isSearching}
        isLocationSearching={isLocationSearching}
      />

      <Box mt={2}>
        {error ? (
          <NoLocationsMessage />
        ) : results?.length ? (
          <>
            <Typography
              variant={"body2"}
              color={"textSecondary"}
              spacingAfter={3}
              data-testid="LocationsPage/list-message"
            >
              {service.name} is available at the pharmacies listed below. Your
              local pharmacy may not offer this service at the moment.
            </Typography>
            <Grid container spacing={2}>
              {results.map((pharmacy, k) => (
                <Grid item xs={12} key={k}>
                  <LocationItem
                    onLocationSelect={onPharmacySelect}
                    {...pharmacy}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          results && <NoLocationsMessage />
        )}
      </Box>
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<LocationsPageProps>
    validatedProps={
      Object.keys(LocationSelectorRequiredProps) as (keyof Appointment)[]
    }
    page={LocationsPage}
  />
);
