import React from "react";
import { Typography } from "@welldigital/components";
import { Link } from "@material-ui/core";

export type CovidInfoProps = {
  isOwner: boolean;
};

const CovidInfo: React.FC<CovidInfoProps> = () => {
  return (
    <>
      <Typography variant="body1">
        This test is not suitable if you think you might have COVID-19. You
        should not book an appointment if you or someone you live with has any
        symptoms of coronavirus, including:
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">a high temperature, or</Typography>
        </li>
        <li>
          <Typography variant="body1">a new, continuous cough,</Typography>
        </li>
        <li>
          <Typography variant="body1">
            or loss or change to sense of smell or taste.
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        Check the{" "}
        <Link
          target="_blank"
          href="https://www.nhs.uk/conditions/coronavirus-covid-19/"
        >
          NHS website for information
        </Link>{" "}
        and advice if you have symptoms of coronavirus.
      </Typography>
    </>
  );
};

export default CovidInfo;
