import React, { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { Paths } from "../paths";
import { Box, Divider } from "@material-ui/core";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { generatePath, useHistory } from "react-router-dom";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import AppointmentCancelConfirmationDetails from "./components/AppointmentCancelConfirmDetails";
import useCancelConfirm from "./hooks/useCancelConfirm";

const AppointmentCancelConfirmPage: React.FC = () => {
  const history = useHistory();
  const { cancel, error, isCancelling, cancelData, token } = useCancelConfirm();

  const onSubmit = useCallback(async () => {
    if (!cancelData) return;
    try {
      await cancel(cancelData.token);
      history.push(
        generatePath(Paths.AppointmentTokenCancelConfirmation, { token })
      );
    } catch (e) {}
  }, [cancel, history, cancelData, token]);

  return (
    <Layout error={error} maxWidth={"sm"}>
      <AppointmentCancelConfirmationDetails
        startTime={cancelData?.startDate}
        pharmacy={cancelData?.pharmacy}
      />
      <AccountInfoMessage pharmacy={cancelData?.pharmacy} />
      <LayoutNavigation
        nextButton={{
          onClick: onSubmit,
          children: "Confirm",
          endIcon: undefined,
          loading: isCancelling,
        }}
      />
      <Box my={4}>
        <Divider />
      </Box>
    </Layout>
  );
};

export default AppointmentCancelConfirmPage;
