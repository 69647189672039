import React from "react";
import OpeningTimes from "../OpeningTimes/OpeningTimes";
import PharmacyAddress from "../PharmacyAddress/PharmacyAddress";
import { ChevronRight } from "@material-ui/icons";
import { Button, Typography, wellColors } from "@welldigital/components";
import { makeStyles, Theme } from "@material-ui/core";
import { Pharmacy } from "../../../../stores/appointment/appointment.types";

export type LocationItemProps = Pharmacy & {
  onLocationSelect(pharmacy: Pharmacy): void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    border: `1px solid ${wellColors.greyscale[500]}`,
    borderRadius: "6px",
  },
  main: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  content: {
    flex: 1,
  },
  distance: {
    flex: "0 0 auto",
  },
}));

export const LocationItem: React.FC<LocationItemProps> = (props) => {
  const { name, businessHours, address, travel, onLocationSelect } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.content}>
          <Typography variant={"h6"} color={"textPrimary"}>
            {name}
          </Typography>
          <OpeningTimes paragraph businessHours={businessHours!} />
          <PharmacyAddress address={address!} />
        </div>
        <div className={classes.distance}>
          <Typography variant={"h6"} color={"textPrimary"}>
            {travel?.distance}
          </Typography>
        </div>
      </div>
      <Button
        color={"primary"}
        endIcon={<ChevronRight />}
        onClick={() => onLocationSelect(props)}
        children={"Book here"}
      />
    </div>
  );
};
