import { subYears } from "date-fns";
import {
  CustomerAppointmentDetails,
  BookingTypes,
} from "../../stores/appointment/appointment.types";
import { CustomerDetailsPageParams } from "./CustomerDetailsPage";

export function checkIfDateIsValid(date: Date) {
  if (!date) return false;
  return !isNaN(new Date(date).getDate());
}

export function checkIfUnder18(date: Date): boolean {
  const targetDate = subYears(new Date(), 18);
  return new Date(date) > targetDate;
}
export function isDateValid(date: Date) {
  if (!date) return false;
  return !isNaN(new Date(date).getDate());
}
export function isAbove74(date: Date): boolean {
  const targetDate = subYears(new Date(), 74);
  return isDateValid(date) && new Date(date) < targetDate;
}

export function checkIfAboveExemptAge(date: Date): boolean {
  const targetDate = subYears(new Date(), parseInt(process.env.REACT_APP_FLU_AGE_EXEMPT!));
  return new Date(date) < targetDate;
}

export function getCustomerNumber(
  bookingType: BookingTypes,
  customerId: CustomerDetailsPageParams["customerId"],
  other: CustomerAppointmentDetails[]
): string {
  if (customerId === "owner") return "";
  let customerNumber = other.findIndex(({ id }) => customerId === id) + 1;
  if (bookingType === BookingTypes.otherIncludingOwner) {
    customerNumber++;
  }
  return formatCustomerNumber(customerNumber);
}

export function formatCustomerNumber(customerNumber: number): string {
  const customerNumberToStringMap: { [key: number]: string } = {
    1: "one",
    2: "two",
    3: "three",
    4: "four",
  };
  return customerNumberToStringMap[customerNumber] || "";
}
