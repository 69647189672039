import React from "react";
import { Alert } from "@welldigital/components";
import { Box } from "@material-ui/core";
import { useCartStore } from "../../../../../stores/cart/cart.store";

export type CartUpdatedAlertProps = {};

const CartUpdatedAlert: React.FC<CartUpdatedAlertProps> = () => {
  const isCartFlagVisible = useCartStore((state) => state.isCartFlagVisible);

  if (!isCartFlagVisible) return null;
  return (
    <Box mt={2}>
      <Alert
        type={"success"}
        message={"Your total has been updated"}
        size={"small"}
      />
    </Box>
  );
};

export default CartUpdatedAlert;
