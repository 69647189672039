import { useParams } from "react-router-dom";
import {
  AccountStoreState,
  useAccountStore,
} from "../../../stores/account/account.store";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { CancelData } from "../../../stores/account/account.types";
import { useMemo } from "react";

type Params = {
  token: string;
};

export type CancelConfirmState = {
  error?: ErrorAlertProps;
  cancelData?: CancelData;
  cancel: AccountStoreState["cancel"];
  isCancelling: AccountStoreState["isCancelling"];
  token: string;
};

export default function useCancelConfirm(): CancelConfirmState {
  const { token } = useParams<Params>();
  const [cancelData, cancel, appointmentError, isCancelling] = useAccountStore(
    (state) => [
      state.cancelMap[token],
      state.cancel,
      state.error,
      state.isCancelling,
    ]
  );
  const error: ErrorAlertProps | undefined = useMemo(() => {
    if (!cancelData) {
      return { message: "No cancellation data stored" };
    }
  }, [cancelData]);

  return {
    error: error || appointmentError,
    cancelData,
    cancel,
    isCancelling,
    token,
  };
}
