import React, { useCallback } from "react";
import { checkIfUnder18, isAbove74 } from "../../CustomerDetailsPage.utils";
import { WatchFields } from "@welldigital/components";
import { Service } from "../../../../stores/service/service.types";

export type DisplayIneligibleProps = {
  isOwner: boolean;
  service:Service;
};

export type CustomDetailsWatchFieldsParams = {
  "details.birthDate": Date;
};

export type CanDisplayIneligibleProps = {
  birthDate: Date;
};

const DisplayIneligible: React.FC<DisplayIneligibleProps> = ({
  children,
  isOwner,
  service
}) => {
  const checkIfCanDisplayIneligible = useCallback(
    ({ birthDate }: CanDisplayIneligibleProps): boolean => {
      if (!birthDate || !isOwner) return false;
      if(service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES ) {
        return checkIfUnder18(birthDate) || isAbove74(birthDate);
      }
      return checkIfUnder18(birthDate);
    },
    [isOwner, service.id]
  );

  return (
    <WatchFields fieldNames={["details.birthDate"]}>
      {({ "details.birthDate": birthDate }: CustomDetailsWatchFieldsParams) =>
        checkIfCanDisplayIneligible({ birthDate }) && children
      }
    </WatchFields>
  );
};

export default DisplayIneligible;
