import React, { useMemo } from "react";
import { Box, Divider, Link } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import getContactEmailByService from "../../../../utils/getContactEmailByService.util";
import { useAppointmentStore } from "../../../../stores/appointment/appointment.store";

const ConfirmationNextInfo: React.FC = () => {
  const service = useAppointmentStore((state) => state.appointment?.service);

  const contactEmail = useMemo(
    () => service && getContactEmailByService(service),
    [service]
  );
  return (
    <>
      <Box mt={4}>
        <Divider />
      </Box>

      <Box mt={4}>
        <Typography variant={"h6"} spacingAfter={2}>
          What happens next?
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          We’ll send you a confirmation email with your appointment details.
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          Find out more about our{" "}
          <Link href={"https://www.well.co.uk/vaccinations"}>
            vaccination services
          </Link>
          .
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          If you have any other questions, please email us at{" "}
          <Link href={`mailto:${contactEmail}`}>{contactEmail}</Link>
        </Typography>
      </Box>
    </>
  );
};

export default ConfirmationNextInfo;
