import React, { useCallback, useMemo, useRef, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { analytics } from "@welldigital/ui-common";
import events from "../../events";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Alert, Form, InputField, Typography } from "@welldigital/components";
import CustomerDetailsFields from "./components/CustomerDetailsFields/CustomerDetailsFields";
import CustomerNHSField from "./components/CustomerNHSField/CustomerNHSField";
import FluPaymentFields from "./components/FluPaymentFields/FluPaymentFields";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import {
  CustomerAppointmentDetails,
  BookingTypes,
  Appointment,
  GuardianTypes,
  PaymentTypes,
} from "../../stores/appointment/appointment.types";
import { Paths } from "../paths";
import MarketingField from "./components/MarketingField/MarketingField";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import FluAddon from "./components/FluAddon/FluAddOn";
import LegalGuardianFields from "./components/LegalGuardianFields/LegalGuardianFields";
import { getCustomerNumber } from "./CustomerDetailsPage.utils";
import DisplayDetails from "./components/DisplayDetails/DisplayDetails";
import BirthDateField from "./components/BirthDateField/BirthDateField";
import Ineligible from "./components/Ineligible/Ineligible";
import DisplayIneligible from "./components/DisplayIneligible/DisplayIneligible";
import DisplayGuardianFields from "./components/DisplayGuardianFields/DisplayGuardianFields";
import FluAddonModal, {
  DataForwarder,
} from "./components/FluAddon/Modal/FluAddOnModal";
import useScreeningQuestions from "../ScreeningQuestionsPage/hooks/useScreeningQuestions";
import GpDetailsFields from "./components/GpDetailsFields/GpDetailsFields";
import DisplayConsent from "./components/DisplayConsent/DisplayConsent";
import ConsentField from "./components/ConsentField/ConsentField";
import { Service } from "../../stores/service/service.types";
import { useServiceStore } from "../../stores/service/service.store";
import { ServiceIds } from "../../stores/service/service.constants";

enum CustomerDetailsRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
}

type CustomerDetailsPageProps = Pick<
  Appointment,
  keyof typeof CustomerDetailsRequiredProps
>;

export type CustomerDetailsPageParams = {
  customerId: "owner" | string;
};

const CustomerDetailsPage: React.FC<CustomerDetailsPageProps> = ({
  service,
  pharmacy,
  bookingType,
  owner,
  other,
}) => {
  const Flu = useServiceStore((state) => state.serviceMap[ServiceIds.Flu]);
  const { setOwnerDetails, setOtherCustomerDetails } = useAppointmentStore();
  const { getScreeningStart } = useScreeningQuestions();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [fluModalOpened, setFluModalOpened] = useState(false);
  const [nhsValidation, setNHSValidation] = useState(false);
  const { customerId } = useParams<CustomerDetailsPageParams>();
  const isOwner = customerId === "owner";
  const isExcludedOwner =
    isOwner && bookingType === BookingTypes.otherExcludingOwner;
  const customer = useMemo(
    () =>
      customerId === "owner"
        ? owner
        : other.find(({ id }) => customerId === id) || null,
    [customerId, other, owner]
  );
  const history = useHistory();
  const fluDataForwarder = useRef<DataForwarder>({} as DataForwarder);

  const openModal = useCallback(() => {
    setFluModalOpened(true);
    analytics.trackEvent({
      flow: Flu.analyticsName,
      event: events.customerDetails.wantsAddOn,
    });
  }, [Flu.analyticsName, setFluModalOpened]);

  const onFluAddonSelected = useCallback(
    (service: Service) => {
      analytics.trackEvent({
        flow: Flu.analyticsName,
        event: events.customerDetails.selectsAddOn,
        metadata: {
          service: service.name,
        },
      });
    },
    [Flu.analyticsName]
  );

  const onFluAddonConfirmed = useCallback(
    (addon: Service) => {
      analytics.trackEvent({
        flow: Flu.analyticsName,
        event: events.customerDetails.confirmsAddOn,
      });
    },
    [Flu.analyticsName]
  );

  const onFluPaymentSelected = useCallback(
    (paymentType: PaymentTypes) => {
      analytics.trackEvent({
        flow: Flu.analyticsName,
        event: events.customerDetails.selectsPayment,
        metadata: {
          paymentType,
        },
      });
    },
    [Flu.analyticsName]
  );

  const onGuardianTypeSelect = useCallback(
    (guardianType: GuardianTypes) => {
      analytics.trackEvent({
        flow: service.analyticsName,
        event: events.customerDetails.guardianTypeSelected,
        metadata: {
          guardianType,
        },
      });
    },
    [service]
  );

  const customerNumber = useMemo(() => {
    return getCustomerNumber(bookingType, customerId, other);
  }, [bookingType, customerId, other]);

  const initialValues = useMemo(() => {
    return customer || ({} as CustomerAppointmentDetails);
  }, [customer]);

  const onSubmit = useCallback(
    async (values: CustomerAppointmentDetails): Promise<void> => {
      if(values.details.nhsNumber?.length !== 10 && values.details.nhsNumber?.match(/^[0-9]+$/)) {
        setNHSValidation(true);
        await Promise.reject('error');
      } 
      if (!values.details || !customer) return;
      values.id = initialValues.id as string;
      const selectedService = values.serviceOverride || service;
      if (values?.serviceOverride?.id === ServiceIds.FluPPV)
        values.paymentType = values.paymentType+"_payment" as PaymentTypes
      else if(values?.serviceOverride?.id === ServiceIds.FluPrevenar13)
        values.paymentType = values.paymentType+"_payment" as PaymentTypes
      if (isOwner) {
        setOwnerDetails(values);
      } else {
        setOtherCustomerDetails(customerId, values);
      }

      if (values.allowMarketing) {
        analytics.enableMarketingTracking(values.details.email);
      }

      analytics.trackEvent({
        flow: selectedService.analyticsName,
        event: events.customerDetails.next,
      });

      if (isExcludedOwner) {
        history.push(Paths.BookingDetails);
      } else {
        await getScreeningStart(values, owner, service, pharmacy, true);
        history.push(generatePath(Paths.ScreeningQuestions, { customerId }));
      }
    },
    [
      customer,
      initialValues.id,
      service,
      isOwner,
      setOwnerDetails,
      setOtherCustomerDetails,
      customerId,
      getScreeningStart,
      owner,
      pharmacy,
      history,
      isExcludedOwner,
    ]
  );

  const isUserFound = useMemo(
    () => isOwner || !!other.find((customer) => customer.id === customerId),
    [customerId, isOwner, other]
  );

  const isFluPaymentShown = useMemo(() => {
    const isFlu = service.id === Flu.id;
    return isFlu && !isExcludedOwner;
  }, [Flu.id, service.id, isExcludedOwner]);

  const isGpDetailsShown = useMemo(() => {
    return !service.shouldSkipGpDetails && !isExcludedOwner;
  }, [service.shouldSkipGpDetails, isExcludedOwner]);

  const isFluAddonShown = useMemo(() => {
    const isOneCustomerAppointment = bookingType === BookingTypes.owner;
    const isFlu = service.id === Flu.id;
    return isOneCustomerAppointment && isFlu;
  }, [Flu.id, service.id, bookingType]);

  const onModalClose = useCallback(() => setFluModalOpened(false), []);

  return (
    <MainLayout>
      {!isUserFound ? (
        <Alert type={"error"} message={"The user does not exist"} />
      ) : (
        <Form onSubmit={onSubmit} initialValues={initialValues} fullWidth>
          <Typography variant={"h4"}>
            {isOwner ? "Your details" : `Customer ${customerNumber} details`}
          </Typography>

          <BirthDateField isOwner={isOwner} service={service} />

          <DisplayGuardianFields isOwner={isOwner}>
            <LegalGuardianFields
              isOwner={isOwner}
              onTypeSelect={onGuardianTypeSelect}
            />
          </DisplayGuardianFields>

          <DisplayConsent isOwner={isOwner}>
            <ConsentField />
          </DisplayConsent>

          <DisplayIneligible isOwner={isOwner} service={service}>
            <Ineligible service={service}/>
          </DisplayIneligible>

          <DisplayDetails isOwner={isOwner} service={service}>
            {!isOwner && (
              <Typography variant={"h4"} spacingAfter={3}>
                Continue with customer {customerNumber}'s details
              </Typography>
            )}

            <CustomerDetailsFields isOwner={isOwner} service={service} />

            <CustomerNHSField isOwner={isOwner} isNHSValid={nhsValidation}/>  

            {isGpDetailsShown && <GpDetailsFields isOwner={isOwner} />}

            {isFluPaymentShown && (
              <FluPaymentFields
                isOwner={isOwner}
                onPaymentSelected={onFluPaymentSelected}
              />
            )}

            {isFluAddonShown && (
              <>
                <FluAddon
                  openModal={openModal}
                  dataForwarder={fluDataForwarder}
                  onAddonConfirmed={onFluAddonConfirmed}
                />
                <InputField name={"serviceOverride"} type={"hidden"} />
                <InputField name={"surgeryDetails"} type={"hidden"} />
              </>
            )}

            {isOwner && <MarketingField />}

            <LayoutNavigation
              nextButton={{
                type: "submit",
                children: "Next",
              }}
            />
          </DisplayDetails>
        </Form>
      )}
      <FluAddonModal
        open={fluModalOpened}
        onClose={onModalClose}
        onOptionSelect={onFluAddonSelected}
        dataForwarder={fluDataForwarder}
      />
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<CustomerDetailsPageProps>
    validatedProps={
      Object.keys(CustomerDetailsRequiredProps) as (keyof Appointment)[]
    }
    page={CustomerDetailsPage}
  />
);
