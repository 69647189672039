import axios from "axios";
import {
  API_ENDPOINT,
  POSTCODE_ENDPOINT,
  AddressesByPostcodeResponse,
  FetchLocationInput,
  FetchLocationsResponse,
  GetAvailabilityParams,
  GetAvailabilityResponse,
  NextScreeningRequest,
  ScreeningResponsePayload,
  StartScreeningRequest,
  PostcodeLookupAddress,
  BookAppointment,
  BookInitAppointment,
  InitialAppointment,
  GetTokenResponse
} from "./api.types";
import {
  Appointment,
  CustomerDetails,
  Pharmacy,
  SurgeryDetails,
} from "../stores/appointment/appointment.types";
import { CovidQuestionType } from "../pages/CovidQuestionsPage/hooks/hooks.types";
import { sleep } from "@welldigital/components";
import {
  mockCustomerDetails,
  mockSurgeryDetails,
} from "../stores/account/mockData/account.mockData";
import { mockAppointment } from "../stores/appointment/mockData/appointment.mockData";
import { CancelData } from "../stores/account/account.types";
import { mockServices } from "../stores/service/mockData/service.mockData";
import { Service } from "../stores/service/service.types";

// TODO: this file will be replaced by GraphQL calls in the future

export async function fetchPharmacyByIdAPI(
  pharmacyId: string
): Promise<Pharmacy> {
  const { data } = await axios.get<Pharmacy>(
    `${API_ENDPOINT}/locations/${pharmacyId}`
  );
  return data;
}

export async function fetchLocationsAPI(
  params: FetchLocationInput
): Promise<FetchLocationsResponse> {
  const { data } = await axios.get<FetchLocationsResponse>(
    `${API_ENDPOINT}/locations`,
    { params }
  );
  return data;
}

export async function getAvailabilityAPI(
  params: GetAvailabilityParams
): Promise<GetAvailabilityResponse> {
  const { data } = await axios.get<GetAvailabilityResponse>(
    `${API_ENDPOINT}/availability`,
    { params }
  );
  return data;
}

export async function getTokenAPI(token: string): Promise<GetTokenResponse> {
  const { data } = await axios.get<GetTokenResponse>(
    `${API_ENDPOINT}/singleusetoken`,
    { params: { token } }
  );
  return data;
}

export async function getPaymentTokenAPI(): Promise<string> {
  const { data } = await axios.get<{ token: string }>(
    `${API_ENDPOINT}/paymenttoken`
  );
  return data.token;
}
export async function bookAppointmentsAPI(
  books: BookAppointment[]
): Promise<void> {
  const {data} = await axios.post(`${API_ENDPOINT}/books`, { books });
  return data;
}

export async function bookInitialAppointmentsAPI(
  booksInitial: BookInitAppointment[]
): Promise<InitialAppointment> {
  const { data } = await axios.post(`${API_ENDPOINT}/initialappointment`, booksInitial);
  return data;
}

export async function getAddressByPostcodeAPI(
  postcode: string
): Promise<PostcodeLookupAddress[]> {
  try {
    const { data } = await axios.get<AddressesByPostcodeResponse>(
      `${POSTCODE_ENDPOINT}/postcodes/${postcode}/addresses`
    );
    return data.addresses;
  } catch (e) {
    return [];
  }
}

const truncateAndFormatToUTC = (date : Date | string) : string => {
  const myDate = typeof date === 'string' ? new Date(date) : date;
  const _dt = `0${myDate.getDate()}`.slice(-2)
  const _mon = `0${myDate.getMonth()+1}`.slice(-2)
  const _yr = `${myDate.getFullYear()}`
  const _hr = '00' 
  const _min = '00' 
  const _sec = '00' 
  return `${_yr}-${_mon}-${_dt}T${_hr}:${_min}:${_sec}Z`
} 
export async function startScreeningAPI(
  props: StartScreeningRequest
): Promise<ScreeningResponsePayload> {
  const { data } = await axios.post<ScreeningResponsePayload>(
    `${API_ENDPOINT}/screening`,
     { ...props, dob: truncateAndFormatToUTC(props.dob)}
  );
  return data;
}

export async function nextScreeningAPI(
  props: NextScreeningRequest
): Promise<ScreeningResponsePayload> {
  const { data } = await axios.post<ScreeningResponsePayload>(
    `${API_ENDPOINT}/screening/next`,
    props
  );
  return data;
}

export async function submitCovidQuestionsAPI(
  screeningId: string,
  answers: CovidQuestionType[]
): Promise<void> {
  await axios.post<ScreeningResponsePayload>(
    `${API_ENDPOINT}/covid-questions`,
    { screeningId, answers }
  );
}

export async function cancelAppointmentAPI(
  cancelData: CancelData
): Promise<void> {
  await axios.post<CancelData>(
    `${API_ENDPOINT}/appointments/${cancelData.appointmentId}/cancel`,
    {
      locationId: cancelData.locationId,
      reason: cancelData.reason,
      token: cancelData.token,
    }
  );
}

export async function getAccountDetailsAPI(): Promise<{
  details: CustomerDetails;
  surgeryDetails: SurgeryDetails;
}> {
  await sleep(500);
  return {
    details: mockCustomerDetails,
    surgeryDetails: mockSurgeryDetails,
  };
}

export async function getAppointmentsAPI(): Promise<Appointment[]> {
  await sleep(500);
  return [mockAppointment, mockAppointment, mockAppointment];
}

export async function getAppointmentDetailsAPI(): Promise<Appointment> {
  await sleep(500);
  return mockAppointment;
}

export async function getServicesAPI(): Promise<Service[]> {
  await sleep(100);
  return mockServices;
}