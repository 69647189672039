import React, { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { CheckboxField, Form, Typography } from "@welldigital/components";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { Box, Divider, Link } from "@material-ui/core";
import { generatePath, useHistory } from "react-router-dom";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import DateFields from "./DateFields/AccountAppointmentRescheduleDateFields";
import { Paths } from "../paths";
import { Slot } from "../BookAppointmentPage/BookAppointmentPage.types";
import useReschedule from "./hooks/useReschedule";

const AccountAppointmentReschedulePage: React.FC = () => {
  const history = useHistory();
  const {
    setRescheduleSlot,
    appointment,
    appointmentId,
    fetchMonthCalendarData,
    calendarData,
    minDate,
    maxDate,
    slots,
    fetchSlots,
    error,
  } = useReschedule();

  const onSubmit = useCallback(
    ({ selectedSlot }: { selectedSlot: Slot }) => {
      setRescheduleSlot(appointmentId, selectedSlot);
      history.push(
        generatePath(Paths.AccountAppointmentRescheduleConfirm, {
          appointmentId,
        })
      );
    },
    [setRescheduleSlot, history, appointmentId]
  );

  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Reschedule your appointment
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        Thanks for letting us know that you would like to reschedule your
        appointment. You can choose the next available appointment, or search
        for a specific date and time below.
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={0}>
        Please note, if you cancel within 48 hours of your appointment time, you
        will not be eligible for a refund.
      </Typography>
      <Form onSubmit={onSubmit} fullWidth>
        <Box my={4}>
          <DateFields
            fetchMonthCalendarData={fetchMonthCalendarData}
            calendarData={calendarData}
            minDate={minDate}
            maxDate={maxDate}
            slots={slots}
            fetchSlots={fetchSlots}
          />
        </Box>

        <AccountInfoMessage pharmacy={appointment?.pharmacy} />

        <Box mt={4} mb={2}>
          <Divider />
        </Box>

        <CheckboxField
          name={"consent"}
          label={
            <>
              I have read and agree to the Well{" "}
              <Link
                href={
                  "https://www.well.co.uk/about-us/policies/terms-and-conditions-vaccination-services"
                }
                target={"_blank"}
              >
                Terms and Conditions
              </Link>
            </>
          }
          required
          validationMessages={{
            required: "You must confirm the above to continue",
          }}
        />

        <LayoutNavigation
          nextButton={{
            type: "submit",
            children: "Next",
          }}
        />
      </Form>
    </Layout>
  );
};

export default AccountAppointmentReschedulePage;
