import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { childNodePolyfillInit } from "@welldigital/ui-common";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import "@welldigital/components/font/index.css";

childNodePolyfillInit();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
