import React, { useCallback, useEffect } from "react";
import { analytics } from "@welldigital/ui-common";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { Form, ItemManager, Typography } from "@welldigital/components";
import BookingDetailsItem from "./components/BookingDetailsItem/BookDetailsItem";
import {
  CustomerAppointmentDetails,
  BookingTypes,
  Appointment,
} from "../../stores/appointment/appointment.types";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import MultipleCustomersAlert from "./components/MultipleCustomersAlert/MultipleCustomersAlert";
import { useHistory } from "react-router-dom";
import { Paths } from "../paths";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import events from "../../events";
import { useConfirmationModal } from "@welldigital/components";
import EmptyOwnerDetailsModal from "./components/EmptyOwnerDetailsModal/EmptyOwnerDetailsModal";
import { formatCustomerNumber } from "../CustomerDetailsPage/CustomerDetailsPage.utils";
import { useCartStore } from "../../stores/cart/cart.store";
import { ItemManagerItem } from "@welldigital/components/ItemManager";

enum BookingDetailsRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
}

type BookingDetailsPageProps = Pick<
  Appointment,
  keyof typeof BookingDetailsRequiredProps
>;

const BookingDetailsPage: React.FC<BookingDetailsPageProps> = ({
  bookingType,
  service,
  owner,
  other,
}) => {
  const history = useHistory();
  const otherCount = other?.length || 0;
  const [showCartFlag, customersBooked] = useCartStore((state) => [
    state.showCartFlag,
    state.customersBooked,
  ]);

  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    confirmationOpened,
    openConfirmation,
    onConfirmationReject,
  } = useConfirmationModal<true>();
  const { addOtherCustomer, deleteOtherCustomer, setBookingDetailsFinished } =
    useAppointmentStore();

  useEffect(() => {
    if (bookingType === BookingTypes.owner) {
      history.push(Paths.BookingFor);
    }
  }, [history, bookingType]);

  const handleAddOther = useCallback(async () => {
    try {
      if (!owner.details) {
        await openConfirmation(true);
      } else {
        addOtherCustomer();
      }
      showCartFlag();
    } catch (e) {}
  }, [owner, openConfirmation, addOtherCustomer, showCartFlag]);

  const handleRemoveOther = useCallback(
    async (customerIndex: number) => {
      deleteOtherCustomer(customerIndex);
      showCartFlag();
    },
    [deleteOtherCustomer, showCartFlag]
  );

  const goNext = useCallback(() => {
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.bookingDetails.next,
      metadata: {
        bookingFor: events.bookingFor.bookingTypeMap[bookingType],
        noOfPeople: customersBooked.length,
      },
    });
    setBookingDetailsFinished();
    history.push(Paths.BookAppointment);
  }, [
    bookingType,
    service,
    history,
    setBookingDetailsFinished,
    customersBooked.length,
  ]);

  const renderItemOther = useCallback(
    ({ index, onDelete, ...customer }: ItemManagerItem) => (
      <BookingDetailsItem
        index={index}
        onDelete={onDelete}
        headerName={`Customer ${formatCustomerNumber(index + 2)}`}
        customer={customer as CustomerAppointmentDetails}
        customerId={customer.id}
        service={service}
      />
    ),
    [service]
  );

  const renderItemRegular = useCallback(
    ({ index, onDelete, ...customer }: ItemManagerItem) => (
      <BookingDetailsItem
        index={index}
        noDetailsMessage={
          "Please enter the details of the person you are booking for."
        }
        onDelete={otherCount > 1 ? onDelete : undefined}
        headerName={`Customer ${formatCustomerNumber(index + 1)}`}
        customer={customer as CustomerAppointmentDetails}
        customerId={customer.id}
        service={service}
      />
    ),
    [otherCount, service]
  );

  return (
    <MainLayout>
      <Form onSubmit={goNext}>
        <Typography variant={"h4"}>Booking details</Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
          We need you to enter some information in order to book your
          appointment.
        </Typography>

        {bookingType === BookingTypes.otherIncludingOwner ? (
          <>
            <BookingDetailsItem
              headerName={"Your details"}
              customer={owner}
              customerId={"owner"}
              service={service}
            />
            <ItemManager
              items={other}
              renderItem={renderItemOther}
              spacingAfter={0}
              onAdd={handleAddOther}
              onRemove={handleRemoveOther}
              removeConfirmation={
                "Are you sure you want to remove this person?"
              }
              noItems={false}
              addButton={{ children: "Add another person" }}
              maxItems={3}
            />
          </>
        ) : (
          <>
            <BookingDetailsItem
              headerName={"Your details"}
              noDetailsMessage={
                "Please enter your details so we can contact you about the appointment if needed."
              }
              customer={owner}
              customerId={"owner"}
            />
            <ItemManager
              items={other || []}
              renderItem={renderItemRegular}
              spacingAfter={0}
              onAdd={handleAddOther}
              onRemove={handleRemoveOther}
              removeConfirmation={
                "Are you sure you want to remove this person?"
              }
              noItems={false}
              addButton={{ children: "Add another person" }}
              minItems={1}
              maxItems={4}
            />
          </>
        )}

        <MultipleCustomersAlert />

        <LayoutNavigation
          backButton={{
            onClick: () => history.push(Paths.BookingFor),
          }}
          nextButton={{
            type: "submit",
            children: "Next",
          }}
        />
      </Form>

      {confirmationOpened && (
        <EmptyOwnerDetailsModal onConfirmationReject={onConfirmationReject} />
      )}
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<BookingDetailsPageProps>
    validatedProps={
      Object.keys(BookingDetailsRequiredProps) as (keyof Appointment)[]
    }
    page={BookingDetailsPage}
  />
);
