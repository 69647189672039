import React, { useMemo } from "react";
import { Appointment } from "../../../../stores/appointment/appointment.types";
import {
  Typography,
  DetailsCard,
  DetailsCardProps,
} from "@welldigital/components";
import { Person, People } from "@material-ui/icons";
import { format } from "date-fns";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { generatePath, Link } from "react-router-dom";
import { Paths } from "../../../paths";

export type AccountDashboardAppointmentProps = Appointment & {};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
  },
}));

const AccountDashboardAppointment: React.FC<AccountDashboardAppointmentProps> =
  ({ id, service, pharmacy, bookedSlot, owner, other }) => {
    const classes = useStyles();
    const isMultipleBookings = !!other?.length;

    const icons: DetailsCardProps["icons"] = useMemo(
      () => [
        { component: isMultipleBookings ? People : Person, color: "primary" },
      ],
      [isMultipleBookings]
    );

    const actions: DetailsCardProps["buttons"] = useMemo(() => {
      return [
        {
          color: "primary",
          variant: "contained",
          component: Link,
          to: generatePath(Paths.AccountAppointmentManage, {
            appointmentId: id,
          }),
          children: "Manage your appointment",
        },
        {
          color: "secondary",
          variant: "outlined",
          component: Link,
          to: generatePath(Paths.AccountAppointmentManage, {
            // TODO: replace with the real path when implemented
            appointmentId: id,
          }),
          children: "View details",
        },
      ];
    }, [id]);

    return (
      <DetailsCard className={classes.root} icons={icons} buttons={actions}>
        <Typography variant={"h6"} spacingAfter={1}>
          {service.name}
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          Date {format(bookedSlot.startTime, "dd/MM/yyyy")}
        </Typography>
        <Typography variant={"subtitle1"} spacingAfter={1}>
          {isMultipleBookings ? (
            <>Group Booking</>
          ) : (
            <>
              {owner.details.firstName} {owner.details.lastName}
            </>
          )}
        </Typography>
        <Typography variant={"body1"}>{pharmacy.name}</Typography>
      </DetailsCard>
    );
  };

export default AccountDashboardAppointment;
