import React, { useMemo } from "react";
import {
  IRadioOption,
  RadioSelectField,
  Typography,
} from "@welldigital/components";
import { useAppointmentStore } from "../../../../stores/appointment/appointment.store";
import {
  checkIfAboveExemptAge,
  checkIfUnder18,
} from "../../CustomerDetailsPage.utils";
import { Box } from "@material-ui/core";
import useFormContext from "../../../../utils/useFormContext";
import {
  PaymentTypes,
  Province,
} from "../../../../stores/appointment/appointment.types";
import { fluPaymentValidationMessages } from "../../../../components/validationMessages";

export type FluPaymentFieldsProps = {
  isOwner: boolean;
  onPaymentSelected: (paymentType: PaymentTypes) => void;
};

const FluPaymentFields: React.FC<FluPaymentFieldsProps> = ({
  isOwner,
  onPaymentSelected,
}) => {
  const { form } = useFormContext();
  const birthDate = form.watch("details.birthDate");
  const province =
    useAppointmentStore().appointment?.pharmacy?.address?.province;
  const isAboveVacExemptAge = useMemo(() => checkIfAboveExemptAge(birthDate), [birthDate]);
  const paymentOptions: IRadioOption[] = useMemo(() => {
    const isNhsHidden =
      !!province &&
      [Province.Scotland, Province.NorthernIreland].includes(province);
    const isUnder18 = checkIfUnder18(birthDate);

    const options: IRadioOption[] = [
      {
        label: isOwner
          ? "I’m eligible for a free NHS flu vaccination"
          : "They’re eligible for a free NHS flu vaccination",
        description:
          "Find out who is eligible for a free flu vaccination on the NHS website.",
        id: PaymentTypes.exempt,
      },
      {
        label: isOwner ? "I have a voucher" : "They have a voucher",
        description: isOwner
          ? "You might have a Well Pharmacy voucher. Make sure you bring this voucher with you to your appointment."
          : "They might have a Well Pharmacy voucher. Make sure you bring this voucher with you to your appointment.",
        id: PaymentTypes.voucher,
      },
      {
        label: isOwner ? "I’m paying for my flu vaccination" : "Paying online",
        description: "You can pay for the flu vaccination online.",
        id: PaymentTypes.payment,
      },
    ];
    if (isNhsHidden || isUnder18) {
      options.splice(0, 1);
    }
    return options;
  }, [isOwner, province, birthDate]);

  return (
    <Box mt={2}>
      <Typography variant={"h6"} spacingAfter={3}>
        {isOwner
          ? "How are you paying for your flu vaccination?"
          : "How are you paying for their flu vaccination?"}
        {" *"}
      </Typography>
      <RadioSelectField
        name={"paymentType"}
        options={paymentOptions}
        variant={"contained"}
        validationMessages={fluPaymentValidationMessages}
        onChange={onPaymentSelected}
        required
        defaultValue={(isAboveVacExemptAge ?(province===Province.Scotland?undefined: PaymentTypes.exempt) : undefined)}
      />
    </Box>
  );
};

export default FluPaymentFields;
