import React from "react";
import { Typography } from "@material-ui/core";

const PageNotFoundPage: React.FC = () => (
  <Typography variant="h4" component="h1" gutterBottom>
    404 - Page Not Found
  </Typography>
);

export default PageNotFoundPage;
