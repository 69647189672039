import React from "react";
import {
  CustomerAppointmentDetails,
  PaymentTypes,

} from "../../stores/appointment/appointment.types";
import { Box, Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { formatPrice } from "../../utils/price.utils";
import { Service } from "../../stores/service/service.types";
import { ServiceIds } from "stores/service/service.constants";

export type CustomerAppointmentItemProps = CustomerAppointmentDetails & {
  title?: string;
  service?: Service;
  showAddress?: boolean;
};

const CustomerAppointmentItem: React.FC<CustomerAppointmentItemProps> = ({
  title,
  showAddress,
  details: {
    firstName,
    lastName,
    address1,
    address2,
    city,
    postcode,
    phone,
    email,
  },
  paymentType,
  service,
}) => {
  return (
    <>
      {title && (
        <Typography variant={"h6"} spacingAfter={2}>
          {title}
        </Typography>
      )}
      <Typography variant={"subtitle1"} spacingAfter={1}>
        {firstName} {lastName}
      </Typography>
      {showAddress && (
        <Typography component={"div"} color={"textSecondary"}>
          <Grid container spacing={3}>
            <Grid item>
              <div>
                {address1}
                {address2 ? ` ${address2}` : ""},
              </div>
              <div>{city}</div>
              <div>{postcode}</div>
            </Grid>
            <Grid item>
              <div>{phone}</div>
              <div>{email}</div>
            </Grid>
          </Grid>
        </Typography>
      )}
      {service && (
        <Box mt={2}>
          <Grid container>
            <Grid item xs>
              <Typography variant={"body1"} color={"textSecondary"}>
                {service.name}
                {paymentType?.includes(PaymentTypes.exempt)
                  ? " - NHS"
                  : paymentType?.includes(PaymentTypes.voucher)
                  ? " - Voucher"
                  : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={"body1"} color={"textSecondary"}>
                {formatPrice(
                  ![PaymentTypes.exempt, PaymentTypes.voucher].includes(
                    paymentType?.split("_")[0]! as PaymentTypes
                  )
                    ? service.fee
                    : service.id === ServiceIds.FluPPV ? service.fee - 17.99
                    : service.id === ServiceIds.FluPrevenar13 && (paymentType === "voucher_payment" as PaymentTypes || paymentType === "exempt_payment" as PaymentTypes) ? service.fee - 17.99
                    : 0
                )}

              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CustomerAppointmentItem;
