import React from "react";
import { Box, Grid } from "@material-ui/core";
import Cart from "./components/Cart";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import { Appointment } from "../../stores/appointment/appointment.types";
import { ErrorAlertProps } from "../ErrorAlert/ErrorAlert";
import Layout from "../Layout/Layout";

export type MainLayoutProps = {
  hideCart?: boolean;
  error?: ErrorAlertProps;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  hideCart,
  error,
}) => {
  const { appointment } = useAppointmentStore();

  return (
    <Layout error={error}>
      {!error && (
        <Grid container spacing={4} direction={"row"} justify={"center"}>
          <Grid item xs={12} md={7}>
            <Box mt={-1}>{children}</Box>
          </Grid>
          {!hideCart && (
            <>
              <Grid item xs={12} md={1} />
              <Grid item xs={12} md={4}>
                <Cart appointment={appointment as Appointment} />
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Layout>
  );
};

export default MainLayout;
