import { fetchPharmacyByIdAPI } from "../api";
import { useEffect, useState } from "react";
import { Pharmacy } from "../stores/appointment/appointment.types";
import { ErrorAlertProps } from "../components/ErrorAlert/ErrorAlert";

export type PharmacyState = {
  pharmacy?: Pharmacy;
  error?: ErrorAlertProps;
};

export default function usePharmacy(pharmacyId?: string): PharmacyState {
  const [pharmacy, setPharmacy] = useState<Pharmacy>();
  const [error, setError] = useState<ErrorAlertProps>();

  useEffect(() => {
    if (!pharmacyId) return;
    fetchPharmacyByIdAPI(pharmacyId)
      .then((response) => {
        setPharmacy(response);
      })
      .catch((e) => {
        setError({
          message: e.response?.data.message || e.message,
          isBlocking: true,
        });
      });
  }, [pharmacyId]);

  return {
    pharmacy,
    error,
  };
}
