import React, { useMemo } from "react";
import {
  Typography,
  Theme,
  makeStyles,
  Container,
  Link,
} from "@material-ui/core";
import Logo from "../../Logo/Logo";
import { wellColors } from "@welldigital/components";
import { UserProfile } from "@welldigital/ui-common/Authentication/components/UserProfile";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    borderBottom: `1px solid ${wellColors.greyscale[300]}`,
  },
  container: {
    display: "flex",
    alignItems: "center",
    minHeight: 116,
  },
  logoContainer: {
    flex: 1,
    "&>a": {
      display: "inline-block",
    },
  },
  logo: {
    "& path": {
      fill: wellColors.elixir[900],
    },
  },
  logoText: {
    color: wellColors.elixir[200],
    marginLeft: theme.spacing(2),
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const isUserProfileVisible = !!process.env.REACT_APP_SHOW_USER_PROFILE;
  const userProfileProps = useMemo(
    () => ({
      isAuthenticationRequired: false,
      shouldTriggerAuthentication: true,
      shouldShowLoginWhenNotIsAuthenticationRequired: true,
    }),
    []
  );

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div className={classes.logoContainer}>
          <Link
            data-testid="header/link-to-home"
            underline="none"
            href="https://www.well.co.uk/services"
          >
            <Logo className={classes.logo} />
            <Typography className={classes.logoText}>
              Pharmacy Services
            </Typography>
          </Link>
        </div>
        {isUserProfileVisible && <UserProfile {...userProfileProps} />}
      </Container>
    </div>
  );
};

export default Header;
