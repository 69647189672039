import React from "react";
import {
  CustomerDetails,
  SurgeryDetails,
} from "../../../../stores/appointment/appointment.types";
import { Box } from "@material-ui/core";
import { Person, Phone, Room } from "@material-ui/icons";
import { wellColors } from "@welldigital/components";
import AccountDashboardDetails from "./Detail/AccountDashboardDetails";
import { format } from "date-fns";

export type AccountDashboardSidebarProps = {
  details: CustomerDetails;
  surgeryDetails: SurgeryDetails;
};

const AccountDashboardSidebar: React.FC<AccountDashboardSidebarProps> = ({
  details,
  surgeryDetails,
}) => {
  return (
    <Box
      border={`1px solid ${wellColors.greyscale[500]}`}
      borderRadius={10}
      p={2}
    >
      <Box>
        <AccountDashboardDetails
          icon={Person}
          label={"Personal details"}
          items={[
            {
              label: `${details.firstName} ${details.lastName}`,
              body: format(details.birthDate, "dd/MM/yyyy"),
            },
          ]}
        />
      </Box>

      <AccountDashboardDetails
        icon={Phone}
        label={"Contact details"}
        items={[
          {
            label: "Email",
            body: details.email,
          },
          {
            label: "Phone number",
            body: details.phone,
          },
          {
            label: "Registered address",
            body: `${details.address1}${
              details.address2 ? ` ${details.address2}` : ""
            },${details.city}, ${details.postcode}`,
          },
        ]}
      />

      <AccountDashboardDetails
        icon={Room}
        label={"GP details"}
        items={[
          {
            label: "GP Surgery",
            body: surgeryDetails.name,
          },
        ]}
        spacingAfter={0}
      />
    </Box>
  );
};

export default AccountDashboardSidebar;
