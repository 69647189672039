import { fetchPharmacyByIdAPI } from "../../api";
import { Pharmacy } from "../../stores/appointment/appointment.types";

export async function getPharmacyById(pharmacyId: string): Promise<Pharmacy> {
  const pharmacy = await fetchPharmacyByIdAPI(pharmacyId);
  if (!pharmacy) {
    throw new Error(`The location with id "${pharmacyId}" does not exist`);
  }
  return pharmacy;
}
