import React from "react";
import { Box } from "@material-ui/core";
import { Typography } from "@welldigital/components";

export const NoLocationsMessage: React.FC = () => (
  <Box mt={3} data-testid="LocationsPage/no-results">
    <Typography variant={"h5"}>
      We can’t find any pharmacies in your search area{" "}
    </Typography>
    <Typography paragraph spacingAfter={1}>
      Search again by town or postcode, or try using your current location.
    </Typography>
  </Box>
);
