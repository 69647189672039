import React, { PropsWithChildren } from "react";
import { Route, Switch } from "react-router-dom";

export type RouteRendererProps<Paths> = {
  routes: RouteProps<Paths>[];
};

export type RouteProps<Paths> = {
  path: Paths;
  component: React.FC<any>;
};

export function RouteRenderer<Paths = string>(
  props: PropsWithChildren<RouteRendererProps<Paths>>
) {
  return (
    <Switch>
      {props.routes.map(({ component: Component, path }) => (
        <Route
          key={(path as unknown) as string}
          path={(path as unknown) as string}
          exact
          render={(props) => <Component {...props} />}
        />
      ))}
    </Switch>
  );
}

export default RouteRenderer;
