import React from "react";
import { Typography, wellColors } from "@welldigital/components";
import { Box } from "@material-ui/core";

const MultipleCustomersAlert: React.FC = () => (
  <Box mt={4} border={`1px solid ${wellColors.zen[50]}`} p={3} borderRadius={4}>
    <Typography variant={"h6"} spacingAfter={1}>
      Booking for more than one person?
    </Typography>
    <Typography variant={"body1"} color={"textSecondary"}>
      You can book an appointment for yourself and up to three other people. If
      you’re booking for more than one person you will all be seen at the same
      time. You can only book for someone under the age of 18 if you are their
      legal guardian.
    </Typography>
  </Box>
);

export default MultipleCustomersAlert;
