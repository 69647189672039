import React, { useMemo } from "react";
import {
  ConditionalFields, EmailSuggestionField,
  InputField,
  IRadioOption,
  RadioSelectField,
  Typography,
  WatchFields
} from "@welldigital/components";
import { GuardianTypes } from "../../../../stores/appointment/appointment.types";
import { checkIfUnder18 } from "../../CustomerDetailsPage.utils";
import { Box } from "@material-ui/core";

export type LegalGuardianFieldsProps = {
  isOwner: boolean;
  onTypeSelect: (guardianType: GuardianTypes) => void;
};

const LegalGuardianFields: React.FC<LegalGuardianFieldsProps> = ({
  isOwner,
  onTypeSelect,
}) => {
  const guardianTypeOptions: IRadioOption[] = useMemo(
    () => [
      { label: "I'm their legal guardian", id: GuardianTypes.owner },
      { label: "I'm not their legal guardian", id: GuardianTypes.other },
    ],
    []
  );

  return (
    <Box mb={4}>
      <WatchFields fieldNames={["details.birthDate"]}>
        {({ "details.birthDate": birthDate }: any) =>
          checkIfUnder18(birthDate) &&
          !isOwner && (
            <>
              <Typography
                variant={"body1"}
                color={"textSecondary"}
                spacingAfter={2}
              >
                Because this person is under the age of 18, they must be
                accompanied by their legal guardian when they attend the
                appointment.
              </Typography>

              <RadioSelectField
                name={"guardianType"}
                options={guardianTypeOptions}
                variant={"contained"}
                fullWidth={false}
                onChange={onTypeSelect}
                required
              />

              <ConditionalFields
                fieldName={"guardianType"}
                fieldValue={GuardianTypes.other}
              >
                <Box mt={2}>
                  <Typography variant={"h6"}>
                    Please enter the guardian’s full name
                  </Typography>
                  <Typography
                    variant={"body1"}
                    color={"textSecondary"}
                    spacingAfter={2}
                  >
                    Because this person is under the age of 18, they must be
                    accompanied by their legal guardian when they attend the
                    appointment.
                  </Typography>
                  <InputField
                    name={"guardianDetails.firstName"}
                    label={"First name"}
                    required
                  />
                  <InputField
                    name={"guardianDetails.lastName"}
                    label={"Last name"}
                    required
                  />
                </Box>

                <Box mt={2}>
                  <Typography variant={"h6"}>
                    Guardian contact information
                  </Typography>
                  <Typography
                    variant={"body1"}
                    color={"textSecondary"}
                    spacingAfter={2}
                  >
                    Please provide the guardian’s email address and phone
                    number.
                  </Typography>
                  <InputField
                    name={"guardianDetails.email"}
                    label={"Email address"}
                    required
                    email
                    afterComponent={
                      <EmailSuggestionField
                        fieldName={"guardianDetails.email"}
                      />
                    }
                  />
                  <InputField
                    name={"guardianDetails.phone"}
                    label={"Phone number"}
                    required
                  />
                </Box>
              </ConditionalFields>
            </>
          )
        }
      </WatchFields>
    </Box>
  );
};
export default LegalGuardianFields;
