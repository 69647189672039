import React, { useCallback, useMemo } from "react";
import Layout from "../../components/Layout/Layout";
import {
  Typography,
  IRadioOption,
  RadioSelectField,
  Form,
} from "@welldigital/components";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { generatePath, useHistory } from "react-router-dom";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import { Paths } from "../paths";
import useAppointmentManage from "./hooks/useAppointmentManage";

export enum ManageAppointmentTypes {
  cancel = "cancel",
  reschedule = "reschedule",
}

const AccountAppointmentManagePage: React.FC = () => {
  const history = useHistory();
  const { appointmentId, appointment, error } = useAppointmentManage();

  const options: IRadioOption[] = useMemo(
    () => [
      {
        label: "Cancel",
        id: ManageAppointmentTypes.cancel,
        description: "Cancel your appointment (Terms & Conditions apply)",
      },
      {
        label: "Reschedule",
        id: ManageAppointmentTypes.reschedule,
        description:
          "If you can no longer attend your original appointment you can reschedule to a date and time that suits you.",
      },
    ],
    []
  );

  const onSubmit = useCallback(
    ({ type }) => {
      if (!appointment) return;
      if (type === ManageAppointmentTypes.reschedule) {
        history.push(
          generatePath(Paths.AccountAppointmentReschedule, {
            appointmentId,
          })
        );
      } else {
        history.push(
          generatePath(Paths.AccountAppointmentCancel, {
            appointmentId,
          })
        );
      }
    },
    [appointment, appointmentId, history]
  );

  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Manage your appointment
      </Typography>
      <Typography variant={"body2"} spacingAfter={3}>
        What would you like to do?
      </Typography>
      <Form onSubmit={onSubmit}>
        <RadioSelectField
          name={"type"}
          variant={"contained"}
          options={options}
          required
          fullWidth
          spacingAfter={4}
        />
        <AccountInfoMessage pharmacy={appointment?.pharmacy} />
        <LayoutNavigation
          nextButton={{
            type: "submit",
            children: "Next",
          }}
          backButton={{ onClick: () => history.push(Paths.AccountDashboard) }}
        />
      </Form>
    </Layout>
  );
};

export default AccountAppointmentManagePage;
