import React, { useState } from "react";
import {
  InputField,
  Typography,
} from "@welldigital/components";
import { Box, makeStyles } from "@material-ui/core";
import {
  NHSValidationMessages,
} from "../../../../components/validationMessages";
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Modal, 
         ModalTitle, 
         ModalActions, 
         Button 
} from "@welldigital/components";

export type CustomerNHSProps = {
  isOwner: boolean;
  isNHSValid: boolean;
};

const CustomerNHSField: React.FC<CustomerNHSProps> = ({ isOwner, isNHSValid }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openNHSModal = () => {
    setIsModalOpen(true);
  };

  const closeNHSModal = () => {
    setIsModalOpen(false);
  };

  const useStyles = makeStyles(() => ({
    paper: {
      width: 598,
    },
  }));
  const classes = useStyles();
  return (
    <Box mt={1}>
      <Typography variant={"h6"}>
        {isOwner ? "Do you know your NHS number? If yes, please enter it below." : "Do you know their NHS number? If yes, please enter it below."}
      </Typography>
      <InputField
        name={"details.nhsNumber"}
        label={"NHS number"}
        onKeyPress={(event:any) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        autocomplete={"off"}
        validationMessages={ NHSValidationMessages }
      />
      {isNHSValid &&
        <Typography 
        style={{ "color": "#f44336", "fontSize": "0.75rem" }}>Enter the valid NHS number</Typography>
      }
      <Typography
        onClick={openNHSModal}
        style={{ "color":"#0066BE", "fontSize":"16px" }}
      >
        <LiveHelpIcon style={{ "position":"relative", "top": "7px" }}/>
        {"Why do you need my NHS number?"}
        <ChevronRightIcon style={{ "position":"relative", "top": "7px", "float": "right" }}/>
      </Typography>
      <Modal
        open={isModalOpen}
        onClose={closeNHSModal}
        PaperProps={{ className: classes.paper }}
      >
        <LiveHelpIcon style={{ "color":"#0066BE","position":"relative", "top": "-7px" }}/>
        <ModalTitle
          label={"Why do you need my NHS number?"}
          variant={"subtitle"}
        />
        <Typography>
          {"Entering your NHS number is optional but it can make it easier to identify you and check your details are correct. "}
        </Typography>
        <ModalActions>
          <Button
            variant={"outlined"}
            color={"primary"}
            children={"Close"}
            size={"large"}
            onClick={closeNHSModal}
          />
        </ModalActions>
      </Modal>  
    </Box>
  );
};

export default CustomerNHSField;
