import create from "zustand";
import { configurePersist } from "zustand-persist";
import {
  Appointment,
  CustomerDetails,
  SurgeryDetails,
} from "../appointment/appointment.types";
import {
  getAccountDetailsAPI,
  getAppointmentDetailsAPI,
  getAppointmentsAPI,
  getTokenAPI,
  cancelAppointmentAPI,
} from "../../api";
import { CancelData, RescheduleData } from "./account.types";
import { Slot } from "../../pages/BookAppointmentPage/BookAppointmentPage.types";
import { sleep } from "@welldigital/components";
import { ErrorAlertProps } from "../../components/ErrorAlert/ErrorAlert";
import { Paths } from "../../pages/paths";

export interface AccountStoreState {
  details?: CustomerDetails;
  surgeryDetails?: SurgeryDetails;
  fetchAccountDetails: () => Promise<void>;
  fetchAppointments: () => Promise<void>;
  fetchAppointmentDetails: (appointmentId: string) => Promise<void>;
  appointments?: Appointment[];
  appointmentMap: { [key: string]: Appointment };
  error?: ErrorAlertProps;
  rescheduleMap: { [key: string]: RescheduleData };
  setRescheduleSlot: (id: string, slot: Slot) => void;
  reschedule: (appointmentId: string) => Promise<void>;
  clearReschedule: (appointmentId: string) => void;
  isRescheduling: boolean;
  cancelMap: { [key: string]: CancelData };
  setCancelDetails: (id: string, cancelData: CancelData) => void;
  cancel: (token: string) => Promise<void>;
  clearCancel: (token: string) => void;
  isCancelling: boolean;
  checkIfTokenIsValid: (token: string) => Promise<void>;
}

const { persist } = configurePersist({ storage: localStorage });

export const useAccountStore = create<AccountStoreState>(
  persist(
    {
      key: "account",
      allowlist: ["rescheduleMap", "cancelMap"],
    },
    (set, get) => ({
      appointmentMap: {},
      rescheduleMap: {},
      isRescheduling: false,
      cancelMap: {},
      isCancelling: false,
      hasAppointmentId: {
        appointmentId: "",
        startDate: "",
        locationId: "",
        pharmacy: { id: "", name: "" },
      },
      async fetchAccountDetails() {
        if (get().details) return;
        try {
          const { details, surgeryDetails } = await getAccountDetailsAPI();
          set({ details, surgeryDetails });
        } catch (e) {
          set({
            error: {
              message: e.response?.data.message || e.message,
              isBlocking: true,
            },
          });
        }
      },

      async fetchAppointments() {
        try {
          const appointments = await getAppointmentsAPI();
          set({ appointments });
        } catch (e) {
          set({
            error: {
              message: e.response?.data.message || e.message,
              isBlocking: true,
            },
          });
        }
      },

      async fetchAppointmentDetails(appointmentId) {
        if (get().appointmentMap[appointmentId]) return;
        try {
          const appointment = await getAppointmentDetailsAPI();
          set({
            appointmentMap: {
              ...get().appointmentMap,
              [appointmentId]: appointment,
            },
          });
        } catch (e) {
          // The copy to be implemented when the API is done, for 404:
          // We couldn’t find an appointment to reschedule
          set({
            error: {
              message: e.response?.data.message || e.message,
              action: {
                children: "Go back to your account",
                to: Paths.AccountDashboard,
              },
              isBlocking: true,
            },
          });
        }
      },

      setRescheduleSlot(appointmentId, bookedSlot) {
        set({
          rescheduleMap: {
            ...get().rescheduleMap,
            [appointmentId]: { bookedSlot },
          },
        });
      },

      async reschedule(appointmentId) {
        set({ isRescheduling: true });
        await sleep(500);
        const rescheduleData = get().rescheduleMap![appointmentId];
        set({
          isRescheduling: false,
          rescheduleMap: {
            ...get().rescheduleMap,
            [appointmentId]: {
              ...rescheduleData,
              isCompleted: true,
            },
          },
        });
      },

      async clearReschedule(appointmentId) {
        const rescheduleMap = { ...get().rescheduleMap };
        delete rescheduleMap[appointmentId];
        set({ rescheduleMap });
      },

      async checkIfTokenIsValid(token: string) {
        try {
          await getTokenAPI(token);
        } catch (e) {
          set({
            error: {
              message: e.response?.data.message || e.message,
              isBlocking: true,
            },
          });
        }
      },

      setCancelDetails(appointmentId, cancelData) {
        set({
          cancelMap: {
            ...get().cancelMap,
            [appointmentId]: cancelData,
          },
        });
      },

      async cancel(token) {
        set({ isCancelling: true });
        const cancelData = get().cancelMap[token];
        try {
          await cancelAppointmentAPI(cancelData);
          set({ isCancelling: false });
        } catch (e) {
          set({
            error: { message: e.response?.data.message || e.message },
            isCancelling: false,
          });
          throw e;
        }
      },

      async clearCancel(token) {
        const cancelMap = { ...get().cancelMap };
        delete cancelMap[token];
        set({ cancelMap });
      },
    })
  )
);
