import React, { FC } from "react";
import MainLayout from "../../../MainLayout/MainLayout";
import ErrorAlert, { ErrorAlertProps } from "../../../ErrorAlert/ErrorAlert";

const ErrorPageLayout: FC<ErrorAlertProps> = (props) => {
  return (
    <MainLayout>
      <ErrorAlert {...props} />
    </MainLayout>
  );
};

export default ErrorPageLayout;
