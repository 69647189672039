import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAccountStore } from "../../../stores/account/account.store";
import { Appointment } from "../../../stores/appointment/appointment.types";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";

type Params = {
  appointmentId: string;
};

export type AppointmentManageState = {
  error?: ErrorAlertProps;
  appointmentId: string;
  appointment: Appointment;
};

export default function useAppointmentManage(): AppointmentManageState {
  const { appointmentId } = useParams<Params>();
  const [
    fetchAppointmentDetails,
    appointment,
    error,
  ] = useAccountStore((state) => [
    state.fetchAppointmentDetails,
    state.appointmentMap[appointmentId],
    state.error,
  ]);

  useEffect(() => {
    fetchAppointmentDetails(appointmentId);
  }, [fetchAppointmentDetails, appointmentId]);

  return {
    error,
    appointment,
    appointmentId,
  };
}
