import {
  Address,
  BusinessDay,
  BusinessHours,
  Pharmacy,
  Province,
  Travel,
} from "../appointment.types";
import { parse } from "date-fns";
import { Slot } from "../../../pages/BookAppointmentPage/BookAppointmentPage.types";
import { Service } from "../../service/service.types";

export const mockService: Service = {
  id: 49814,
  name: "Flu vaccination",
  analyticsName: "Flu",
  fee: 15,
  shouldSkipGpDetails: true,
  disablePrice: true,
};

export const mockAddress: Address = {
  addressLine1: "The address of the pharmacy",
  addressLine2: "",
  city: "London",
  postalCode: "ASD 123",
  province: Province.England,
};

export const mockBusinessDay: BusinessDay = {
  isOpen: true,
  startTime: 900,
  endTime: 1700,
};

export const mockBusinessHours: BusinessHours = {
  mon: mockBusinessDay,
  tue: mockBusinessDay,
  wed: mockBusinessDay,
  thu: mockBusinessDay,
  fri: mockBusinessDay,
  sat: mockBusinessDay,
  sun: mockBusinessDay,
};

export const mockTravel: Travel = {
  distance: "500m",
  startLat: "2.1",
  startLon: "1.2",
};

export const mockPharmacy: Pharmacy = {
  id: "e1cf5b12-d4eb-4e14-9331-21693fd445a0",
  name: "The test pharmacy",
  address: mockAddress,
  businessHours: mockBusinessHours,
  travel: mockTravel,
  phone: "+123123123123",
};

export const mockBookingSlot: Slot = {
  startTime: parse("2020-10-25", "yyyy-MM-dd", new Date()),
  endTime: parse("2020-10-25", "yyyy-MM-dd", new Date()),
  resourceId: "123,",
};

export const mockReason = "REASON";
export const mockToken = "12";
