import React, { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { Typography } from "@welldigital/components";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import { useRescheduleConfirmation } from "./hooks/useRescheduleConfirmation";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import { useHistory } from "react-router-dom";
import { Paths } from "../paths";

const AccountAppointmentRescheduleConfirmationPage: React.FC = () => {
  const history = useHistory();
  const {
    appointment,
    appointmentId,
    clearReschedule,
    error,
  } = useRescheduleConfirmation();

  const onSubmit = useCallback(() => {
    clearReschedule(appointmentId);
    history.push(Paths.AccountDashboard);
  }, [history, clearReschedule, appointmentId]);

  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Your appointment has been rescheduled
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={4}>
        We’ve sent you an email to confirm your new appointment.
      </Typography>

      <AccountInfoMessage pharmacy={appointment?.pharmacy} />

      <LayoutNavigation
        nextButton={{
          onClick: onSubmit,
          children: "Finish",
          endIcon: undefined,
        }}
      />
    </Layout>
  );
};

export default AccountAppointmentRescheduleConfirmationPage;
