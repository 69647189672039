import React, { FC, useCallback } from "react";
import { Box, InputAdornment, IconButton } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import {
  Button,
  Form,
  InputField,
  Spacing,
  SpacingProps,
} from "@welldigital/components";
import LayoutNavigation from "../../../../components/LayoutNavigation/LayoutNavigation";
import { locationSearchValidationMessages } from "../../../../components/validationMessages";

export type SearchControlsProps = {
  searchTerm: string;
  currentLocation: Position | null;
  onSearch(searchTerm: string): void;
  onSearchByCurrentLocation(): void;
  spacingAfter?: SpacingProps["spacing"];
  isSearching: boolean;
  isLocationSearching: boolean;
};

export const SearchControls: FC<SearchControlsProps> = ({
  searchTerm,
  currentLocation,
  onSearchByCurrentLocation,
  onSearch,
  spacingAfter,
  isSearching,
  isLocationSearching,
}) => {
  const onSubmit = useCallback(({ searchTerm }) => onSearch(searchTerm), [
    onSearch,
  ]);

  return (
    <Form onSubmit={onSubmit} initialValues={{ searchTerm }}>
      <InputField
        name="searchTerm"
        required
        inputProps={{
          "data-testid": "locationSelector/searchLocation-textField",
        }}
        fullWidth
        InputProps={{
          placeholder: "Enter town or postcode",
          startAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
          autoFocus: true,
        }}
        validationMessages={locationSearchValidationMessages}
      />
      <Box>
        <Button
          color={currentLocation ? "primary" : "default"}
          variant={currentLocation ? "outlined" : "contained"}
          onClick={onSearchByCurrentLocation}
          children={`${
            currentLocation ? "Using" : "Use"
          } your current location`}
          fullWidth
          loading={isLocationSearching}
          disabled={isSearching}
        />
      </Box>
      <LayoutNavigation
        backButton={{
          onClick: () => (window.location.href = "https://well.co.uk"),
        }}
        nextButton={{
          type: "submit",
          children: "Search",
          loading: isSearching,
          disabled: isLocationSearching,
        }}
        spacingBefore={2}
      />
      <Spacing spacing={spacingAfter} />
    </Form>
  );
};

SearchControls.defaultProps = {
  spacingAfter: 2,
};
