import React, { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { generatePath, useHistory } from "react-router-dom";
import { Paths } from "../paths";
import { Button, Form, Typography } from "@welldigital/components";
import { ChevronRight } from "@material-ui/icons";
import useCancel from "./hooks/useCancel";
import AccountAppointmentCancelReasons from "./components/AppointmentCancellationReasons";
import { CancelData } from "../../stores/account/account.types";

const AppointmentCancelPage: React.FC = () => {
  const {
    error,
    setCancelDetails,
    token,
    pharmacy,
    appointmentId,
    locationId,
    appointmentStartDate,
  } = useCancel();
  const history = useHistory();

  const onSubmit = useCallback(
    ({ reason }: CancelData) => {
      if (!pharmacy) return;
      setCancelDetails(token, {
        locationId,
        reason,
        token,
        appointmentId,
        pharmacy,
        startDate: new Date(appointmentStartDate),
      });
      history.push(
        generatePath(Paths.AppointmentTokenCancelConfirm, {
          token,
        })
      );
    },
    [
      pharmacy,
      appointmentStartDate,
      setCancelDetails,
      appointmentId,
      locationId,
      token,
      history,
    ]
  );
  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Cancel your appointment
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        We’re sorry to hear that you wish to cancel your appointment.
      </Typography>
      <Form onSubmit={onSubmit}>
        <AccountAppointmentCancelReasons pharmacy={pharmacy} />
        <Button
          data-testid={"next"}
          color={"primary"}
          variant={"contained"}
          fullWidth
          endIcon={<ChevronRight />}
          children={"Next"}
          size={"large"}
          type={"submit"}
          disabled={!pharmacy}
        />
      </Form>
    </Layout>
  );
};

export default AppointmentCancelPage;
