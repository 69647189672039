import React from "react";
import { Link, LinkProps, makeStyles, Theme } from "@material-ui/core";
import { Typography, wellColors } from "@welldigital/components";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "block",
    marginBottom: theme.spacing(2),
    color: wellColors.elixir[900],
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const FooterLink: React.FC<LinkProps> = (props) => {
  const classes = useStyles();
  return (
    <Typography
      {...props}
      className={classes.root}
      component={Link}
      variant={"button"}
    />
  );
};

export default FooterLink;
