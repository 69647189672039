import React, { FC } from "react";
import { Typography, TypographyProps } from "@material-ui/core";
import capitalize from "lodash/capitalize";

import {
  BusinessDay,
  BusinessHours,
} from "../../../../stores/appointment/appointment.types";

interface OpeningTime {
  from: string;
  to?: string;
  startTime: number;
  endTime: number;
}

export function numberTimeToString(numberTime: number) {
  if (numberTime === 0) return "00:00";
  const time = `${numberTime}`;
  return `${time.substring(0, time.length - 2)}:${time.substring(
    time.length - 2
  )}`;
}

export function groupByOpeningHours(businessHours: BusinessHours) {
  const entries: [string, BusinessDay][] = Object.entries(businessHours);
  const openingTimes: OpeningTime[] = [];

  function addNew(day: string, businessDay: BusinessDay) {
    openingTimes.push({
      from: day,
      startTime: businessDay.startTime!,
      endTime: businessDay.endTime!,
    });
  }

  for (let i = 0; i < entries.length; i++) {
    const day = entries[i][0];
    const hours = entries[i][1];

    if (openingTimes.length === 0) {
      if (hours.isOpen) {
        addNew(day, hours);
      }
    } else {
      const previous = entries[i - 1];

      if (!previous[1].isOpen) {
        if (hours.isOpen) {
          addNew(day, hours);
        }
      } else {
        if (hours.isOpen) {
          const lastEntry = openingTimes[openingTimes.length - 1];

          if (
            lastEntry.startTime === hours.startTime &&
            lastEntry.endTime === hours.endTime
          ) {
            lastEntry.to = day;
          } else {
            addNew(day, hours);
          }
        }
      }
    }
  }

  return openingTimes
    .map(
      (value) =>
        `${capitalize(value.from)}${
          value.to ? ` to ${capitalize(value.to)}` : ""
        } ${numberTimeToString(value.startTime)}-${numberTimeToString(
          value.endTime
        )}`
    )
    .join(", ");
}

interface OpeningTimesProps extends TypographyProps {
  businessHours: BusinessHours;
}

const OpeningTimes: FC<OpeningTimesProps> = ({ businessHours, ...rest }) => {
  const openingTimes = groupByOpeningHours(businessHours);

  return <Typography {...rest}>{openingTimes}</Typography>;
};

export default OpeningTimes;
