import React, { useCallback } from "react";
import {
  Button,
  InputField,
  Typography,
  WatchFields,
  AutoCompleteField,
  AutoCompleteOption,
} from "@welldigital/components";
import { getAddressByPostcodeAPI } from "../../../../api";
import { Box } from "@material-ui/core";
import { useAppointmentStore } from "../../../../stores/appointment/appointment.store";
import { Appointment } from "../../../../stores/appointment/appointment.types";
import { useParams } from "react-router-dom";
import { CustomerDetailsPageParams } from "../../CustomerDetailsPage";
import useFormContext from "../../../../utils/useFormContext";
import {
  address1ValidationMessages,
  cityValidationMessages,
  postcodeValidationMessages,
} from "../../../../components/validationMessages";
import { PostcodeLookupAddress } from "../../../../api/api.types";

export type AddressFieldsProps = {
  isOwner: boolean;
};

const POSTCODE_REGEX =
  /(^([Gg][Ii][Rr] ?0[Aa]{2})$)|(^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$)/;

const AddressFields: React.FC<AddressFieldsProps> = ({ isOwner }) => {
  const { form } = useFormContext();
  const { customerId } = useParams<CustomerDetailsPageParams>();
  const { appointment } = useAppointmentStore();
  const { owner } = appointment as Appointment;

  const loadData = useCallback(async (searchText) => {
    if (!POSTCODE_REGEX.test(searchText)) return;
    const addresses = await getAddressByPostcodeAPI(searchText);
    return addresses.map((address) => {
      const { line1, line2, city } = address;
      return {
        label: `${line1}${line2 ? `, ${line2}` : ""}, ${city}`,
        value: address,
      };
    }) as AutoCompleteOption[];
  }, []);

  const handleItemSelect = useCallback(
    (data: Partial<PostcodeLookupAddress>) => {
      const { postcode, line1, line2, city } = data;
      form.setValue("details.postcode", postcode);
      form.setValue("details.address1", line1);
      form.setValue("details.address2", line2);
      form.setValue("details.city", city);
    },
    [form]
  );

  const replaceAsOwner = useCallback(() => {
    const { postcode, address1, address2, city } = owner.details;
    handleItemSelect({ postcode, line1: address1, line2: address2, city });
  }, [handleItemSelect, owner.details]);

  return (
    <Box mt={2}>
      <Typography variant={"h6"}>
        {isOwner ? "Your address" : "Their address"}
      </Typography>
      <WatchFields fieldNames={["details.postcode"]}>
        {({ "details.postcode": postcode }: { "details.postcode": string }) =>
          customerId !== "owner" &&
          owner.details &&
          owner.details?.postcode !== postcode ? (
            <Box mb={2}>
              <Button onClick={replaceAsOwner} size={"small"} variant={"text"}>
                Use same address as {owner.details.firstName}{" "}
                {owner.details.lastName} ({owner.details.postcode})
              </Button>
            </Box>
          ) : null
        }
      </WatchFields>

      <AutoCompleteField
        name={"details.postcode"}
        label={"Postcode"}
        onItemSelect={handleItemSelect}
        afterSelectChange={({ value }: AutoCompleteOption) => value.postcode}
        loadData={loadData}
        popper={{placement:null}}
        required
        validationMessages={postcodeValidationMessages}
      />
      <InputField
        name={"details.address1"}
        label={"Address line 1"}
        required
        validationMessages={address1ValidationMessages}
      />
      <InputField name={"details.address2"} label={"Address line 2"} />
      <InputField
        name={"details.city"}
        label={"City"}
        required
        validationMessages={cityValidationMessages}
      />
    </Box>
  );
};

export default AddressFields;
