import { useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import {
  AccountStoreState,
  useAccountStore,
} from "../../../stores/account/account.store";
import { Appointment } from "../../../stores/appointment/appointment.types";
import { RescheduleData } from "../../../stores/account/account.types";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { Paths } from "../../paths";

type Params = {
  appointmentId: string;
};

export type RescheduleConfirmState = {
  error?: ErrorAlertProps;
  appointment: Appointment;
  rescheduleData?: RescheduleData;
  reschedule: AccountStoreState["reschedule"];
  isRescheduling: AccountStoreState["isRescheduling"];
  appointmentId: string;
};

export default function useRescheduleConfirm(): RescheduleConfirmState {
  const history = useHistory();
  const { appointmentId } = useParams<Params>();
  const [
    fetchAppointmentDetails,
    appointment,
    rescheduleData,
    reschedule,
    isRescheduling,
    error,
  ] = useAccountStore((state) => [
    state.fetchAppointmentDetails,
    state.appointmentMap[appointmentId],
    state.rescheduleMap[appointmentId],
    state.reschedule,
    state.isRescheduling,
    state.error,
  ]);

  useEffect(() => {
    fetchAppointmentDetails(appointmentId);
  }, [fetchAppointmentDetails, appointmentId]);

  useEffect(() => {
    if (rescheduleData?.isCompleted) {
      history.push(
        generatePath(Paths.AccountAppointmentRescheduleConfirmation, {
          appointmentId,
        })
      );
    }
  }, [appointmentId, history, rescheduleData]);

  const invalidError: ErrorAlertProps = {
    message: "The reschedule data is not set",
    action: {
      children: "Set appointment",
      to: generatePath(Paths.AccountAppointmentReschedule, { appointmentId }),
    },
  };

  return {
    error: !rescheduleData ? invalidError : error,
    appointment,
    rescheduleData,
    reschedule,
    isRescheduling,
    appointmentId,
  };
}
