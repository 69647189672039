import { useEffect, useMemo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import {
  AccountStoreState,
  useAccountStore,
} from "../../../stores/account/account.store";
import {
  Appointment,
  CustomerAppointmentDetails,
} from "../../../stores/appointment/appointment.types";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import {
  getSlotMaximumDate,
  getSlotMinimumDate,
} from "../../BookAppointmentPage/BookAppointmentPage.utils";
import { mockCustomerDetails } from "../../../stores/account/mockData/account.mockData";
import { subHours } from "date-fns";
import { Paths } from "../../paths";
import {
  FindAvailabilityState,
  useFindAvailability,
} from "../../BookAppointmentPage/hooks/useFindAvailability";
import { getNow } from "utils/utils";
import { ServiceIds } from "../../../stores/service/service.constants";

type Params = {
  appointmentId: string;
};

export type RescheduleState = {
  error?: ErrorAlertProps;
  appointment: Appointment;
  appointmentId: string;
  calendarData: FindAvailabilityState["calendarData"];
  slots: FindAvailabilityState["slots"];
  fetchSlots: FindAvailabilityState["fetchSlots"];
  fetchMonthCalendarData: FindAvailabilityState["fetchMonthCalendarData"];
  setRescheduleSlot: AccountStoreState["setRescheduleSlot"];
  minDate: Date;
  maxDate?: Date;
};

export default function useReschedule(): RescheduleState {
  const history = useHistory();
  const { appointmentId } = useParams<Params>();
  const [
    fetchAppointmentDetails,
    appointment,
    setRescheduleSlot,
    appointmentError,
  ] = useAccountStore((state) => [
    state.fetchAppointmentDetails,
    state.appointmentMap[appointmentId],
    state.setRescheduleSlot,
    state.error,
  ]);
  const service = appointment?.service;
  const locationId = appointment?.pharmacy.id;
  const isFlu = useMemo(() => service?.id === ServiceIds.Flu, [service]);
  const minDate = useMemo(
    () => getSlotMinimumDate({ isFlu, locationId }),
    [isFlu, locationId]
  );
  const maxDate = useMemo(
    () => getSlotMaximumDate({ isFlu, locationId }),
    [isFlu, locationId]
  );
  const customersBooked = useMemo(
    // TODO: implement
    () => [
      {
        details: mockCustomerDetails,
        screeningId: "123",
      } as CustomerAppointmentDetails,
    ],
    []
  );

  const {
    calendarData,
    slots,
    fetchSlots,
    fetchMonthCalendarData,
    error: availabilityError,
  } = useFindAvailability({
    minDate,
    maxDate,
    appointment,
    customersBooked,
  });

  useEffect(() => {
    fetchAppointmentDetails(appointmentId);
  }, [fetchAppointmentDetails, appointmentId]);

  useEffect(() => {
    if (!appointment) return;
    const today = getNow();
    const isUnder24Hours =
      today > subHours(appointment.bookedSlot.startTime, 24);
    if (!isUnder24Hours) {
      // TODO remove the ! after implementation
      history.push(
        generatePath(Paths.AccountAppointmentRescheduleNotAllowed, {
          appointmentId,
        })
      );
    }
  }, [fetchAppointmentDetails, appointmentId, appointment, history]);

  return {
    error: availabilityError || appointmentError,
    appointment,
    appointmentId,
    calendarData,
    slots,
    fetchSlots,
    fetchMonthCalendarData,
    setRescheduleSlot,
    minDate,
    maxDate,
  };
}
