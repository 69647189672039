import React from "react";
import { Typography } from "@welldigital/components";
import LayoutNavigation from "../../../../../components/LayoutNavigation/LayoutNavigation";
import { Pharmacy } from "../../../../../stores/appointment/appointment.types";

export type IneligibleOwnerProps = {
  pharmacy: Pharmacy;
  onContinue: () => void;
};

const IneligibleOwner: React.FC<IneligibleOwnerProps> = ({
  pharmacy,
  onContinue,
}) => {
  return (
    <>
      <Typography variant={"h4"} spacingAfter={3}>
        We’re sorry, we’re unable to complete your booking
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        The information you provided suggests this service might not be suitable
        for you. If you would like more information about this vaccination,
        please speak to the pharmacy team:
      </Typography>
      <Typography variant={"body1"} spacingAfter={3}>
        {pharmacy.name}
        {pharmacy.phone ? ` - ${pharmacy.phone}` : ""}
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        You can go back if you need to make any changes to your details.
      </Typography>
      <LayoutNavigation
        nextButton={{
          children: "Explore other services",
          onClick: onContinue,
        }}
      />
    </>
  );
};

export default IneligibleOwner;
