import React, { useCallback } from "react";
import Layout from "../../components/Layout/Layout";
import { Button, Spacing, Typography } from "@welldigital/components";
import AccountInfoMessage from "../../components/AccountInfoMessage/AccountInfoMessage";
import useCancelConfirmation from "./hooks/useCancelConfirmation";
import { Link } from "@material-ui/core";

const AppointmentCancelConfirmationPage: React.FC = () => {
  const { error, cancelData, clearCancel } = useCancelConfirmation();

  const onSubmit = useCallback(() => {
    if (!cancelData) return;
    clearCancel(cancelData.token);
    window.location.href = "https://www.well.co.uk";
  }, [clearCancel, cancelData]);

  return (
    <Layout error={error} maxWidth={"sm"}>
      <Typography variant={"h4"} spacingAfter={4}>
        Your appointment has been cancelled
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={4}>
        We’ve cancelled your appointment. If you’d like to book another
        appointment, you can do so at any time using our{" "}
        <Link href={`https://www.well.co.uk/vaccinations`}>
          vaccinations homepage
        </Link>
        .
      </Typography>
      <AccountInfoMessage pharmacy={cancelData?.pharmacy} />
      <Spacing spacing={4} />
      <Button
        data-testid="next"
        color="primary"
        variant="contained"
        fullWidth
        children={"Go to the homepage"}
        size="large"
        type="submit"
        onClick={onSubmit}
      />
    </Layout>
  );
};

export default AppointmentCancelConfirmationPage;
