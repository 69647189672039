import { Service } from "../service.types";
import { ServiceIds } from "../service.constants";

export const mockServices: Service[] = [
  {
    id: ServiceIds.PneumoniaPPV,
    name: "Pneumonia PPV vaccination",
    analyticsName: "PPV",
    fee: 30.0,
  },
  {
    id: ServiceIds.PneumoniaPrevenar13,
    name: "Pneumonia PCV (Prevenar13) vaccination",
    analyticsName: "Prevnar 13",
    fee: 70.0,
  },
  {
    id: ServiceIds.PneumoniaWalkIn,
    name: "Pneumonia",
    analyticsName: "Pneumonia Walk In",
    fee: 0,
    disablePrice: true,
  },
  {
    id: ServiceIds.HPV,
    name: "Gardasil 9 (two doses)",
    analyticsName: "Gardasil 9 (two doses)",
    fee: 320.0,
    minAge: 9,
    maxAge: 45,
    maxAgeMale: 45,
    maxAgeFemale: 45,
  },
  {
    id: ServiceIds.Chickenpox,
    name: "Chickenpox vaccination",
    analyticsName: "Chickenpox",
    fee: 130.0,
    minAge: 2,
    maxAge: 65,
  },
  {
    id: ServiceIds.MenBBexsero,
    name: "Meningitis-B vaccination",
    analyticsName: "Bexsero",
    fee: 220.0,
  },
  {
    id: ServiceIds.MenBTrumenba,
    name: "Meningitis-B",
    analyticsName: "Trumenba",
    fee: 220.0,
  },
  {
    id: ServiceIds.Flu,
    name: "Flu vaccination",
    analyticsName: "Flu",
    fee: 17.99,
    shouldSkipGpDetails: true,
    disablePrice: true,
  },
  {
    id: ServiceIds.FluPrevenar13,
    name: "Flu and pneumonia PCV (Prevenar 13) vaccinations",
    analyticsName: "FluPrevnar",
    fee: 87.99,
  },
  {
    id: ServiceIds.FluPPV,
    name: "Flu and pneumonia PPV vaccinations",
    analyticsName: "FluPPV",
    fee: 47.99,
  },
  {
    id: ServiceIds.CovidAntigen,
    name: "COVID-19 PCR test",
    analyticsName: "PCR",
    fee: 99,
    shouldSkipGpDetails: true,
  },
  {
    id: ServiceIds.CovidGreenPack,
    name: "COVID-19 Green travel package",
    analyticsName: "COVID-19 Green travel package",
    fee: 160,
    shouldSkipGpDetails: true,
    packContents: [
      "1 x Pre-departure PCR test",
      "1 x Day two PCR home test kit",
    ],
  },
  {
    id: ServiceIds.CovidAmberPack,
    name: "COVID-19 Amber travel package",
    analyticsName: "COVID-19 Amber travel package",
    fee: 225,
    shouldSkipGpDetails: true,
    packContents: [
      "1 x Pre-departure PCR test",
      "1 x Day two PCR home test kit",
      "1 x Day eight PCR home test kit",
    ],
  },
  {
    id: ServiceIds.Saxenda,
    name: "Medicated Weight Management Service",
    analyticsName: "Saxenda",
    fee: 0,
    shouldSkipGpDetails: true,
  },
  {
    id: ServiceIds.Wegovy,
    name: "Medicated Weight Management – Wegovy",
    fee: 20,
    analyticsName: "Medicated Weight Management – Wegovy",
    shouldSkipGpDetails: false,
  },
  {
    id: ServiceIds.HPVSingleDose,
    name: "Gardasil 9 (single dose)",
    analyticsName: "Gardasil 9 (single dose)",
    fee: 160.0,
    minAge: 9,
    maxAge: 45,
    maxAgeMale: 45,
    maxAgeFemale: 45,
  },
  {
    id: ServiceIds.HPVThreeDoses,
    name: "Gardasil 9 (three doses)",
    analyticsName: "Gardasil 9 (three doses)",
    fee: 480.0,
    minAge: 9,
    maxAge: 45,
    maxAgeMale: 45,
    maxAgeFemale: 45,
  },
];
