import { useCallback, useState } from "react";
import { fetchLocationsAPI } from "../../../api";
import { FetchLocationInput } from "../../../api/api.types";
import {
  Pharmacy,

} from "../../../stores/appointment/appointment.types";
import { getCurrentPosition } from "./utils";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { Service } from "../../../stores/service/service.types";

export type LocationSearchParams = {
  proximity?: number;
  service: Service;
};

export type LocationSearchResult = {
  searchTerm: string;
  currentLocation: Position | null;
  onSearch: (searchTerm: string) => Promise<void>;
  onSearchByCurrentLocation: () => void;
  isSearching: boolean;
  isLocationSearching: boolean;
  results: Pharmacy[] | null;
  error?: ErrorAlertProps;
};

export function useLocationSearch({
  service,
  proximity = 10,
}: LocationSearchParams): LocationSearchResult {
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isLocationSearching, setIsLocationSearching] = useState(false);
  const [error, setError] = useState<ErrorAlertProps>();
  const [currentLocation, setCurrentLocation] = useState<Position | null>(null);
  const [results, setResults] = useState<Pharmacy[] | null>(null);

  const getLocations = useCallback(
    async (input: FetchLocationInput) => {
      setError(undefined);
      try {
        const data = await fetchLocationsAPI(input);
        setResults(data.locations || []);
      } catch (e) {
        setError({ message: e.response?.data.message || e.message });
        setResults(null);
      }
    },
    [setResults]
  );

  const onSearch: LocationSearchResult["onSearch"] = useCallback(
    async (search: string) => {
      if (search.toLowerCase() === "enfield"){
        search = "london borough of enfield"
      }
      setIsSearching(true);
      setCurrentLocation(null);
      setSearchTerm(search);
      await getLocations({
        offset: 0,
        limit: 25,
        serviceId: service.id,
        location: `${search} uk`,
        proximity,
      });
      setIsSearching(false);
    },
    [service, proximity, getLocations]
  );

  const onSearchByCurrentLocation: LocationSearchResult["onSearchByCurrentLocation"] = useCallback(async () => {
    setIsLocationSearching(true);
    setSearchTerm(" ");
    setImmediate(setSearchTerm, "");
    const position: Position = await getCurrentPosition();
    setCurrentLocation(position);
    await getLocations({
      offset: 0,
      limit: 25,
      serviceId: service.id,
      location: `${position.coords.latitude}, ${position.coords.longitude}`,
      proximity,
    });
    setIsLocationSearching(false);
  }, [getLocations, proximity, service]);

  return {
    searchTerm,
    currentLocation,
    onSearch,
    onSearchByCurrentLocation,
    isLocationSearching,
    isSearching,
    results,
    error,
  };
}