import React from "react";

import { Typography } from "@welldigital/components";
import { formatConfirmationDateTime } from "../../../utils/date.utils";
import { Pharmacy } from "../../../stores/appointment/appointment.types";
import { Slot } from "../../BookAppointmentPage/BookAppointmentPage.types";

export type AppointmentCancelDetailsProps = {
  bookedSlot?: Slot;
  pharmacy?: Pharmacy;
  startTime?: Date;
};
const AppointmentCancelConfirmationDetails: React.FC<AppointmentCancelDetailsProps> =
  ({ bookedSlot, pharmacy, startTime }) => {
    return (
      <>
        <Typography variant={"h4"} spacingAfter={4}>
          Confirm your cancellation
        </Typography>
        {bookedSlot?.startTime && pharmacy ? (
          <Typography
            variant={"body1"}
            color={"textSecondary"}
            spacingAfter={2}
          >
            Please confirm the cancellation of your appointment on{" "}
            {formatConfirmationDateTime(bookedSlot.startTime)} at{" "}
            {pharmacy.name} below.
          </Typography>
        ) : startTime && pharmacy ? (
          <Typography
            variant={"body1"}
            color={"textSecondary"}
            spacingAfter={2}
          >
            Please confirm the cancellation of your appointment on{" "}
            {formatConfirmationDateTime(startTime)} at {pharmacy.name} below.
          </Typography>
        ) : null}
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={4}>
          Please note, if you cancel within 48 hours of your appointment time,
          you will not be eligible for a refund.
        </Typography>
      </>
    );
  };

export default AppointmentCancelConfirmationDetails;
