import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { Spacing, wellColors } from "@welldigital/components";

const AvailabilityLoader = (props: any) => {
  const ref = useRef<any>();
  const [width, setWidth] = useState<number>(722);
  const height = 56;

  useEffect(() => {
    if (!ref.current) return () => {};
    const onResize = () => setWidth(ref.current.parentNode.clientWidth);
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref]);

  return (
    <div ref={ref}>
      {Array.from(new Array(3)).map((i, k) => (
        <React.Fragment key={k}>
          <ContentLoader
            display="block"
            width="100%"
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor={wellColors.greyscale[100]}
            foregroundColor={wellColors.greyscale[400]}
            speed={1}
            {...props}
          >
            <rect x="0" y="0" rx="0" ry="0" width={width} height="2" />
            <rect x="0" y="0" rx="0" ry="0" width="2" height={height} />
            <rect
              x="0"
              y={height - 2}
              rx="0"
              ry={height - 2}
              width={width}
              height="2"
            />
            <rect
              x={width - 2}
              y="0"
              rx={width - 2}
              ry="0"
              width="2"
              height={height}
            />

            <circle cx={width - 30} cy={height / 2} r="8" />
            <rect
              x="20"
              y={height / 2 - 5}
              rx="5"
              ry="5"
              width="70"
              height="10"
            />
            <rect
              x="120"
              y={height / 2 - 5}
              rx="5"
              ry="5"
              width="150"
              height="10"
            />
          </ContentLoader>
          {k < 2 && <Spacing spacing={2} />}
        </React.Fragment>
      ))}
    </div>
  );
};

export default AvailabilityLoader;
