import { StartScreeningRequestGuardian } from "../pages/ScreeningQuestionsPage/hooks/hooks.types";
import {
  PaymentTypes,
  Pharmacy,
} from "../stores/appointment/appointment.types";
export const API_INIT_KEY = "api_init_key"
export const API_ENDPOINT = process.env.REACT_APP_SERVICE_API || "";
export const POSTCODE_ENDPOINT = process.env.REACT_APP_POSTCODE_API || "";
export const fullDateFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const yearMonthDateFormat = "yyyy-MM-dd";


export interface FetchLocationsResponse {
  locations: Pharmacy[];
  hasMore: boolean;
}

export interface GetAvailabilityParams {
  locationId: string;
  serviceId: number;
  startDate: string;
  endDate: string;
  numberOfBooks?: number;
  isDays?: true;
}

export interface AvailableDay {
  date: string; // date format like: "2021-04-01"
}

export interface ApiSlot {
  resourceId: string;
  startTime: string;
  endTime: string;
}

export interface GetAvailabilityResponse {
  daySlots: AvailableDay[];
  slots: ApiSlot[];
}

export enum Genders {
  male = "male",
  female = "female",
}

export interface NextScreeningRequest {
  currentQuestionNumber: number;
  nextQuestionNumber: number;
  patientAnswer: string;
  screeningId: string;
}

export interface PostcodeLookupAddress {
  line1: string;
  line2?: string;
  city: string;
  postcode: string;
  country: string;
}

export type AddressesByPostcodeResponse = {
  postcode: string;
  addresses: PostcodeLookupAddress[];
};

export interface FetchLocationInput {
  offset: number;
  limit: number;
  serviceId: number;
  location: string;
  proximity: number;
}

export interface StartScreeningRequest {
  dob: Date;
  sex: string;
  locationId: string;
  serviceId: number;
  firstNames: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  town: string;
  postcode: string;
  email?: string;
  phone?: string;
  nhsNumber?: string;
  surgeryName?: string;
  surgeryAddress?: string;
  allowMarketing?: boolean;
  guardian?: StartScreeningRequestGuardian;
}

export interface ScreeningResponsePayload {
  screeningId: string;
  patientAdvice: string;
  pharmacistInstructions: string;
  questionText: string;
  currentQuestionNumber: number;
  nextQuestionNumber: number;
  questionType: number;
  message: string;
  hasResult: boolean;
  isAllowedService: boolean;
}

export interface InitialAppointmentResponse{
  id: number;
  serviceName: string;
  groupId: number;
}

export interface InitialAppointment{
  initialAppointment: InitialAppointmentResponse[];
}

export interface BookInitAppointment {
  locationId: string;
  serviceId: number;
  resourceId: number;
  startDateTime: Date;
  endDateTime: Date;
}
export interface BookAppointment {
  screeningId: string;
  paymentNonce?: string;
  resourceId: number;
  startDate: Date;
  endDate: Date;
  paymentType: PaymentTypes;
  groupId?: string;
  data ?:InitialAppointmentResponse[]
}

export interface GetTokenResponse {
  token: string;
  status: boolean;
}

export interface BookAppointmentCompleteResponse {
  appointmentId: string,
  screeningId: string,
  userId: string
}