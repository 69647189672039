import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Button, ButtonProps, FormState } from "@welldigital/components";
import { ChevronRight } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import useFormContext from "../../utils/useFormContext";

export type LayoutNavigationProps = {
  nextButton: Omit<ButtonProps, "size"> & { "data-testid"?: string };
  backButton?: Omit<ButtonProps, "size"> & { "data-testid"?: string };
  spacingBefore?: number;
};

const LayoutNavigation: React.FC<LayoutNavigationProps> = ({
  backButton,
  nextButton,
  spacingBefore,
}) => {
  const form = useFormContext();
  const history = useHistory();

  return (
    <Box mt={spacingBefore}>
      <Grid container spacing={2} wrap="wrap-reverse">
        <Grid item>
          <Button
            onClick={history.goBack}
            variant="text"
            children={"Back"}
            size="large"
            minWidth={"100px"}
            disabled={
              backButton?.disabled || form?.state === FormState.submitting
            }
            {...backButton}
          />
        </Grid>
        <Grid item xs>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            children={"Continue"}
            size="large"
            endIcon={<ChevronRight />}
            loading={
              nextButton?.loading || form?.state === FormState.submitting
            }
            {...nextButton}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

LayoutNavigation.defaultProps = {
  spacingBefore: 4,
};

export default LayoutNavigation;
