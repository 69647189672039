import React from "react";
import { Typography } from "@welldigital/components";
import { Box, Link } from "@material-ui/core";

export type ScreeningInfoProps = {
  isOwner: boolean;
};

const ScreeningInfo: React.FC<ScreeningInfoProps> = ({ isOwner }) => {
  return (
    <Box mb={3}>
      <Typography variant={"h4"} spacingAfter={2}>
        Screening questions
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        {isOwner
          ? "Please complete the following questions so that our pharmacist can assess your suitability for this service."
          : "Please complete the following questions on this person’s behalf so that our pharmacist can assess their suitability for this service."}
      </Typography>

      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
        Any information you give will be treated in the strictest confidence.
        Please see our{" "}
        <Link href="https://www.well.co.uk/about-us/policies/privacy">
          {"Privacy Policy"}
        </Link>{" "}
        for more information.
      </Typography>
    </Box>
  );
};

export default ScreeningInfo;
