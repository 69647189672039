import React from "react";
import ReactMarkdown from "react-markdown";
import { formatMarkdown } from "../../../../utils/utils";
import ScreeningInfo from "../ScreeningInfo/ScreeningInfo";
import LayoutNavigation from "../../../../components/LayoutNavigation/LayoutNavigation";
import { BookingTypes } from "../../../../stores/appointment/appointment.types";

export type ScreeningFinishedProps = {
  bookingType: BookingTypes;
  advice: string;
  onComplete: () => void;
  isOwner: boolean;
};

const ScreeningFinished: React.FC<ScreeningFinishedProps> = ({
  bookingType,
  advice,
  onComplete,
  isOwner,
}) => (
  <>
    <ScreeningInfo isOwner={isOwner} />
    <ReactMarkdown source={formatMarkdown(advice)} />
    <LayoutNavigation
      nextButton={{
        children:
          bookingType === BookingTypes.owner ? "Book appointment" : "Continue",
        onClick: onComplete,
      }}
    />
  </>
);

export default ScreeningFinished;
