import React from "react";
import { Box, Grid } from "@material-ui/core";
import { Typography } from "@welldigital/components";
import { formatPrice } from "../../../../utils/price.utils";
import { Appointment } from "../../../../stores/appointment/appointment.types";
import CartUpdatedAlert from "./CartUpdatedAlert";
import { useCartStore } from "../../../../stores/cart/cart.store";

export interface DetailsPanelStateProps {
  appointment: Appointment;
}

const Cart: React.FC<DetailsPanelStateProps> = ({ appointment }) => {
  const [cartItems, totalFee] = useCartStore((state) => [
    state.cartItems,
    state.totalFee,
  ]);

  if (totalFee === -1) return null;
  return (
    <>
      <Typography variant={"h6"} spacingAfter={3}>
        Your basket
      </Typography>
      <Box mb={2}>
        {cartItems.map(({ serviceName, fee, amount, packContents }, k) => (
          <Grid container key={k}>
            <Grid item xs>
              <Typography variant="subtitle2">
                {serviceName}
                {amount > 1 ? ` (x${amount})` : ""}
              </Typography>
              {!!packContents.length && (
                <Grid item xs>
                  {packContents.map((service, k) => (
                    <Typography
                      key={k}
                      variant="subtitle2"
                      color="textSecondary"
                      children={service}
                      spacingAfter={1}
                    />
                  ))}
                </Grid>
              )}
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" align="right">
                {formatPrice(fee)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="subtitle1" align="left">
            Total
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" align="right">
            {formatPrice(totalFee)}
          </Typography>
        </Grid>
      </Grid>
      <CartUpdatedAlert />
    </>
  );
};

export default Cart;
