import { Service } from "../stores/service/service.types";
import { ServiceIds } from "../stores/service/service.constants";

export default function getContactEmailByService(service?: Service) {
  const fluEmail = "hello@well.co.uk";
  const pharmacyEmail = "hello@well.co.uk";
  const defaultEmail = "hello@well.co.uk";
  const serviceEmailMap = {
    [ServiceIds.Flu]: fluEmail,
    [ServiceIds.HPV]: pharmacyEmail,
    [ServiceIds.MenBBexsero]: pharmacyEmail,
    [ServiceIds.MenBTrumenba]: pharmacyEmail,
    [ServiceIds.PneumoniaPPV]: pharmacyEmail,
    [ServiceIds.PneumoniaPrevenar13]: pharmacyEmail,
    [ServiceIds.PneumoniaWalkIn]: pharmacyEmail,
    [ServiceIds.Chickenpox]: pharmacyEmail,
    [ServiceIds.Saxenda]: pharmacyEmail,
    default: defaultEmail,
  };
  return (service && serviceEmailMap[service.id]) || serviceEmailMap.default;
}
