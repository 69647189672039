import React, { useMemo } from "react";
import { Typography, DatePickerField } from "@welldigital/components";
import { ThemeProvider, createMuiTheme } from "@material-ui/core"
import { subYears, addDays } from "date-fns";
import { getBirthDateValidationMessages } from "../../../../components/validationMessages";
import { Service } from "../../../../stores/service/service.types";
import useFormContext from "utils/useFormContext";
import { Genders } from "api/api.types";
import { useAppointmentStore } from "../../../../stores/appointment/appointment.store";

export type BirthDateFieldProps = {
  isOwner: boolean;
  service: Service;
};

const defaultMaterialTheme = createMuiTheme({
  overrides: {
    MuiFormControl: {
      root: {
        border: "1px solid #C4C4C4",
        borderRadius: "4px",
        "&:hover": {
          border: "1px solid rgba(0, 0, 0, 0.87)",
        },
      },
    },
    MuiInput: {
      root: {
        borderBottom: "0px",
        "&:hover": {
          borderBottom: "0px",
        },
      },
      underline: {
        "&::before": {
          borderBottom: "0px",
        },
        "&::after": {
          borderBottom: "0px",
          boxShadow: "none",
        },
        "&:hover": {
          boxShadow: "none",
          borderBottom: "0px",
        },
        '&:hover:not($disabled):before': {
          borderBottom: "0px",
        },
        "&:focus": {
          boxShadow: "none",
          borderBottom: "0px",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: "#0033E8",
        // "&:focus": {
        //   border: "0px",
        // },
        // "&:hover, &:focus": {
        //   border: "0px",
        // },
      },
    },
    MuiInputBase: {
      root: {
        color: "rgba(0, 0, 0, 0.87)",
        cursor: "text",
        display: "inline-flex",
        position: "relative",
        fontSize: "1rem",
        boxSizing: "border-box",
        alignItems: "center",
        fontFamily: "GilroyAlt",
        fontWeight: 500,
        lineHeight: "1.1876em",
        letterSpacing: "0.5px",
        "&:focus": {
          border: "2px solid rgba(0, 0, 0, 0.87)",
        },
        "&$focused": {
          outline: "#0033E8 auto 1px",
        },
      },
      input: {
        font: "inherit",
        color: "currentColor",
        width: "100%",
        border: 0,
        height: "48px",
        margin: 0,
        display: "block",
        padding: "6px 7px 7px",
        minWidth: 0,
        background: "none",
        boxSizing: "border-box",
        animationName: "mui-auto-fill-cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
        WebkitTapHighlightColor: "transparent",
      },
    },
    MuiTypography: {
      subtitle1: {
        width: "25%",
        float: "left",
        fontFamily: "GilroyAlt",
      },
      colorPrimary: {
        width: "25%",
        float: "left",
        margin: "auto !important",
        fontWeight: 500,
        fontSize: "1rem",
        color: "#0033E8",
        borderRadius: "30px",
      },
      body1: {
        fontSize: "1rem",
        fontFamily: "GilroyAlt",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.5px",
      },
      h4: {
        fontSize: "2.125rem",
        fontFamily: "GilroyAlt",
        fontWeight: 600,
        lineHeight: "125%",
        letterSpacing: "0.25px",
      },
      h5: {
        fontSize: "1.5rem",
        fontFamily: "GilroyAlt",
        fontWeight: 600,
        lineHeight: "150%",
      },
      caption: {
        fontWeight: 500,
        fontFamily: "GilroyAlt",
        letterSpacing: "0.5px",
      },
    },
    //@ts-ignore
    MuiPickersMonth: {
      root: {
        flex: "1 0 33.33%",
        cursor: "pointer",
        height: "75px",
        display: "flex",
        outline: "none",
        transition: "font-size 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 600,
        lineHeight: "150%",
      },
      monthSelected: {
        color: "#0033E8",
        fontWeight: 500,
        fontSize: "1.5rem",
      }
    },
    MuiPickersYear: {
      yearSelected: {
        fontSize: "1.5rem",
      }
    },
    MuiPickersToolbarText: {
      toolbarBtnSelected: {
        width: "100%",
      },
      toolbarTxt: {
        color: "rgba(255, 255, 255, 0.54)",
        width: "100%",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: "-8px",
        marginBottom: "24px",
        "& p": {
          padding: "0px 18px",
          fontWeight: 600,
        },
      },
      daysHeader: {
        marginBottom: "16px",
      },
      dayLabel: {
        color: "#0C161F",
        width: "44px",
        margin: "0px",
        fontSize: "1rem",
        textAlign: "center",
        lineHeight: "1.5rem",
      },
      iconButton: {
        color: "#0C161F",
        transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#0033E8",
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: "18.5px 14px",
      },
    },
    MuiToolbar: {
      root: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        background: "#0033E8",
      },
      regular: {
        minHeight: "56px",
      },
      gutters: {
        paddingLeft: "16px",
        paddingRight: "16px"
      },
    },
    MuiDialogActions: {
      root: {
        flex: "0 0 auto",
        display: "flex",
        padding: "8px",
        alignItems: "center",
        justifyContent: "flex-end",
      },
    },
    MuiPickersDay: {
      day: {
        color: "rgba(0, 0, 0, 0.87)",
        width: "44px",
        height: "44px",
        margin: "0px",
        fontSize: "0.75rem",
        fontWeight: 500,
        "& p": {
          fontSize: "1rem",
          fontFamily: "GilroyAlt",
          fontWeight: 400,
          lineHeight: "150%",
        },
      },
      daySelected: {
        fontWeight: 500,
        color: "rgba(0, 0, 0, 0.87)",
        border: "4px solid #0C161F",
        backgroundColor: "#fff",
        "&:hover": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          border: "4px solid #0C161F",
        },
      },
    },
    MuiButton: {
      root: {
        color: "rgba(0, 0, 0, 0.87)",
        height: "initial",
        padding: "8px 12px",
        fontSize: "15px",
        minWidth: "150px",
        boxSizing: "border-box",
        minHeight: "48px",
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        fontFamily: "GilroyAlt",
        fontWeight: 600,
        lineHeight: "150%",
        borderRadius: "6px",
        textTransform: "initial",
      },
      label: {
        fontWeight: 600,
      },
      textPrimary: {
        color: "#fff",
        backgroundColor: "#0033E8",
        "&:hover": {
          backgroundColor: "rgb(0, 35, 162)",
        },
      },
      contained: {
        color: "#0033E8",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
        backgroundColor: "#E0ECFD",
      },
      containedPrimary: {
        color: "#fff",
        backgroundColor: "#0033E8",
        "&:hover": {
          backgroundColor: "0px",
        },
      }
    }
  },
})

const BirthDateField: React.FC<BirthDateFieldProps> = ({
  isOwner,
  service,
}) => {
  const { form } = useFormContext();
  const gender = form.watch("details.gender");

  const minAge = service.minAge || 2;
  const maxAge =
    (gender === Genders.male ? service.maxAgeMale : service.maxAgeFemale) ||
    120;

  // Age is inclusive, e.g. a maxAge of 65 would allow anyone that is up to
  // 65y 11m and 30d old, but not 66yo, even if 66y and 0d
  const minDate = useMemo(
    () => addDays(subYears(new Date(), maxAge + 1), 1),
    [maxAge]
  );
  const maxDate = useMemo(() => subYears(new Date(), minAge), [minAge]);
  const birthDateValidationMessages = useMemo(
    () => getBirthDateValidationMessages(minAge, maxAge),
    [minAge, maxAge]
  );
  const { appointment } = useAppointmentStore();
  return (
    <>
      <Typography variant={"h6"}>
        {isOwner ? "Enter your date of birth" : "Enter their date of birth"}
      </Typography>
      {!isOwner && (
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={2}>
          We need to check if this person is over or under 18 before you enter
          more information about them.
        </Typography>
      )}

      <ThemeProvider theme={defaultMaterialTheme}>
      <DatePickerField
        name={"details.birthDate"}
        views={["year", "month", "date"]}
        required
        spacingAfter={3}
        placeholder={"Date of birth"}
        disableFuture
        invalidDate
        minDate={appointment?.bookingType === 'otherExcludingOwner' && isOwner ? new Date('1900-01-01') : minDate}
        maxDate={maxDate}
        validationMessages={birthDateValidationMessages}
        />
      </ThemeProvider>
    </>
  );
};
export default BirthDateField;