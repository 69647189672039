import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  AccountStoreState,
  useAccountStore,
} from "../../../stores/account/account.store";
import { Appointment } from "../../../stores/appointment/appointment.types";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";

type Params = {
  appointmentId: string;
};

export type RescheduleConfirmationState = {
  error?: ErrorAlertProps;
  appointment: Appointment;
  appointmentId: string;
  clearReschedule: AccountStoreState["clearReschedule"];
};

export function useRescheduleConfirmation(): RescheduleConfirmationState {
  const { appointmentId } = useParams<Params>();
  const [
    error,
    fetchAppointmentDetails,
    appointment,
    clearReschedule,
  ] = useAccountStore((state) => [
    state.error,
    state.fetchAppointmentDetails,
    state.appointmentMap[appointmentId],
    state.clearReschedule,
  ]);

  useEffect(() => {
    fetchAppointmentDetails(appointmentId);
  }, [fetchAppointmentDetails, appointmentId]);

  return {
    error,
    appointment,
    appointmentId,
    clearReschedule,
  };
}
