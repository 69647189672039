import create from "zustand";
import { v4 as generateUuid } from "uuid";
import {
  CustomerAppointmentDetails,
  BookingTypes,
  Pharmacy,
  Appointment,

} from "./appointment.types";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from "../../pages/LocationsPage/hooks/utils";
import { DeepPartial } from "react-hook-form";
import { Slot } from "../../pages/BookAppointmentPage/BookAppointmentPage.types";
import { Service } from "../service/service.types";

export interface AppointmentStoreState {
  appointment: Appointment | null;
  initAppointment: (service: Service, pharmacy?: Pharmacy) => void;
  setBookingType: (bookingType: BookingTypes) => void;
  deleteAppointment: () => void;
  setAdditionalServices: (hasAdditionalServices: boolean) => void;
  setOwnerDetails: (owner: CustomerAppointmentDetails) => void;
  addOtherCustomer: () => void;
  deleteOtherCustomer: (customerIndex: number) => void;
  setOtherCustomerDetails: (
    customerId: string,
    details: CustomerAppointmentDetails
  ) => void;
  setScreeningId: (customerId: string, screeningId: string) => void;
  setCovidQuestionsFinished: (customerId: string) => void;
  setBookingDetailsFinished: () => void;
  setBookedSlot: (slot: Slot) => void;
  setAppointmentComplete: () => void;
  setTraversalId: (traversalId: string) => void;
}

export enum PersisterLocalStorageKeys {
  appointment = "appointment",
  service = "service",
  pharmacy = "pharmacy",
}

export const useAppointmentStore = create<AppointmentStoreState>((set) => {
  const handleUpdateAppointment = (
    cb: (oldAppointment: Appointment) => DeepPartial<Appointment>,
    ignoreIfNotFound?: boolean
  ) => {
    const appointment = getFromLocalStorage<Appointment>(
      PersisterLocalStorageKeys.appointment
    );
    if (!appointment && !ignoreIfNotFound) {
      throw new Error(`The appointment does not exist`);
    }
    const newAppointment = cb(appointment as Appointment) as Appointment;
    setToLocalStorage(PersisterLocalStorageKeys.appointment, newAppointment);
    (window as any).appointment = newAppointment;
    set({ appointment: newAppointment });
  };
  (window as any).appointment = getFromLocalStorage<Appointment>(
    PersisterLocalStorageKeys.appointment
  );

  return {
    appointment: getFromLocalStorage<Appointment>(
      PersisterLocalStorageKeys.appointment
    ),

    deleteAppointment() {
      set({ appointment: null });
      deleteFromLocalStorage(PersisterLocalStorageKeys.appointment);
    },

    initAppointment(service, pharmacy) {
      handleUpdateAppointment(
        () => ({
          service,
          pharmacy,
        }),
        true
      );
    },

    setBookingType(bookingType) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        bookingType,
        other:
          bookingType === BookingTypes.otherExcludingOwner
            ? [{ id: generateUuid(), service: oldAppointment.service }]
            : [],
        owner: {
          id: generateUuid(),
          service:
            bookingType !== BookingTypes.otherExcludingOwner
              ? oldAppointment.service
              : undefined,
        },
      }));
    },

    setAdditionalServices(hasAdditionalServices) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        hasAdditionalServices,
      }));
    },

    setOwnerDetails(owner) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        details: owner.details,
        owner,
      }));
    },

    addOtherCustomer() {
      handleUpdateAppointment((oldAppointment) => {
        return {
          ...oldAppointment,
          other: [
            ...oldAppointment.other,
            { id: generateUuid(), service: oldAppointment.service },
          ],
        };
      });
    },

    deleteOtherCustomer(customerIndex) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        other: oldAppointment.other.filter(
          (i, index) => index !== customerIndex
        ),
      }));
    },

    setOtherCustomerDetails(customerId, details) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        other: oldAppointment.other.map((customer) =>
          customer.id === customerId ? details : customer
        ),
      }));
    },

    setScreeningId(customerId, screeningId) {
      handleUpdateAppointment((oldAppointment) => {
        const newAppointment = { ...oldAppointment };
        if (customerId === "owner") {
          newAppointment.owner.screeningId = screeningId;
        } else {
          const customer = newAppointment.other.find(
            ({ id }) => customerId === id
          ) as CustomerAppointmentDetails;
          customer.screeningId = screeningId;
        }
        return newAppointment;
      });
    },

    setCovidQuestionsFinished(customerId) {
      handleUpdateAppointment((oldAppointment) => {
        const newAppointment = { ...oldAppointment };
        if (customerId === "owner") {
          newAppointment.owner.covidQuestionsFinished = true;
        } else {
          const customer = newAppointment.other.find(
            ({ id }) => customerId === id
          ) as CustomerAppointmentDetails;
          customer.covidQuestionsFinished = true;
        }
        return newAppointment;
      });
    },

    setBookingDetailsFinished() {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        bookingDetailsFinished: true,
      }));
    },

    setBookedSlot(bookedSlot) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        bookedSlot,
      }));
    },

    setAppointmentComplete() {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        appointmentComplete: true,
      }));
    },
    setTraversalId(traversalId) {
      handleUpdateAppointment((oldAppointment) => ({
        ...oldAppointment,
        traversalId,
      }));
    },
  } as AppointmentStoreState;
});
