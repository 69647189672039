import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Form,
  InputField,
  Typography,
  Divider,
} from "@welldigital/components";
import MainLayout from "../../components/MainLayout/MainLayout";
import { Paths } from "../paths";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import { Appointment } from "../../stores/appointment/appointment.types";
import { Box, Grid } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { goToHref } from "../../utils/location.utils";

enum CheckoutOptionsPageRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
  bookingDetailsFinished = "bookingDetailsFinished",
}

type CheckoutOptionsPageProps = Pick<
  Appointment,
  keyof typeof CheckoutOptionsPageRequiredProps
>;

export const CheckoutOptionsPage: React.FC<CheckoutOptionsPageProps> = () => {
  const history = useHistory();

  const submitNext = useCallback(({ email }: { email: string }) => {
    goToHref(`${process.env.REACT_APP_ACCOUNT_API}/sign-in?email=${email}`);
  }, []);

  const checkoutAsGuest = useCallback(() => {
    history.push(Paths.BookAppointment);
  }, [history]);

  return (
    <MainLayout>
      <Form onSubmit={submitNext} fullWidth>
        <Typography variant={"h4"} spacingAfter={4}>
          How do you want to checkout?
        </Typography>
        <Typography variant={"h5"}>Sign in</Typography>
        <Typography variant={"body2"} color={"textSecondary"} spacingAfter={2}>
          If you already have a Well account, enter your email address to sign
          in.
        </Typography>

        <InputField
          name={"email"}
          label={"Email address"}
          required
          validationMessages={{ required: "Enter an email address" }}
        />

        <Grid container alignItems={"center"}>
          <Grid item xs>
            <Button
              data-testid={"CheckoutOptionsPage/login"}
              color={"primary"}
              variant={"contained"}
              fullWidth
              children={"Continue"}
              size={"large"}
              endIcon={<ChevronRight />}
              type={"submit"}
            />
          </Grid>
          <Grid item>
            <Box ml={3} mr={1}>
              <Typography
                variant={"body2"}
                color={"textSecondary"}
                component={"span"}
              >
                Don’t have an account?{" "}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              data-testid={"CheckoutOptionsPage/register"}
              type={"submit"}
              variant={"text"}
              color={"default"}
              minWidth={0}
            >
              Register now
            </Button>
          </Grid>
        </Grid>

        <Divider spacingBefore={4} spacingAfter={4} text={"or"} />

        <Button
          data-testid={"CheckoutOptionsPage/guest"}
          onClick={checkoutAsGuest}
          color={"default"}
          variant={"contained"}
          fullWidth
        >
          Checkout as guest
        </Button>
      </Form>
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<CheckoutOptionsPageProps>
    validatedProps={
      Object.keys(CheckoutOptionsPageRequiredProps) as (keyof Appointment)[]
    }
    page={CheckoutOptionsPage}
  />
);
