import React from "react";
import { CheckboxField } from "@welldigital/components";
import { Box } from "@material-ui/core";

const MarketingField: React.FC = () => {
  return (
    <Box mt={2}>
      <CheckboxField
        name={"allowMarketing"}
        label={`Optional: I would like to receive health advice and offers from Well by email. (You can change your marketing preferences at any time)`}
      />
    </Box>
  );
};

export default MarketingField;
