import React from "react";
import IneligibleOwner from "./IneligibleOwner/IneligibleOwner";
import IneligibleOwnerAddon from "./IneligibleOwnerAddon/IneligibleOwnerAddOn";
import IneligibleOther from "./IneligibleOther/IneligibleOther";
import { Pharmacy } from "../../../../stores/appointment/appointment.types";
import { Service } from "../../../../stores/service/service.types";
import { ServiceIds } from "../../../../stores/service/service.constants";

export type ScreeningIneligibleProps = {
  isOwner: boolean;
  pharmacy: Pharmacy;
  service: Service;
  onOwnerContinue: () => void;
  onOwnerAddonContinue: () => void;
  onOtherContinue: () => void;
};

const ScreeningIneligible: React.FC<ScreeningIneligibleProps> = ({
  isOwner,
  pharmacy,
  service,
  onOwnerContinue,
  onOwnerAddonContinue,
  onOtherContinue,
}) => {
  const isFluAddon = [ServiceIds.FluPPV, ServiceIds.FluPrevenar13].includes(
    service.id
  );
  if (isOwner && !isFluAddon) {
    return <IneligibleOwner pharmacy={pharmacy} onContinue={onOwnerContinue} />;
  }
  if (isOwner && isFluAddon) {
    return (
      <IneligibleOwnerAddon
        pharmacy={pharmacy}
        onContinue={onOwnerAddonContinue}
      />
    );
  }
  return <IneligibleOther pharmacy={pharmacy} onContinue={onOtherContinue} />;
};

export default ScreeningIneligible;
