import React from "react";
import {
  checkIfDateIsValid,
  checkIfUnder18,
} from "../../CustomerDetailsPage.utils";
import { WatchFields } from "@welldigital/components";

export type DisplayGuardianFieldsProps = {
  isOwner: boolean;
};

export type DisplayGuardianWatchFieldsParams = {
  "details.birthDate": Date;
};

export type CanDisplayIneligibleProps = {
  birthDate: Date;
};

const DisplayConsent: React.FC<DisplayGuardianFieldsProps> = ({
  children,
  isOwner,
}) => {
  return (
    <WatchFields fieldNames={["details.birthDate"]}>
      {({ "details.birthDate": birthDate }: DisplayGuardianWatchFieldsParams) =>
        checkIfDateIsValid(birthDate) && !checkIfUnder18(birthDate) && !isOwner
          ? children
          : null
      }
    </WatchFields>
  );
};

export default DisplayConsent;
