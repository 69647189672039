import { useCallback, useEffect, useState } from "react";
import { getAvailabilityAPI } from "../../../api";
import {
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  getDaysInMonth,
  parse,
  setDate,
  startOfDay,
  startOfMonth,
} from "date-fns";
import {
  CalendarData,
  dayFormat,
  monthFormat,
  MonthData,
} from "@welldigital/components";
import {
  yearMonthDateFormat,
  fullDateFormat,
  AvailableDay,
} from "../../../api/api.types";
import {
  Appointment,
  CustomerAppointmentDetails,
} from "../../../stores/appointment/appointment.types";
import { Slot } from "../BookAppointmentPage.types";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { Paths } from "../../paths";
import { generatePath } from "react-router-dom";
import { ServiceIds } from "../../../stores/service/service.constants";

type FindAvailabilityParams = {
  minDate: Date;
  maxDate?: Date;
  appointment: Appointment | null;
  customersBooked: CustomerAppointmentDetails[];
};

export type FindAvailabilityState = {
  error?: ErrorAlertProps;
  nextAvailableSlot: Slot | null;
  fetchSlots: (date: Date) => Promise<void>;
  slots: Slot[] | null;
  fetchMonthCalendarData: (formattedMonth: string) => Promise<void>;
  calendarData: CalendarData;
};

export function useFindAvailability({
  minDate,
  maxDate,
  appointment,
  customersBooked,
}: FindAvailabilityParams): FindAvailabilityState {

  const service = appointment?.owner.serviceOverride || appointment?.service;
  const [error, setError] = useState<ErrorAlertProps>();
  const [nextAvailableSlot, setNextAvailableSlot] = useState<Slot | null>(null);
  const [calendarData, setData] = useState<CalendarData>({});
  const [fetchingCalendarData, setFetchingCalendarData] = useState<{
    [key: string]: boolean;
  }>({});
  const [slots, setSlots] = useState<FindAvailabilityState["slots"]>(null);
  const numberOfBookings = customersBooked.length;

  useEffect(() => {
    if (!customersBooked.length) return;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const customersAreValid = customersBooked.every(
      ({ details, screeningId }) => details && screeningId
    );
    if (!customersAreValid) {
      setError({
        message: "The people booked don't have all their details set",
        action: {
          children: "Enter booking details",
          to:
            customersBooked.length === 1
              ? generatePath(Paths.CustomerDetails, { customerId: "owner" })
              : Paths.BookingDetails,
        },
      });
    }
  }, [customersBooked]);

  const setMonthData = useCallback(
    (formattedMonth: string, daySlots: AvailableDay[]) => {
      const month = parse(formattedMonth, monthFormat, new Date());
      const monthData: MonthData = {};
      const availableDays = daySlots.map(({ date }) => date);
      const daysInMonth = getDaysInMonth(month);

      for (let i = 1; i <= daysInMonth; i++) {
        const day = setDate(month, i);
        const dayString = format(day, yearMonthDateFormat);
        if (!availableDays.includes(dayString)) {
          const formattedDay = format(day, dayFormat);
          monthData[formattedDay] = { isUnavailable: true };
        }
      }

      setData((oldState) => ({
        ...oldState,
        [formattedMonth]: monthData,
      }));
    },
    []
  );

  const getFirstAvailableDays = useCallback(async (): Promise<Date[]> => {
    if (!appointment || !service) return [];

    const { daySlots } = await getAvailabilityAPI({
      numberOfBooks: numberOfBookings,
      locationId: appointment.pharmacy.id,
      serviceId: service.id,
      startDate: format(minDate, fullDateFormat),
      endDate: format(
        maxDate || endOfMonth(addMonths(minDate, 2)),
        fullDateFormat
      ),
      isDays: true,
    });
    if (daySlots === null) {
      throw new Error(
        "We're sorry, this service is not available at the pharmacy you selected"
      );
    }

    const servicesToSkip = ServiceIds.ServiceToSkip?.split(',');

    let availableSlot: any[] = [];
    if (servicesToSkip?.includes(service.id.toString())) {
      let startDayString = process.env.REACT_APP_FLU_SKIP_START_DATE;
      let endDayString = process.env.REACT_APP_FLU_SKIP_END_DATE;
      let startDayArr = startDayString && startDayString.split("-");
      let endDayArr = endDayString && endDayString.split("-");
      var startDay = startDayArr && new Date(parseInt(startDayArr[2]), parseInt(startDayArr[1]) - 1, parseInt(startDayArr[0]));
      var endDay = endDayArr && new Date(parseInt(endDayArr[2]), parseInt(endDayArr[1]) - 1, parseInt(endDayArr[0]));

      availableSlot = daySlots.filter((elm) => {
        let arr = elm.date.split('-')
        const dateToSkip = new Date(parseInt(arr[0]), parseInt(arr[1]) - 1, parseInt(arr[2]));
        if (startDay && endDay) {
          if (dateToSkip >= startDay && dateToSkip <= endDay) {
            return false;
          } else
            return true;
        }
        return true;
      });

      setMonthData(format(minDate, monthFormat), availableSlot);
      setMonthData(
        format(maxDate || addMonths(minDate, 1), monthFormat),
        availableSlot
      );
    } else {
      setMonthData(format(minDate, monthFormat), daySlots);
      setMonthData(
        format(maxDate || addMonths(minDate, 1), monthFormat),
        daySlots
      );
    }

    if (servicesToSkip?.includes(service.id.toString())) {
      return availableSlot
        .filter((i, index) => index < 2) // first 2 entries
        .map(({ date }) => parse(date, yearMonthDateFormat, new Date()));
    }
    return daySlots
      .filter((i, index) => index < 2) // first 2 entries
      .map(({ date }) => parse(date, yearMonthDateFormat, new Date()));
  }, [minDate, maxDate, appointment, setMonthData, numberOfBookings, service]);

  const getFirstAvailableSlot = useCallback(
    async (firstAvailableDays: Date[]): Promise<Slot | null> => {
      if (!appointment || !service) return null;

      const data = await getAvailabilityAPI({
        numberOfBooks: numberOfBookings,
        locationId: appointment.pharmacy.id,
        serviceId: service.id,
        startDate: format(startOfDay(firstAvailableDays[0]), fullDateFormat),
        endDate: format(
          endOfDay(firstAvailableDays[1] || firstAvailableDays[0]),
          fullDateFormat
        ),
      });

      if (!data.slots) return null;

      return Slot.fromRawSlot(data.slots[0]);
    },
    [appointment, numberOfBookings, service]
  );

  useEffect(() => {
    (async () => {
      const firstAvailableDays = await getFirstAvailableDays();
      const data = await getFirstAvailableSlot(firstAvailableDays);
      setNextAvailableSlot(data);
    })().catch((e) => {
      setError({
        message: e.response?.data?.message || e.message,
        action: {
          children: "Select another pharmacy",
          href: "/",
        },
      });
    });
  }, [getFirstAvailableDays, getFirstAvailableSlot]);

  const fetchSlots: FindAvailabilityState["fetchSlots"] = useCallback(
    async (selectedDate: Date) => {
      if (!appointment || !service) return;
      setSlots(null);

      const data = await getAvailabilityAPI({
        locationId: appointment.pharmacy.id,
        serviceId: service.id,
        startDate: format(startOfDay(selectedDate), fullDateFormat),
        endDate: format(endOfDay(selectedDate), fullDateFormat),
      });
      const { slots: rawSlots } = data;

      const slots = (rawSlots || []).map(Slot.fromRawSlot); // TODO: remove the fallback after backend implemented

      setSlots(slots);
    },
    [appointment, service]
  );

  const setFetchingMonth = useCallback(
    (formattedMonth: string, fetching: boolean) => {
      setFetchingCalendarData({
        ...fetchingCalendarData,
        [formattedMonth]: fetching,
      });
    },
    [fetchingCalendarData]
  );

  const fetchMonthCalendarData: FindAvailabilityState["fetchMonthCalendarData"] = useCallback(
    async (formattedMonth) => {
      if (
        !appointment ||
        calendarData[formattedMonth] ||
        fetchingCalendarData[formattedMonth] ||
        !service
      )
        return;
      setFetchingMonth(formattedMonth, true);
      const month = parse(formattedMonth, monthFormat, new Date());
      const startDate = format(startOfMonth(month), fullDateFormat);
      const endDate = format(endOfMonth(month), fullDateFormat);
      const { daySlots } = await getAvailabilityAPI({
        locationId: appointment.pharmacy.id,
        serviceId: service.id,
        startDate,
        endDate,
        isDays: true,
      });
      if (!daySlots?.length) return;

      setMonthData(formattedMonth, daySlots);
      setFetchingMonth(formattedMonth, false);
    },
    [
      appointment,
      calendarData,
      fetchingCalendarData,
      setFetchingMonth,
      setMonthData,
      service,
    ]
  );

  return {
    error,
    fetchSlots,
    slots,
    nextAvailableSlot,
    fetchMonthCalendarData,
    calendarData,
  };
}