export default {
  location: {
    noneAvailable: "no locations found", // after searching for locations, if none are found
    selected: "pharmacy selected", // after selecting a pharmacy and continuing
  },
  bookingFor: {
    next: "booking for", // after selecting who they are booking for, either on modal confirm or on next
    bookingTypeMap: {
      owner: "myself",
      otherIncludingOwner: "myself and others",
      otherExcludingOwner: "other people",
    },
  },
  bookingDetails: {
    next: "how many people",
  },
  customerDetails: {
    wantsAddOn: "wants add on", // when opening the modal
    selectsAddOn: "selects add on", // in the modal, when selecting the add on
    confirmsAddOn: "confirms add on", // in the customer details page, when opening the modal
    next: "details entered", // after submitting your details (or their details in guardian flow)
    guardianTypeSelected: "guardian details", // after selecting the guardian type
    selectsPayment: "payment option", // when selecting the payment type
  },
  appointment: {
    selected: "appointment selected", // after the user clicks next with a selected appointment
    noneAvailable: "no appointments available", // when the user selects a day with no appointments, or the user sees that the initial day has no appointments
  },
  payment: {
    complete: "payment complete", // just before the user is redirected to the confirmation page
    failed: "payment failed", // after the pay button is clicked, if payment fails
  },
  screening: {
    started: "screening started", // when the question page is first loaded before first screening question is fetched
    answeredQuestion: "screening answered question", // after next pressed when answering a question
    complete: "screening questions", // after the book appointment button is pressed
    ineligible: "ineligible", // after the user has answered a question, if the response from the API says the user is ineligible
  },
  confirmation: {
    bookingComplete: "booking complete",
  },
};
