export enum Paths {
  Locations = "/",
  BookingFor = "/booking-for",
  BookingDetails = "/booking-details",
  CustomerDetails = "/booking-details/customer/:customerId",
  ScreeningQuestions = "/screening-questions/customer/:customerId",
  CovidQuestions = "/covid-questions/customer/:customerId",
  CheckoutOptions = "/checkout-options",
  BookAppointment = "/appointment",
  Payment = "/payment",
  Confirmation = "/confirmation",

  AccountDashboard = "/account/dashboard",
  AccountAppointmentManage = "/account/appointment/:appointmentId",
  AccountAppointmentReschedule = "/account/appointment/:appointmentId/reschedule",
  AccountAppointmentRescheduleNotAllowed = "/account/appointment/:appointmentId/reschedule/not-allowed",
  AccountAppointmentRescheduleConfirm = "/account/appointment/:appointmentId/reschedule/confirm",
  AccountAppointmentRescheduleConfirmation = "/account/appointment/:appointmentId/reschedule/confirmation",
  AccountAppointmentCancel = "/account/appointment/:appointmentId/cancel",
  AccountAppointmentCancelConfirm = "/account/appointment/:appointmentId/cancel/confirm",
  AccountAppointmentCancelConfirmation = "/account/appointment/:appointmentId/cancel/confirmation",
  AppointmentTokenCancel = "/appointment/:token/cancel",
  AppointmentTokenCancelConfirm = "/appointment/:token/cancel/confirm",
  AppointmentTokenCancelConfirmation = "/appointment/:token/cancel/confirmation",
  IneligiblePage ="/Ineligible"
}
