// import React, { useState, useEffect, useRef } from 'react';
import React, { useEffect, useRef, useCallback } from 'react';
import useWGForm from '../hooks/useWGForm';
import { exception } from "../common/util";
import LoadingSpinner from '../LoadingSpinner';
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "./formfill.scss";
import BmiContainer from "./BmiContainer";
import ScreeningInfo from "../../../pages/ScreeningQuestionsPage/components/ScreeningInfo/ScreeningInfo";
import { useHistory } from 'react-router';
// import ReviewED from './ReviewED';

export interface Service {
  id: number;
  flow: string;
}
export interface WGFormProps {
  formname: string;
  onfinish: () => void;
  getTraversalId: (traversalId:string) => void;
}
export interface questionType {
  label: string,
  element: string,
  isChild: boolean,
  dirty: boolean,
  id: string,
  field_name: string,
  content: string,
  options: option[],
  questionId: string
}
export interface option {
  label: string,
  text: string,
  value: string,
  redirectQid: string,
  correct:boolean,
  key: string,
  checked: boolean
}
function WGForm({  formname, onfinish,getTraversalId} : WGFormProps) {

  const ref = useRef(true);
  const [
    loadPost,
    loading,
    error,
    questionId,
    saveRadioAnswer,
    saveCheckedAns,
    enableButton,
    saveTextAns,
    textArea,
    submitAnswer,
    questionData,
    lastQuestion,
    traversalId,
    consentLoading,
    terminalQuestion,
    redirection,
    callRespondApi,
    setEnableButton
  ] = useWGForm();

  const history = useHistory();
  useEffect(() => {
    //if (modal) modal.$overlay.show();

    const firstRender = ref.current;

    //Call the function
    // console.log("in useEffect");
    if (firstRender) {
      ref.current = false;
      // console.log("First Render");
      // console.log('formname', formname);
      loadPost(formname);
    } else {
      // console.log("Not a first Render");
    }
  }, [ loadPost, formname]);

  const getBMIValue = useCallback((BmiMetricResults, row) => {
    if(BmiMetricResults === 0) {
      setEnableButton(true);
     }
    else if(BmiMetricResults < 27) {
      saveRadioAnswer(row?.options[2], row.options, row)
     } else if(BmiMetricResults>=27 && BmiMetricResults<=30){
      saveRadioAnswer(row?.options[1], row.options, row)
     }else if(BmiMetricResults > 30){
      saveRadioAnswer(row?.options[0], row.options, row)
     }
 
   },[saveRadioAnswer, setEnableButton])

   useEffect(() => {
    if(terminalQuestion && !redirection){
      callRespondApi();
    }
  }, [callRespondApi, terminalQuestion, redirection]);

  if(lastQuestion || redirection){
    if(redirection) {
   onfinish();
   getTraversalId(traversalId);
    } 
    if(lastQuestion) {
      history.push('/Ineligible');
      
    }
  }

  return (
      <div className='form-fill-bg'>
        {consentLoading ? <LoadingSpinner/> : 
          <div className='question-page'>
            <ScreeningInfo isOwner={true} />
            {questionData?.map((row : questionType, i :number) => row.id === questionId && (
          <div key={row.id}>
            {!(row.element === "RadioButtons" && row.label === "Please select BMI ") && <p className='question-text'>{row.label}</p> }
            {row?.content?.length > 0 && <p className='text-subtitle'>{row.content}</p>}
            <br></br>
            <>
              {row.element === "RadioButtons" && (row.label === "Please select BMI " ?
              <><BmiContainer getBMIValue= {(value)=> getBMIValue(value,row)}></BmiContainer></> 
              :
               <>{row?.options.map((option: option) => (
                 <div key={option.key}><input 
                    type="radio" 
                    value={option.text}
                    checked={option.checked || false} 
                    name={row.questionId} 
                    disabled={loading}
                    onChange={(e) => saveRadioAnswer(option, row.options, row)}
                    />
                 <label className='adjustLabel'>{option.text}</label></div>
               ))}</>
              )}
            </>
            <>
              {row.element === "Dropdown" && 
              <> 
               {row?.options.map((option, i) => (
                 <div key={option.key}>
                  <>
                    {exception.includes(option.text) && 
                      <>
                        <input 
                          type="checkbox" 
                          value={option.value}
                          checked={option.checked || false}
                          disabled={loading}
                          onChange={(e) => saveCheckedAns(e.target.checked, option.key, option.text, option, row.id, "radio", row.options)}
                        /><label className='adjustLabel'>{option.text}</label>
                      </>
                    }
                    {!exception.includes(option.text) && 
                      <>
                        <input 
                          type="checkbox" 
                          value={option.value}
                          className="checkbox"
                          checked={option.checked || false}
                          disabled={loading}
                          onChange={(e) => saveCheckedAns(e.target.checked, option.key, option.text, option, row.id, "checkbox", row.options)}
                        /><label className='adjustLabel'>{option.text}</label>
                      </>
                    }
                  </>
                 </div>
               ))}
              </>
              }
            </>
            <>
              {row.element === "Checkboxes" && 
              <> 
               {row?.options.map((option, i) => (
                 <div key={option.key}>
                   <>
                     {exception.includes(option.text) && 
                      <>
                        <input 
                          type="checkbox" 
                          value={option.value}
                          checked={option.checked || false}
                          disabled={loading}
                          onChange={(e) => saveCheckedAns(e.target.checked, option.key, option.text, option, row.id, "radio", row.options)}
                        /><label className='adjustLabel'>{option.text}</label>
                      </>
                     }
                     {!exception.includes(option.text) && 
                      <>
                        <input 
                          type="checkbox" 
                          value={option.value}
                          className="checkbox"
                          checked={option.checked || false}
                          disabled={loading}
                          onChange={(e) => saveCheckedAns(e.target.checked, option.key, option.text, option, row.id, "checkbox", row.options)}
                        /><label className='adjustLabel'>{option.text}</label>
                      </>
                     }
                   </>
                 </div>
               ))}
              </>
              }
            </>
            <>
              {row.element === "TextArea" && 
              <>
                {row?.options.map((option, i) => 
                <div key={option.key}>
                  <textarea 
                    value={textArea}
                    rows={5}
                    cols={60}
                    className="textArea"
                    placeholder='Your response*'
                    disabled={loading}
                    onChange={(e)=> saveTextAns(e.target.value, option)}
                  />
                </div>
                )}
              </>
              }
            </>
            <>
              {row.element === "ShortText" && 
              <>
                {row?.options.map((option, i) => 
                  <div key={option.key}>
                    <input
                      type="text" 
                      value={textArea}
                      className="shortText"
                      placeholder='Your response*'
                      disabled={loading}
                      onChange={(e)=> saveTextAns(e.target.value, option)}
                    />
                  </div>
                )}
              </> 
              }
            </>
            {error && (<><br></br><span style={{ color: "red" }}>{JSON.stringify(error)}</span> <br></br></>)}
            <br></br>
            <Grid container>
              <Grid item md={6}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={(row.element === "TerminalText") ? false : enableButton}
                  onClick={(e) => submitAnswer(row.element, questionData?.length, i)}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </div>  
        ))}
          </div>
        }
      </div>
  );

}

export default WGForm;