import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getPaymentTokenAPI } from "../../../api";
import BraintreeWebDropIn, {
  PaymentMethodPayload,
} from "braintree-web-drop-in";
import { getCostStr } from "../../../utils/utils";
import { CustomerDetails } from "../../../stores/appointment/appointment.types";
import { BRAINTREE_CONTAINER_ID } from "../components/Panel/Panel";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";

export interface PaymentState {
  paymentStatus: PaymentStatus;
  error?: ErrorAlertProps;
  makePayment(): Promise<PaymentMethodPayload>;
}

export enum PaymentStatus {
  gettingToken = "gettingToken",
  ready = "ready",
  disabled = "disabled",
  paying = "paying",
  completed = "completed",
  error = "error",
}

export interface PaymentOptions {
  skip: boolean;
  fee: number;
  customerDetails: CustomerDetails;
}

export function usePayment({
  skip,
  fee,
  customerDetails,
}: PaymentOptions): PaymentState {
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(
    PaymentStatus.gettingToken
  );
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [error, setError] = useState<ErrorAlertProps>();
  const instance = useRef<BraintreeWebDropIn.Dropin | null>(null);
  const cost = useMemo(() => getCostStr(fee), [fee]);

  useEffect(() => {
    if (skip) {
      return;
    }

    function updatePaymentOption({ paymentOption }: { paymentOption: string }) {
      setPaymentMethod(paymentOption);
      (instance.current as any).updateConfiguration(
        "threeDSecure",
        paymentOption === "card"
      );
    }

    (async () => {
      const paymentToken = await getPaymentTokenAPI();
      if (!paymentToken) return;

      const braintreeOptions = Object.assign(
        {
          container: BRAINTREE_CONTAINER_ID,
          authorization: paymentToken,
          valutManager: true,
          threeDSecure: true,
        },
        process.env.REACT_APP_ALLOW_ALTERNATE_PAYMENTS === "1"
          ? {
              paypal: {
                flow: "checkout",
                amount: cost,
                currency: "GBP",
              },
              googlePay: {
                googlePayVersion: 2,
                transactionInfo: {
                  currencyCode: "GBP",
                  totalPriceStatus: "FINAL",
                  totalPrice: cost,
                },
              },
            }
          : undefined
      );

      instance.current = await BraintreeWebDropIn.create(braintreeOptions);
      setPaymentStatus(PaymentStatus.ready);
      instance.current.on("paymentOptionSelected", updatePaymentOption);
    })().catch((e) => {
      setError({ message: "could not begin payment" });
      console.error(e);
    });

    return () => {
      (instance.current as any)?.off(
        "paymentOptionSelected",
        updatePaymentOption
      );
      instance.current?.teardown();
    };
  }, [skip, cost]);

  const makePayment: PaymentState["makePayment"] = useCallback(async () => {
    setPaymentStatus(PaymentStatus.paying);
    try {
      if (!instance.current) {
        throw new Error("The Braintree instance is not initialized");
      }
      const requestOptions =
        paymentMethod === "card"
          ? {
              threeDSecure: {
                amount: cost,
                mobilePhoneNumber: customerDetails.phone,
                email: customerDetails.email,
                billingAddress: {
                  givenName: customerDetails.firstName,
                  surname: customerDetails.lastName,
                  phoneNumber: customerDetails.phone,
                  streetAddress: customerDetails.address1,
                  extendedAddress: customerDetails.address2,
                  locality: customerDetails.city,
                  postalCode: customerDetails.postcode,
                  countryCodeAlpha2: "GB",
                },
              },
            }
          : undefined;
      const payload = await instance.current.requestPaymentMethod(
        requestOptions
      );
      if (!payload) {
        throw new Error("Braintree PaymentMethodPayload was not defined");
      }
      setPaymentStatus(PaymentStatus.completed);
      return payload;
    } catch (e) {
      setPaymentStatus(PaymentStatus.error);
      throw e;
    }
  }, [customerDetails, cost, paymentMethod]);

  return {
    paymentStatus,
    error,
    makePayment,
  };
}
