import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  IRadioOption,
  RadioSelectField,
  Typography,
  Modal,
  Form,
} from "@welldigital/components";
import { formatPrice } from "../../../../../utils/price.utils";
import LayoutNavigation from "../../../../../components/LayoutNavigation/LayoutNavigation";
import { SurgeryDetails } from "../../../../../stores/appointment/appointment.types";
import { checkIfAboveExemptAge } from '../../../CustomerDetailsPage.utils';
import GpDetailsFields from "../../GpDetailsFields/GpDetailsFields";
import { fluAddonValidationMessages } from "../../../../../components/validationMessages";
import { Service } from "../../../../../stores/service/service.types";
import { useServiceStore } from "../../../../../stores/service/service.store";
import { ServiceIds } from "../../../../../stores/service/service.constants";

export type DataForwarder = {
  setFormData: (
    serviceOverride: Service,
    surgeryDetails: SurgeryDetails
  ) => void;
  setBirthDate: (birthDate: Date) => void;
};

export type FluAddonModalProps = {
  open: boolean;
  onOptionSelect: (service: Service) => void;
  onClose: () => void;
  dataForwarder: React.MutableRefObject<DataForwarder>;
};

const FluAddonModal: React.FC<FluAddonModalProps> = ({
  open,
  onOptionSelect,
  onClose,
  dataForwarder,
}) => {
  const Flu = useServiceStore((state) => state.serviceMap[ServiceIds.Flu]);
  const FluPPV = useServiceStore(
    (state) => state.serviceMap[ServiceIds.FluPPV]
  );
  const FluPrevenar13 = useServiceStore(
    (state) => state.serviceMap[ServiceIds.FluPrevenar13]
  );
  const [birthDate, setBirthDate] = useState<Date>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const hidePPV = useMemo(
    () => birthDate && !checkIfAboveExemptAge(birthDate),
    [birthDate]
  );

  const servicesOptions: IRadioOption[] = useMemo(() => {
    const options = [
      {
        label: `Pneumococcal polysaccharide vaccine (PPV) - ${formatPrice(
          FluPPV.fee - Flu.fee
        )}`,
        description:
          "Suitable for people over the age of 65 who are at a higher risk of developing pneumonia. (Usually people who are eligible for a free flu vaccination).",
        id: FluPPV,
      },
      {
        label: (
          <>
            Pneumococcal conjugate vaccine (PCV) -{" "}
            {formatPrice(FluPrevenar13.fee - Flu.fee)}
            <br /> Also known by the brand name Prevenar 13
          </>
        ),
        description:
          "Suitable for people aged two and over who are not at an increased risk of developing pneumonia.",
        id: FluPrevenar13,
      },
    ];
    if (hidePPV) {
      options.splice(0, 1);
    }
    return options;
  }, [Flu.fee, FluPPV, FluPrevenar13, hidePPV]);

  useEffect(() => {
    dataForwarder.current.setBirthDate = (newBirthDate) => {
      setBirthDate(newBirthDate);
    };
  }, [dataForwarder]);

  const onSubmit = useCallback(
    (values) => {
      dataForwarder.current.setFormData(
        values.serviceId,
        values.surgeryDetails
      );
      onClose();
    },
    [dataForwarder, onClose]
  );

  return (
    <Modal open={open} onClose={onClose} maxWidth={"xs"}>
      <Form onSubmit={onSubmit} fullWidth>
        <Typography variant={"h5"} spacingAfter={3}>
          Your pneumonia vaccination
        </Typography>
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
          {hidePPV
            ? `Based on the information you've provided, the below pneumonia vaccination has been recommended. If you add a pneumonia vaccination, we'll ask you a few questions on the next screen to check if the pneumonia vaccination is suitable for you. `
            : `Please select the vaccination to add to your booking from the options below. If you add a pneumonia vaccination, we'll ask you a few questions on the next screen to check if the pneumonia vaccination is suitable for you.`}
        </Typography>
        <RadioSelectField
          name={"serviceId"}
          options={servicesOptions}
          variant={"contained"}
          required
          fullWidth
          validationMessages={fluAddonValidationMessages}
          onChange={onOptionSelect}
        />

        <GpDetailsFields isOwner />

        <LayoutNavigation
          spacingBefore={2}
          backButton={{ onClick: onClose, children: "Cancel" }}
          nextButton={{ type: "submit", children: "Add to booking" }}
        />
      </Form>
    </Modal>
  );
};

export default FluAddonModal;
