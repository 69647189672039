import axios from "axios";
export const API_HOST = process.env.REACT_APP_INTEG_API;

export interface traversalSave {
  customer_id: string,
  traversal_id: string
  }

export async function traversalSavetAPI(
    traversalSavePayload: traversalSave
  ): Promise<any> {
    const { data } = await axios.post(`${API_HOST}traversals/save`, traversalSavePayload);
    return data;
  }