import create from "zustand";
import { Service } from "./service.types";
import { getServicesAPI } from "../../api";

export type ServiceState = {
  services: Service[];
  serviceMap: { [key: number]: Service };
  fetchServices: () => Promise<void>;
};

export const useServiceStore = create<ServiceState>((set, get) => ({
  services: null as unknown as Service[],
  serviceMap: {},

  // This function will only be executed once, when the app starts
  async fetchServices() {
    const services = await getServicesAPI();
    const serviceMap = services.reduce((map, service) => {
      map[service.id] = service;
      return map;
    }, {} as ServiceState["serviceMap"]);
    set({ services, serviceMap });
  },
}));
