import React from "react";
import { InputField, Typography } from "@welldigital/components";
import { Box } from "@material-ui/core";
import {
  surgeryAddressValidationMessages,
  surgeryNameValidationMessages,
} from "../../../../components/validationMessages";

export type GpDetailsFieldsProps = {
  isOwner: boolean;
};

const GpDetailsFields: React.FC<GpDetailsFieldsProps> = ({ isOwner }) => {
  return (
    <Box mt={2}>
      <Typography variant={"h6"} spacingAfter={2}>
        {isOwner ? "Your GP" : "Their GP"}
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        {isOwner
          ? "Please provide the name and address of your GP surgery."
          : "Please provide the name and address of their GP surgery."}
      </Typography>
      <InputField
        name={"surgeryDetails.name"}
        label={"Surgery name"}
        required
        validationMessages={surgeryNameValidationMessages}
      />
      <InputField
        name={"surgeryDetails.address"}
        label={"Surgery address"}
        required
        validationMessages={surgeryAddressValidationMessages}
      />
    </Box>
  );
};

export default GpDetailsFields;
