import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from "../../LocationsPage/hooks/utils";
import { submitCovidQuestionsAPI } from "../../../api";
import { CustomerAppointmentDetails } from "../../../stores/appointment/appointment.types";
import { useCallback, useEffect, useState } from "react";
import { CovidFormAnswer, CovidAnswer, CovidQuestionType } from "./hooks.types";
import { covidQuestions } from "./hardcodedData/covidQuestions";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { useHistory } from "react-router-dom";

export type CovidQuestionsState = {
  questions: CovidAnswer[];
  submitAnswers: (
    screeningId: string,
    answers: CovidFormAnswer[]
  ) => Promise<void>;
  answers?: CovidFormAnswer[] | null;
  error?: ErrorAlertProps;
};

export type CovidQuestionsPersistedState = {
  answers: CovidFormAnswer[];
};

export default function useCovidQuestions(
  customer?: CustomerAppointmentDetails
): CovidQuestionsState {
  const history = useHistory();
  const [error, setError] = useState<ErrorAlertProps>();
  const [answers, setAnswers] = useState<CovidFormAnswer[] | null>();

  const getPersistedState = useCallback(
    (screeningId: string): CovidQuestionsPersistedState | null => {
      return getFromLocalStorage<CovidQuestionsPersistedState>(
        `covid-questions-${screeningId}`
      );
    },
    []
  );

  const setPersistedState = useCallback(
    (screeningId: string, state: CovidQuestionsPersistedState) => {
      setToLocalStorage<CovidQuestionsPersistedState>(
        `covid-questions-${screeningId}`,
        state
      );
    },
    []
  );

  const removePersistedState = useCallback((screeningId: string) => {
    deleteFromLocalStorage(`covid-questions-${screeningId}`);
  }, []);

  useEffect(() => {
    if (!customer) {
      setError({
        message: "The customer details does not exist",
        action: { children: "Go back", onClick: () => history.goBack() },
      });
    } else if (!customer.screeningId) {
      setError({
        message: "The Screening ID does not exist",
        action: { children: "Go back", onClick: () => history.goBack() },
      });
    } else {
      const state = getPersistedState(customer.screeningId);
      setAnswers(state?.answers || null);
    }
  }, [customer, getPersistedState, history]);

  const submitAnswers = useCallback(
    async (screeningId: string, answers: CovidFormAnswer[]): Promise<void> => {
      const isIneligible = !covidQuestions.every(({ correctAnswer }, index) => {
        if (typeof correctAnswer === "undefined") return true;
        const { answer } = answers[index];
        return answer === correctAnswer;
      });

      if (isIneligible) {
        removePersistedState(screeningId);
        throw new Error("Ineligible");
      }

      const fullAnswers: CovidQuestionType[] = covidQuestions.map(
        ({ number, question, followUpQuestion }, index) => {
          const { answer, followUpAnswer } = answers[index];
          return {
            number,
            question: `${question}${
              followUpQuestion ? `:${followUpQuestion}` : ""
            }`,
            type: 1,
            answer: `${answer}${followUpAnswer ? `:${followUpAnswer}` : ""}`,
            followUpQuestionAnswer: followUpAnswer || "",
          };
        }
      );

      await submitCovidQuestionsAPI(screeningId, fullAnswers);
      setAnswers(answers);
      setPersistedState(screeningId, { answers });
    },
    [removePersistedState, setPersistedState]
  );

  return {
    error,
    answers,
    questions: covidQuestions,
    submitAnswers,
  };
}
