import { ApiSlot } from "../../api/api.types";

export class Slot {
  resourceId: string;
  startTime: Date;
  endTime: Date;

  constructor(props: Slot) {
    Object.assign(this, props);
    return this;
  }

  static fromRawSlot(props: ApiSlot) {
    return new Slot({
      resourceId: props.resourceId,
      startTime: new Date(props.startTime),
      endTime: new Date(props.endTime),
    });
  }
}
