import React, { useCallback, useEffect, useMemo } from "react";
import { analytics } from "@welldigital/ui-common";
import { generatePath, useHistory, useParams } from "react-router-dom";
import events from "../../events";
import {
  BookingTypes,
  Appointment,
} from "../../stores/appointment/appointment.types";
import RouteValidator from "../../components/RouteValidator/RouteValidator";
import useScreeningQuestions from "./hooks/useScreeningQuestions";
import MainLayout from "../../components/MainLayout/MainLayout";
import ScreeningLoader from "./components/ScreeningLoader/ScreeningLoader";
import ScreeningIneligible from "./components/ScreeningIneligible/ScreeningIneligible";
import { Paths } from "../paths";
import { useAppointmentStore } from "../../stores/appointment/appointment.store";
import ScreeningQuestion, {
  ScreeningQuestionProps,
} from "./components/ScreeningQuestion/ScreeningQuestion";
import ScreeningFinished from "./components/ScreeningFinished/ScreeningFinished";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import { ServiceIds } from "../../stores/service/service.constants";
import WGForm from "../../components/WGForm/components/WGForm";

enum ScreeningQuestionsRequiredProps {
  service = "service",
  pharmacy = "pharmacy",
  bookingType = "bookingType",
  owner = "owner",
  other = "other",
}

type ScreeningQuestionsPageProps = Pick<
  Appointment,
  keyof typeof ScreeningQuestionsRequiredProps
>;

export type ScreeningQuestionsPageParams = {
  customerId: "owner" | string;
};

const ScreeningQuestionsPage: React.FC<ScreeningQuestionsPageProps> = ({
  bookingType,
}) => {
  const history = useHistory();
  const { customerId } = useParams<ScreeningQuestionsPageParams>();
  const {
    appointment,
    setScreeningId,
    setBookingDetailsFinished,
    setOwnerDetails,
    deleteOtherCustomer,
    setTraversalId
  } = useAppointmentStore();
  const {
    owner,
    other,
    pharmacy,
    service: actualService,
  } = appointment as Appointment;
  const isOwner = customerId === "owner";
  const customer = useMemo(
    () => (isOwner ? owner : other.find(({ id }) => customerId === id) || null),
    [customerId, other, owner, isOwner]
  );
  const service = customer?.serviceOverride || actualService;
  const { counter, screening, getScreeningStart, getNextScreening, error } =
    useScreeningQuestions();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const screeningLoaded = !!screening;
  const isFinished =
    !!screening?.hasResult &&
    screening.isAllowedService &&
    !!screening.patientAdvice;
  const isUnnecessary =
    !!screening?.isAllowedService &&
    screening.hasResult &&
    !screening.patientAdvice;
  const isIneligible =
    !!screening?.hasResult &&
    !screening.isAllowedService &&
    !!screening.patientAdvice;

  const goNext = useCallback(
    (action: "push" | "replace") => {
      setScreeningId(customerId, screening?.screeningId as string);
      if (
        [
          ServiceIds.CovidAntigen,
          ServiceIds.CovidGreenPack,
          ServiceIds.CovidAmberPack,
        ].includes(service.id)
      ) {
        history.replace(generatePath(Paths.CovidQuestions, { customerId }));
      } else if (bookingType === BookingTypes.owner) {
        setBookingDetailsFinished();
        history[action](Paths.BookAppointment);
      } else {
        history[action](Paths.BookingDetails);
      }
    },
    [
      customerId,
      history,
      screening,
      setBookingDetailsFinished,
      setScreeningId,
      bookingType,
      service.id,
    ]
  );

  // Load screening if not loaded
  useEffect(() => {
    if (screening || !customer) return;
    getScreeningStart(customer, owner, service, pharmacy);
  }, [customer, getScreeningStart, pharmacy, owner, screening, service]);

  // Track when starting screening
  useEffect(() => {
    if (!(screeningLoaded && !isUnnecessary && counter === 1)) return;
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.screening.started,
    });
  }, [screeningLoaded, isUnnecessary, counter, service.analyticsName]);

  // Redirect if not need for screening
  useEffect(() => {
    if (!isUnnecessary) return;
    if(service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES) return;
    goNext("replace");
  }, [isUnnecessary, goNext, service.id]);

  // Track if is ineligible
  useEffect(() => {
    if (!isIneligible) return;
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.screening.ineligible,
      metadata: {
        reason: "failed screening",
      },
    });
  }, [isIneligible, service.analyticsName]);

  const onSubmitAnswer: ScreeningQuestionProps["onSubmitAnswer"] = useCallback(
    async ({ answer }: { answer: string }) => {
      if (!screening || !customer) return;
      await getNextScreening(customer.id, {
        currentQuestionNumber: screening.currentQuestionNumber,
        nextQuestionNumber: screening.nextQuestionNumber,
        patientAnswer: answer,
        screeningId: screening.screeningId,
      });
      analytics.trackEvent({
        flow: service.analyticsName,
        event: events.screening.answeredQuestion,
        metadata: {
          question: screening.questionText,
        },
      });
    },
    [screening, customer, service.analyticsName, getNextScreening]
  );

  const onComplete = useCallback(() => {
    analytics.trackEvent({
      flow: service.analyticsName,
      event: events.screening.complete,
    });
    goNext("push");
  }, [service, goNext]);

  const onIneligibleOwner = useCallback(() => {
    window.location.href = "https://www.well.co.uk/vaccinations";
  }, []);

  const onIneligibleOwnerAddon = useCallback(() => {
    if (!customer) return;
    const details = {
      ...customer,
      serviceOverride: undefined,
      surgeryDetails: undefined,
    };
    setOwnerDetails(details);
    getScreeningStart(details, owner, actualService, pharmacy, true);
  }, [
    customer,
    setOwnerDetails,
    getScreeningStart,
    owner,
    actualService,
    pharmacy,
  ]);
const getTraversalId= (traversalId :string)=> {
  setTraversalId(traversalId);
}
  const onIneligibleOther = useCallback(() => {
    if (!customer) return;
    deleteOtherCustomer(other.indexOf(customer));
    history.push(Paths.BookingDetails);
  }, [customer, deleteOtherCustomer, history, other]);

  // if (isUnnecessary) {
  // if(service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES){
  //   return  <MainLayout><WGForm
  //   formname="WG-FORM"
  //   onfinish ={onComplete}
  //   getTraversalId= {getTraversalId}
  // /> </MainLayout>;
  // }
  //   return null;
  // }
 
  return (
    <MainLayout>
      {error ? (
        <ErrorAlert {...error} />
      ) : !screening ? (
        <ScreeningLoader />
      ) : isFinished ? 
           ( <ScreeningFinished
        isOwner={isOwner}
        bookingType={bookingType}
        advice={screening.patientAdvice}
        onComplete={onComplete}/>
      ) : isIneligible ? (
        <ScreeningIneligible
          isOwner={isOwner}
          pharmacy={pharmacy}
          service={service}
          onOwnerContinue={onIneligibleOwner}
          onOwnerAddonContinue={onIneligibleOwnerAddon}
          onOtherContinue={onIneligibleOther}
        />
      ) : isUnnecessary? (service.id.toString() === process.env.REACT_APP_WEGOVY_SERVICES?
      <WGForm
      formname="WG-FORM"
      onfinish ={onComplete}
      getTraversalId= {getTraversalId}
    /> : null): (
    <ScreeningQuestion
    {...screening}
    key={counter}
    isOwner={isOwner}
    counter={counter}
    onSubmitAnswer={onSubmitAnswer}
  />
      )}
    </MainLayout>
  );
};

export default () => (
  <RouteValidator<ScreeningQuestionsPageProps>
    validatedProps={
      Object.keys(ScreeningQuestionsRequiredProps) as (keyof Appointment)[]
    }
    page={ScreeningQuestionsPage}
  />
);
