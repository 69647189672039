import React from "react";
import { CheckboxField } from "@welldigital/components";
import { consentValidationMessages } from "../../../../components/validationMessages";

const ConsentField: React.FC = () => {
  return (
    <CheckboxField
      name="consent"
      label={"I have consent to book on behalf of this person."}
      required
      validationMessages={consentValidationMessages}
    />
  );
};

export default ConsentField;
