import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { formatPostcode } from "./utils";
import { Address } from "../../../../stores/appointment/appointment.types";

interface PharmacyAddressProps {
  address: Address;
}

const PharmacyAddress: FC<PharmacyAddressProps> = ({ address }) => {
  return (
    <>
      <Typography color={"textSecondary"}>{`${address.addressLine1}${
        address.addressLine2 ? `,${address.addressLine2}` : ""
      }`}</Typography>
      <Typography color={"textSecondary"}>{`${address.city}, ${formatPostcode(
        address.postalCode
      )}`}</Typography>
    </>
  );
};

export default PharmacyAddress;
