import {
  AutoCompleteFieldProps,
  CheckboxFieldProps,
  DatePickerFieldProps,
  InputFieldProps,
  ItemManagerFieldProps,
  SelectFieldProps,
} from "@welldigital/components";

export const locationSearchValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter a town or postcode",
};

export const bookingForValidationMessages: SelectFieldProps["validationMessages"] = {
  required: "Select one of the options",
};

export const bookingDetailsOwnerValidationMessages: ItemManagerFieldProps["validationMessages"] = {
  required: "Enter your details",
};

export const bookingDetailsOtherValidationMessages: ItemManagerFieldProps["validationMessages"] = {
  required: "Enter their details",
};

export const screeningQuestionValidationMessages: SelectFieldProps["validationMessages"] = {
  required: "Select one of the options",
};

export const covidTestDateValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter the test date",
};

export const covidConsentValidationMessages: CheckboxFieldProps["validationMessages"] = {
  required: "You must confirm the above to continue",
};

export const getBirthDateValidationMessages = (
  minAge: number,
  maxAge: number
): DatePickerFieldProps["validationMessages"] => ({
  invalidDate: "Check the date of birth you’ve entered is correct",
  required: "Enter your date of birth",
  maxDate: `This service is available to people aged between ${minAge} and ${maxAge}`,
  minDate: `This service is available to people aged between ${minAge} and ${maxAge}`,
  disableFuture: "This date can’t be in the future",
});

export const firstNameValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter your first name",
};

export const consentValidationMessages: CheckboxFieldProps["validationMessages"] = {
  required: "You must confirm the above to continue",
};

export const lastNameValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter your last name",
};

export const firstNameTheirValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter their first name",
};

export const lastNameTheirValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter their last name",
};

export const genderValidationMessages: SelectFieldProps["validationMessages"] = {
  required: "Select one of the options",
};

export const postcodeValidationMessages: AutoCompleteFieldProps["validationMessages"] = {
  required: "Enter a postcode",
};

export const address1ValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter the first line of your address",
};

export const cityValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter a city or town",
};

export const emailValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter an email address",
};

export const phoneValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter a valid telephone number",
};

export const surgeryNameValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter your surgery name",
};

export const surgeryAddressValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter your surgery address",
};

export const fluPaymentValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Select a payment option",
};

export const fluAddonValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Select one of the options",
};

export const NHSValidationMessages: InputFieldProps["validationMessages"] = {
  required: "Enter your valid NHS number",
};