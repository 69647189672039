import React from "react";
import { Box, Divider, Link } from "@material-ui/core";
import {
  CheckboxField,
  SelectField,
  Typography,
} from "@welldigital/components";
import AccountInfoMessage from "../../../components/AccountInfoMessage/AccountInfoMessage";
import { Pharmacy } from "../../../stores/appointment/appointment.types";

export type AppointmentCancelReasonProps = {
  pharmacy?: Pharmacy;
};

const AccountAppointmentCancelReasons: React.FC<AppointmentCancelReasonProps> =
  ({ pharmacy }) => {
    const reasonOption = [
      { label: "Family emergency", value: "Family emergency" },
      {
        label: "Appointment no longer needed",
        value: "Appointment no longer needed",
      },
      { label: "Wrong service chosen", value: "Wrong service chosen" },
      { label: "Coronavirus", value: "Coronavirus" },
      { label: "Booked elsewhere", value: "Booked elsewhere" },
      { label: "Other ", value: "Other" },
    ];

    return (
      <>
        <SelectField
          error
          fullWidth
          name={"reason"}
          options={reasonOption}
          label="Reason for cancelling"
          required
        />
        <Typography variant={"body1"} color={"textSecondary"} spacingAfter={4}>
          Please note, if you cancel within 48 hours of your appointment time,
          you will not be eligible for a refund.
        </Typography>
        {pharmacy ? <AccountInfoMessage pharmacy={pharmacy} /> : null}
        <Box mt={4} mb={2}>
          <Divider />
        </Box>
        <CheckboxField
          name={"consent"}
          label={
            <>
              I have read and agree to the Well{" "}
              <Link
                href={
                  "https://www.well.co.uk/about-us/policies/terms-and-conditions-vaccination-services"
                }
                target={"_blank"}
              >
                Terms and Conditions
              </Link>
            </>
          }
          required
          validationMessages={{
            required: "You must confirm the above to continue",
          }}
        />
      </>
    );
  };

export default AccountAppointmentCancelReasons;
