import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { wellColors } from "@welldigital/components";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    position: "relative",
    marginBottom: "-140px",
    background: "#fff",
    zIndex: 4,
  },
}));

const PaymentLoader = ({ className, ...props }: any) => {
  const classes = useStyles();
  const ref = useRef<any>();
  const [width, setWidth] = useState<number>(700);
  const height = 242;

  useEffect(() => {
    if (!ref.current) return () => {};
    const onResize = () => setWidth(ref.current.parentNode.clientWidth);
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref]);

  return (
    <div ref={ref} className={classes.root}>
      <ContentLoader
        display="block"
        width="100%"
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={wellColors.greyscale[100]}
        foregroundColor={wellColors.greyscale[400]}
        speed={1}
        {...props}
      >
        <rect x="0" y="0" rx="0" ry="0" width="2" height={height} />
        <rect x="0" y={height - 2} rx="0" ry="0" width={width} height="2" />
        <rect x={width - 2} y="0" rx="0" ry="0" width="2" height={height} />
        <rect x="0" y="0" rx="0" ry="0" width={width} height="2" />
        <rect x="" y="50" rx="0" ry="0" width={width} height="2" />

        <rect x="18" y="90" rx="0" ry="0" width={width - 36} height="44" />
        <rect
          x="18"
          y="165"
          rx="0"
          ry="0"
          width={(width - 36) / 2 - 8}
          height="44"
        />
        <rect
          x={(width - 36) / 2 + 26}
          y="165"
          rx="0"
          ry="0"
          width={(width - 36) / 2 - 8}
          height="44"
        />
      </ContentLoader>
    </div>
  );
};

export default PaymentLoader;
