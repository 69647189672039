import React, { useMemo } from "react";
import {
  Typography,
  RadioSelectField,
  InputField,
  IRadioOption,
  ConditionalFields,
} from "@welldigital/components";
import {
  covidTestDateValidationMessages,
  screeningQuestionValidationMessages,
} from "../../../../components/validationMessages";
import { CovidAnswer } from "../../hooks/hooks.types";

export type CovidQuestionProps = CovidAnswer & {
  baseName: string;
};

const CovidQuestion: React.FC<CovidQuestionProps> = ({
  question,
  followUpQuestion,
  baseName,
}) => {
  const options: IRadioOption[] = useMemo(
    () => [
      { label: "Yes", id: "Y", gridProps: { xs: 6 } },
      { label: "No", id: "N", gridProps: { xs: 6 } },
    ],
    []
  );

  return (
    <>
      <Typography variant={"h4"}>{question} *</Typography>
      <RadioSelectField
        name={`${baseName}.answer`}
        variant="contained"
        options={options}
        required
        validationMessages={screeningQuestionValidationMessages}
      />

      {!!followUpQuestion && (
        <ConditionalFields fieldName={`${baseName}.answer`} fieldValue={"Y"}>
          <Typography variant="body1" spacingAfter={1}>
            {followUpQuestion} *
          </Typography>
          <InputField
            name={`${baseName}.followUpAnswer`}
            fullWidth
            required
            validationMessages={covidTestDateValidationMessages}
          />
        </ConditionalFields>
      )}
    </>
  );
};

export default CovidQuestion;
