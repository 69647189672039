import React from "react";
import { Pharmacy } from "../../stores/appointment/appointment.types";
import { Alert, MaskIcon } from "@welldigital/components";
import { Link } from "@material-ui/core";

export type AccountInfoMessageProps = {
  pharmacy?: Pharmacy;
};

const AccountInfoMessage: React.FC<AccountInfoMessageProps> = ({
  pharmacy,
}) => {
  const pharmacyPhone = pharmacy?.phone;
  return (
    <Alert
      type={"info"}
      icon={MaskIcon}
      message={
        <>
          Do not leave home if you or someone you live with has any symptoms of
          Coronavirus.
          {pharmacyPhone && (
            <>
              {" "}
              If you are cancelling your appointment due to Coronavirus, contact
              your pharmacy on{" "}
              <Link href={`tel:${pharmacyPhone}`}>{pharmacyPhone}</Link> to move
              your appointment to a later date.
            </>
          )}
        </>
      }
    />
  );
};

export default AccountInfoMessage;
