import create from "zustand";
import {
  CustomerAppointmentDetails,
  PaymentTypes,
  Appointment,
  BookingTypes,
} from "../appointment/appointment.types";
import { CartItem } from "./cart.types";
import { ServiceIds } from "../service/service.constants";
import { useServiceStore } from "../service/service.store";

export type CartState = {
  isCartFlagVisible: boolean;
  showCartFlag: () => void;
  calculateCart: (
    data: Pick<Appointment, "service" | "bookingType" | "owner" | "other">
  ) => void;
  flagTimeout?: NodeJS.Timeout;
  customersBooked: CustomerAppointmentDetails[];
  cartItems: CartItem[];
  totalFee: number;
};

export const useCartStore = create<CartState>((set, get) => ({
  totalFee: -1,
  customersBooked: [],
  cartItems: [],
  isCartFlagVisible: false,

  showCartFlag() {
    const { flagTimeout } = get();
    if (flagTimeout) {
      clearTimeout(flagTimeout);
    }
    const newTimeout = setTimeout(() => {
      set({ isCartFlagVisible: false });
    }, 10000);
    set({ flagTimeout: newTimeout, isCartFlagVisible: true });
  },

  calculateCart({ service, bookingType, owner, other }) {
    const Flu = useServiceStore.getState().serviceMap[ServiceIds.Flu];

    if (!owner) {
      set({
        cartItems: [
          {
            serviceName: service.name,
            amount: 1,
            fee: service.fee,
            packContents: service.packContents || [],
          },
        ],
        totalFee: service.fee,
        customersBooked: [],
      });
      return;
    }

    const customersBooked: CustomerAppointmentDetails[] = [];
    if (bookingType !== BookingTypes.otherExcludingOwner) {
      customersBooked.push(owner);
    }
    customersBooked.push(...other);

    const serviceNameAmountMap = customersBooked.reduce(
      (map, { paymentType }) => {
        let serviceName = service.name;
        let fee = service.fee;
        let packContents = service.packContents || [];
        if (
          [
            ServiceIds.Flu,
            ServiceIds.FluPPV,
            ServiceIds.FluPrevenar13,
          ].includes(service.id)
        ) {
          if (paymentType?.includes(PaymentTypes.exempt)) {
            serviceName += " - NHS";
            fee = service.fee - Flu.fee;
          } else if (paymentType?.includes(PaymentTypes.voucher)) {
            serviceName += " - Voucher";
            fee = service.fee - Flu.fee;
          }
        }
        map[serviceName] = map[serviceName] || { amount: 0, fee, packContents };
        map[serviceName].amount++;
        return map;
      },
      {} as {
        [key: string]: { amount: number; fee: number; packContents: string[] };
      }
    );

    const cartItems: CartItem[] = Object.entries(serviceNameAmountMap)
      .map(([serviceName, { amount, fee, packContents }]) => ({
        serviceName,
        amount,
        fee,
        packContents,
      }))
      .sort((a, b) => (a.serviceName > b.serviceName ? 1 : -1));

    const totalFee = cartItems.reduce((count, { fee, amount }) => {
      return count + fee * amount;
    }, 0);

    set({ cartItems, totalFee, customersBooked });
  },
}));
