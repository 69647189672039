import React, { useCallback } from "react";
import { Typography } from "@welldigital/components";
import LayoutNavigation from "../../components/LayoutNavigation/LayoutNavigation";
import MainLayout from "../../components/MainLayout/MainLayout";
import { useAppointmentStore } from "stores/appointment/appointment.store";
import { Appointment } from "stores/appointment/appointment.types";

const InEligible = () => {
  const { appointment } = useAppointmentStore();
  const { pharmacy } = appointment as Appointment;

  const onContinue = useCallback(() => {
    window.location.href = "https://www.well.co.uk/vaccinations";
  }, []);

  return (
    <MainLayout>
      <Typography variant={"h4"} spacingAfter={3}>
        We’re sorry, we’re unable to complete your booking
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        The information you provided suggests this service might not be suitable
        for you. If you would like more information about this vaccination,
        please speak to the pharmacy team:
      </Typography>
      <Typography variant={"body1"} spacingAfter={3}>
        {pharmacy?.name}
        {pharmacy?.phone ? ` - ${pharmacy?.phone}` : ""}
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        You can go back if you need to make any changes to your details.
      </Typography>
      <LayoutNavigation
        nextButton={{
          children: "Explore other services",
          onClick: onContinue,
        }}
      />
    </MainLayout>
  );
};

export default InEligible;
