import React from "react";
import { Typography } from "@welldigital/components";
import LayoutNavigation from "../../../../../components/LayoutNavigation/LayoutNavigation";
import { Pharmacy } from "../../../../../stores/appointment/appointment.types";

export type IneligibleOtherProps = {
  pharmacy: Pharmacy;
  onContinue: () => void;
};

const IneligibleOther: React.FC<IneligibleOtherProps> = ({
  pharmacy,
  onContinue,
}) => {
  return (
    <>
      <Typography variant={"h4"} spacingAfter={3}>
        We’re sorry, we’re unable to add this person to your booking
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        The information you provided suggests this service might not be suitable
        for the person you are booking for. If they would like more information
        about this vaccination, they can speak to the pharmacy team:
      </Typography>
      <Typography variant={"body1"} spacingAfter={3}>
        {pharmacy.name}
        {pharmacy.phone ? ` - ${pharmacy.phone}` : ""}
      </Typography>
      <Typography variant={"body1"} color={"textSecondary"} spacingAfter={3}>
        You can go back if you need to make any changes to this person’s
        details, or continue with this person removed from your booking.
      </Typography>
      <LayoutNavigation
        nextButton={{
          children: "Continue with booking",
          onClick: onContinue,
        }}
      />
    </>
  );
};

export default IneligibleOther;
