import {
  CustomerDetails,
  SurgeryDetails,
} from "../../appointment/appointment.types";
import { Genders } from "../../../api/api.types";
import { parse } from "date-fns";

export const mockCustomerDetails: CustomerDetails = {
  firstName: "John",
  lastName: "Smith",
  birthDate: parse("2000-05-25", "yyyy-MM-dd", new Date()),
  gender: Genders.male,
  address1: "22 Test address",
  address2: "second floor, apartment 25",
  postcode: "AB 123",
  city: "London",
  email: "john@smith.com",
  phone: "+401234567890",
};

export const mockSurgeryDetails: SurgeryDetails = {
  name: "Jane Smith",
  address: "44 Surgery address",
};
