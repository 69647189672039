import {
  CustomerAppointmentDetails,
  GuardianDetails,
  GuardianTypes,
  Pharmacy,
  CustomerDetails,
} from "../../../stores/appointment/appointment.types";
import { nextScreeningAPI, startScreeningAPI } from "../../../api";
import {
  NextScreeningRequest,
  ScreeningResponsePayload,
} from "../../../api/api.types";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from "../../LocationsPage/hooks/utils";
import { useCallback, useState } from "react";
import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import { Service } from "../../../stores/service/service.types";

export type ScreeningQuestionsState = {
  counter: number;
  screening?: ScreeningResponsePayload;
  getScreeningStart: (
    customer: CustomerAppointmentDetails,
    owner: CustomerAppointmentDetails,
    service: Service,
    pharmacy: Pharmacy,
    ignorePersister?: boolean
  ) => Promise<void>;
  getNextScreening: (
    customerId: string,
    props: NextScreeningRequest
  ) => Promise<void>;
  error?: ErrorAlertProps;
};

export type ScreeningQuestionsPersistedState = {
  counter: number;
  screening?: ScreeningResponsePayload;
};

export default function useScreeningQuestions(): ScreeningQuestionsState {
  const [counter, setCounter] = useState<number>(-1);
  const [error, setError] = useState<ErrorAlertProps>();
  const [screening, setScreening] = useState<ScreeningResponsePayload>();

  const getPersistedState = useCallback(
    (customerId: string): ScreeningQuestionsPersistedState | null => {
      return getFromLocalStorage<ScreeningQuestionsPersistedState>(
        `screening-${customerId}`
      );
    },
    []
  );

  const setPersistedState = useCallback(
    (
      customerId: string,
      screening: ScreeningResponsePayload,
      counter: number
    ) => {
      setToLocalStorage(`screening-${customerId}`, { screening, counter });
    },
    []
  );

  const removePersistedState = useCallback((customerId: string) => {
    deleteFromLocalStorage(`screening-${customerId}`);
  }, []);

  const getGuardianDetails = useCallback(
    (
      customer: CustomerAppointmentDetails,
      owner: CustomerAppointmentDetails
    ): GuardianDetails => {
      if (customer.guardianType === GuardianTypes.owner) {
        return {
          firstName: owner.details.firstName,
          lastName: owner.details.lastName,
          email: owner.details.email,
          phone: owner.details.phone,
        };
      }
      return customer.guardianDetails as GuardianDetails;
    },
    []
  );

  const getScreeningStart = useCallback(
    async (customer, owner, service, pharmacy, ignorePersister) => {
      setError(undefined);
      if (!ignorePersister) {
        const persistedState = getPersistedState(customer.id);
        if (persistedState) {
          setScreening(persistedState.screening);
          setCounter(persistedState.counter);
          return;
        }
      }
      const customerDetails = customer?.details as CustomerDetails;
      const guardianDetails = getGuardianDetails(customer, owner);
      const selectedService = customer.serviceOverride || service;
      try {
        const response = await startScreeningAPI({
          firstNames: customerDetails.firstName,
          lastName: customerDetails.lastName,
          dob: customerDetails.birthDate,
          sex: customerDetails.gender,
          locationId: pharmacy.id,
          serviceId: selectedService.id,
          addressLine1: customerDetails.address1,
          addressLine2: customerDetails.address2 || "",
          town: customerDetails.city,
          postcode: customerDetails.postcode,
          surgeryName: customer?.surgeryDetails?.name,
          surgeryAddress: customer?.surgeryDetails?.address,
          email: guardianDetails?.email || customerDetails.email,
          phone: guardianDetails?.phone || customerDetails.phone,
          nhsNumber: customerDetails.nhsNumber,
          allowMarketing: guardianDetails
            ? customer?.allowMarketing
            : undefined,
          guardian: guardianDetails
            ? {
                title: "Mr(s)",
                firstnames: guardianDetails.firstName,
                lastName: guardianDetails.lastName,
              }
            : undefined,
        });
        setPersistedState(customer.id, response, 1);
        setScreening(response);
        setCounter(1);
      } catch (e) {
        setError({ message: e.response?.data?.message || e.message });
        removePersistedState(customer.id);
      }
    },
    [
      getGuardianDetails,
      getPersistedState,
      removePersistedState,
      setPersistedState,
    ]
  );

  const getNextScreening = useCallback(
    async (customerId, props) => {
      try {
        const response = await nextScreeningAPI(props);
        setPersistedState(customerId, response, counter + 1);
        setScreening(response);
        setCounter(counter + 1);
      } catch (e) {
        setError({ message: e.response?.data?.message || e.message });
        removePersistedState(customerId);
      }
    },
    [counter, removePersistedState, setPersistedState]
  );

  return {
    counter,
    error,
    screening,
    getScreeningStart,
    getNextScreening,
  };
}
