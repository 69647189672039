import React, { useMemo } from "react";
import {
  Form,
  InputField,
  IRadioOption,
  RadioSelectField,
  Typography,
} from "@welldigital/components";
import ScreeningInfo from "../ScreeningInfo/ScreeningInfo";
import LayoutNavigation from "../../../../components/LayoutNavigation/LayoutNavigation";
import { screeningQuestionValidationMessages } from "../../../../components/validationMessages";
import { ScreeningResponsePayload } from "../../../../api/api.types";

export type ScreeningQuestionProps = {
  counter: number;
  onSubmitAnswer: (values: { answer: string }) => Promise<void>;
  isOwner: boolean;
} & ScreeningResponsePayload;

const ScreeningQuestion: React.FC<ScreeningQuestionProps> = ({
  counter,
  questionText,
  questionType,
  onSubmitAnswer,
  isOwner,
}) => {
  const options: IRadioOption[] = useMemo(
    () => [
      { label: "Yes", id: "Y", gridProps: { xs: 6 } },
      { label: "No", id: "N", gridProps: { xs: 6 } },
    ],
    []
  );

  return (
    <Form onSubmit={onSubmitAnswer}>
      <ScreeningInfo isOwner={isOwner} />

      <Typography variant={"h6"} spacingAfter={2}>
        Question {counter}
      </Typography>
      <Typography paragraph>{questionText}</Typography>
      {questionType === 1 ? (
        <RadioSelectField
          name="answer"
          variant="contained"
          options={options}
          required
          validationMessages={screeningQuestionValidationMessages}
        />
      ) : [3, 5].includes(questionType) ? (
        <InputField name="answer" type="number" required />
      ) : (
        <Typography>Unknown Question Type</Typography>
      )}
      <LayoutNavigation
        nextButton={{
          type: "submit",
          children: "Next",
        }}
      />
    </Form>
  );
};
export default ScreeningQuestion;
