import React from "react";
import {
  Button,
  Modal,
  ModalActions,
  ModalTitle,
} from "@welldigital/components";

export type EmptyOwnerDetailsModalProps = {
  onConfirmationReject: () => void;
};

const EmptyOwnerDetailsModal: React.FC<EmptyOwnerDetailsModalProps> = ({
  onConfirmationReject,
}) => (
  <Modal open onClose={() => onConfirmationReject()}>
    <ModalTitle
      label={"We need your details first, to manage the booking"}
      variant={"subtitle"}
    />
    <ModalActions>
      <Button
        onClick={onConfirmationReject}
        children={"OK"}
        color={"primary"}
      />
    </ModalActions>
  </Modal>
);

export default EmptyOwnerDetailsModal;
