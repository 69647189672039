import React from "react";
import { Container, Grid, makeStyles, Theme, Link } from "@material-ui/core";
import { Typography, wellColors } from "@welldigital/components";
import img1Src from "./assets/footer-img1.jpg";
import img2Src from "./assets/footer-img2.jpg";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: `1px solid ${wellColors.greyscale[300]}`,
    overflow: "hidden",
  },
  container: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 50,
    },
  },
  logo: {
    display: "block",
    width: 110,
    height: "auto",
    marginBottom: theme.spacing(6),
    "& path": {
      fill: wellColors.elixir[900],
    },
  },
  images: {
    display: "flex",
    margin: theme.spacing(-2),
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  image: {
    padding: theme.spacing(2),
    flex: "0 0 auto",
  },
  img1: {
    width: 209,
  },
  img2: {
    width: 79,
  },
  img3: {
    width: 66,
  },
}));

const FooterSecond: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth={"lg"} className={classes.container}>
        <Grid container spacing={8}>
          <Grid item sm={12} md={8}>
            <Typography
              variant={"caption"}
              color={"textSecondary"}
              spacingAfter={2}
            >
              Bestway National Chemists Limited is registered in England and
              Wales, trading as Well and Well Pharmacy. Our online pharmacy
              (well.co.uk) registration number is 9010492 and the registered
              pharmacy address is: Well, Healthcare Service Centre, Meir Park,
              Stoke-on-Trent, Staffordshire, ST3 7UN.
            </Typography>
            <Typography
              variant={"caption"}
              color={"textSecondary"}
              spacingAfter={2}
            >
              If you would like to know who the Responsible Pharmacist is at any
              given time, please email{" "}
              <Link href="mailto:pharmacy.fap20@nhs.net">
                pharmacy.fap20@nhs.net
              </Link>{" "}
              or call <Link href={"tel:01782 597313"}>01782 597313</Link>. You
              can also contact the pharmacy on{" "}
              <Link href="mailto:hello@well.co.uk">hello@well.co.uk</Link>.
            </Typography>
            <Typography variant={"caption"} color={"textSecondary"}>
              The Superintendent Pharmacist for this pharmacy is Iftkhar Ahmad
              Khan, GPhC registration number 2041286. You can find out more
              about checking if an online pharmacy is operating legally from the
              General Pharmaceutical Council (GPhC).
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <div className={classes.images}>
              <a
                className={classes.image}
                href="https://www.nhs.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={img1Src}
                  className={classes.img1}
                  alt="NHS Services"
                />
              </a>
              <a
                className={classes.image}
                href="https://www.legitscript.com/websites/well.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={img2Src}
                  className={classes.img2}
                  alt="Well.co.uk Legit Script"
                />
              </a>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterSecond;
