import React, { useEffect, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { wellColors } from "@welldigital/components";

const AccountDashboardSidebarLoader: React.FC = (props: any) => {
  const ref = useRef<any>();
  const [width, setWidth] = useState<number>(284);
  const height = 534;

  useEffect(() => {
    if (!ref.current) return () => {};
    const onResize = () => setWidth(ref.current.parentNode.clientWidth);
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [ref]);

  return (
    <div ref={ref}>
      <ContentLoader
        display={"block"}
        width={"100%"}
        height={"100%"}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={wellColors.greyscale[100]}
        foregroundColor={wellColors.greyscale[400]}
        speed={1}
        {...props}
      >
        <rect x={"0"} y={"0"} rx={"0"} ry={"0"} width={width} height={"2"} />
        <rect x={"0"} y={"0"} rx={"0"} ry={"0"} width={"2"} height={height} />
        <rect
          x={"0"}
          y={height - 2}
          rx={"0"}
          ry={height - 2}
          width={width}
          height={"2"}
        />
        <rect
          x={width - 2}
          y={"0"}
          rx={width - 2}
          ry={"0"}
          width={"2"}
          height={height}
        />

        <rect x="30" y="40" rx="5" ry="5" width="150" height="12" />
        <Section offsetY={85} />

        <rect x="30" y="165" rx="5" ry="5" width="150" height="12" />
        <Section offsetY={210} />
        <Section offsetY={280} />
        <Section offsetY={350} />
        <Section offsetY={440} />
      </ContentLoader>
    </div>
  );
};

const Section: React.FC<{ offsetY: number; long?: boolean }> = ({
  offsetY,
  long,
}) => {
  const step = 28;
  return (
    <>
      <circle cx="42" cy={offsetY + 5} r="12" />
      <rect x="80" y={offsetY} rx="5" ry="5" width="150" height="10" />
      <rect x="80" y={offsetY + step} rx="5" ry="5" width="120" height="10" />
      {long && (
        <>
          <rect
            x="80"
            y={offsetY + step * 2}
            rx="5"
            ry="5"
            width="120"
            height="10"
          />
          <rect
            x="80"
            y={offsetY + step * 3}
            rx="5"
            ry="5"
            width="150"
            height="10"
          />
          <rect
            x="80"
            y={offsetY + step * 4}
            rx="5"
            ry="5"
            width="110"
            height="10"
          />
          <rect
            x="80"
            y={offsetY + step * 5}
            rx="5"
            ry="5"
            width="130"
            height="10"
          />
        </>
      )}
    </>
  );
};

export default AccountDashboardSidebarLoader;
