import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useLocationQuery } from "../../../utils/utils";

import { ErrorAlertProps } from "../../../components/ErrorAlert/ErrorAlert";
import {
  AccountStoreState,
  useAccountStore,
} from "../../../stores/account/account.store";
import { Pharmacy } from "../../../stores/appointment/appointment.types";
import usePharmacy from "../../../utils/usePharmacy.utl";

type Params = {
  token: string;
};

export type CancelState = {
  error?: ErrorAlertProps;
  setCancelDetails: AccountStoreState["setCancelDetails"];
  token: string;
  appointmentId: string;
  locationId: string;
  appointmentStartDate: string;
  pharmacy?: Pharmacy;
};

export default function useCancel(): CancelState {
  const { token } = useParams<Params>();
  const { appointmentId, appointmentStartDate, locationId } =
    useLocationQuery();
  const { pharmacy, error: pharmacyError } = usePharmacy(locationId);
  const isQsParamsMissing = useMemo(
    () => !appointmentId || !appointmentStartDate || !locationId,
    [appointmentId, appointmentStartDate, locationId]
  );
  const error = useMemo(() => {
    if (!isQsParamsMissing) return;
    return {
      message: `One of query string params missing: "appointmentId", "appointmentStartDate", "locationId"`,
      isBlocking: true,
    };
  }, [isQsParamsMissing]);

  const [appointmentError, setCancelDetails, checkIfTokenIsValid] =
    useAccountStore((state) => [
      state.error,
      state.setCancelDetails,
      state.checkIfTokenIsValid,
    ]);

  useEffect(() => {
    if (isQsParamsMissing) return;
    checkIfTokenIsValid(token);
  }, [isQsParamsMissing, checkIfTokenIsValid, token]);

  return {
    error: error || pharmacyError || appointmentError,
    setCancelDetails,
    pharmacy,
    token,
    appointmentId,
    locationId,
    appointmentStartDate,
  };
}
